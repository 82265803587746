import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import schoolIcon from "../../../Assets/school-2.png"

// Fix the default icon paths
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const GISComponent = () => {
  const positions = [
    { lat: 6.5244, lng: 3.3792, name: "Lagos" },
    { lat: 9.0765, lng: 7.3986, name: "Abuja" },
    { lat: 7.3775, lng: 3.947, name: "Ibadan" },
    { lat: 12.0022, lng: 8.5919, name: "Kano" },
    { lat: 4.8156, lng: 7.0498, name: "Port Harcourt" },
    { lat: 6.335, lng: 5.6037, name: "Benin City" },
    { lat: 10.5105, lng: 7.4165, name: "Kaduna" },
    { lat: 9.8965, lng: 8.8583, name: "Jos" },
    { lat: 6.5243, lng: 7.5186, name: "Enugu" },
    { lat: 11.1113, lng: 7.7227, name: "Zaria" },
    { lat: 5.1107, lng: 7.3652, name: "Aba" },
    { lat: 8.4966, lng: 4.5421, name: "Illorin" },
    { lat: 8.1339, lng: 4.2436, name: "Ogbomosho" },
    { lat: 11.8333, lng: 13.15, name: "Maiduguri" },
    { lat: 5.5174, lng: 5.7501, name: "Warri" },
    { lat: 13.0059, lng: 5.2476, name: "Sokoto" },
    { lat: 7.1604, lng: 3.3483, name: "Abeokuta" },
    { lat: 5.0389, lng: 7.9128, name: "Uyo" },
  ];

  console.log(positions.length)

  return (
    <section>
      <div className="container w-full h-screen">
        <MapContainer
          center={[9.0765, 7.3986]}
          zoom={6}
          scrollWheelZoom={true}
          className="h-full"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {positions.map((position, index) => (
            <Marker key={index} position={[position.lat, position.lng]}>
              <Popup>{position.name}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </section>
  );
};

export default GISComponent;
