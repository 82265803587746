import { Box, Button, Modal, TextField } from "@mui/material";
import React from "react";

const RejectDataEntry = ({
  openRejectModal1,
  handleCloseRejectModal1,
  rejectionReason1,
  handleRejectReasonChange1,
  handleSubmit1,
}) => {
  return (
    <div>
      <Modal
        open={openRejectModal1}
        onClose={handleCloseRejectModal1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ width: 300, bgcolor: "background.paper", boxShadow: 24, p: 4 }}
        >
          <h2>Notification Messages</h2>
          <TextField
            id="outlined-multiline-static"
            label="Messages"
            multiline
            rows={4}
            value={rejectionReason1}
            onChange={handleRejectReasonChange1}
            variant="outlined"
            disabled
          />
          <Button onClick={handleSubmit1} variant="contained" color="primary">
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default RejectDataEntry;
