import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TcDataTable from "../Preview/table_components/TcData";

const modalVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: { delay: 0.2, type: "spring", stiffness: 120 },
  },
};

const ViewTcs = ({ title, closePage }) => {
  return (
    <div className="fixed left-0 top-0 w-full h-screen bg-black bg-opacity-70 flex justify-center items-center">
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[80%] h-[98vh] px-4 pt-2 pb-6 bg-page_bg rounded-lg relative"
      >
        <div
          className="flex justify-between items-center py-3 ml-8 font-bold"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <h1
            className="text-2xl text-active_bg"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            {title}
          </h1>

          <CloseIcon
            onClick={closePage}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr />

        <Box className="w-full my-3">
          <Typography component={"span"}>
            <TcDataTable />
          </Typography>
        </Box>
      </motion.div>
    </div>
  );
};

ViewTcs.propTypes = {
  title: PropTypes.string.isRequired,
  closePage: PropTypes.func.isRequired,
};

export default ViewTcs;
