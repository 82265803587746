import React from "react";
import AnalyticsCard from "../../Pages/Component1/Analytics_user3/Cards_component/AnalyticsCards";

import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import IntermediateCardComponent3 from "../../Pages/Component1/Analytics_user3/Cards_component/intermediateCardComponent3";
import PdoCardComponent3 from "../../Pages/Component1/Analytics_user3/Cards_component/PdoCardComponent3";
import { TeachersDoughnutChartDisplayComponent3 } from "../../Pages/Component1/Analytics_Dashboard/chart_components/component3Doughnut";
import IntermediateCardComponent4 from "../../Pages/Component1/Analytics_user3/Cards_component/IntermediateCardComponent4";
import PdoCardComponent4 from "../../Pages/Component1/Analytics_user3/Cards_component/PdoCardComponent4";

const Component4DashboardDisplay = ({
  totalTTIs,
  aggregatedData,
  preServiceData,
  inServiceData,
  preServiceAggregatedData,
  inServiceAggregatedData,
}) => {
  const maleCount = aggregatedData?.maleCount;
  const femaleCount = aggregatedData?.femaleCount;
  const maleCountPreService = preServiceAggregatedData?.maleCount;
  const femaleCountPreService = preServiceAggregatedData?.femaleCount;
  const maleCountInService = inServiceAggregatedData?.maleCount;
  const femaleCountInService = inServiceAggregatedData?.femaleCount;
  const total = maleCount + femaleCount;
  const percentageMale = ((maleCount * 100) / total).toFixed(1);
  const percentageFemale = ((femaleCount * 100) / total).toFixed(1);

  const pieChartData = [
    { malePercentage: parseFloat(percentageMale) },
    { femalePercentage: parseFloat(percentageFemale) },
  ];

  const stateCount = 3;
  const federalCount = 6;
  const skillAcquisitionCenterCount = 8;
  return (
    <div>
      <div className="flex flex-col mt-3 px-3">
        <h2 className=" font-bold text-lg mb-5">Analytics for component 4</h2>
        <div className="grid grid-cols-3  gap-3">
          <AnalyticsCard
            title="Number of Sector Skills Councils operational"
            value={7}
            icon={Groups2OutlinedIcon}
          />
          <AnalyticsCard
            title="Number of improved NSQF – recognised TC curricula reflecting digital skills requirements in the labour market "
            value={4}
            icon={Groups2OutlinedIcon}
          />
          <AnalyticsCard
            title="Number of TTIs in TCs and skills acquisition centers certified as NSQF assessors or verifiers  "
            value={3}
            icon={Groups2OutlinedIcon}
          />
          <AnalyticsCard
            title="Number of Sector Skills Councils that meet at least twice a year "
            value={5}
            icon={Groups2OutlinedIcon}
          />
          <AnalyticsCard
            title="Number of improved and NSQF recognized TC Curricula developed "
            value={3}
            icon={Groups2OutlinedIcon}
          />
          <AnalyticsCard
            title="Number of Skills Development Reports published "
            value={5}
            icon={Groups2OutlinedIcon}
          />
          <AnalyticsCard
            title="Number of Tracer Studies conducted "
            value={8}
            icon={Groups2OutlinedIcon}
          />
        </div>

        <div className="mt-4">
          <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
            <IntermediateCardComponent4
              stateCount={stateCount}
              federalCount={federalCount}
              skillAcquisitionCenterCount={skillAcquisitionCenterCount}
            />
          </div>
          <div className="mt-4">
            <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
              {/* <PdoCardComponent3
                maleCountPreService={maleCountPreService}
                femaleCountPreService={femaleCountPreService}
                maleCountInService={maleCountInService}
                femaleCountInService={femaleCountInService}
              /> */}
              <PdoCardComponent4
                maleCountPreService={maleCountPreService}
                femaleCountPreService={femaleCountPreService}
                maleCountInService={maleCountInService}
                femaleCountInService={femaleCountInService}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component4DashboardDisplay;
