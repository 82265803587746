import {
  ArrowDropDown,
  Visibility,
  Settings as SettingsIcon,
  Logout,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import dp from "../Assets/profilePicture.svg";
import { useEffect, useRef, useState } from "react";
import Preview from "../Pages/Component1/Preview/Preview";
import Settings from "../Pages/Component1/Settings/Settings";
import {
  logOut,
  selectCurrentJurisdiction,
  selectCurrentUserRole,
  selectValidatorState,
} from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ViewTcs from "../Pages/Component1/ViewTcs/ViewTcs";
import RejectDataEntry from "./Modal_Forms/RejectMessage/Reject";
import { toSentenceCase } from "../utils/toSentenceCase";



const PageHeader = ({ title }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showViewTcs, setShowViewTcs] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showSettingsSuper, setShowSettingsSuper] = useState(false);
  const [profilePicture, setProfilePicture] = useState(dp);
  const [arrowRotate, setArrowRotate] = useState(0);
  const [messageCount, setMessageCount] = useState(3);
  const [showNotifications, setShowNotifications] = useState(false); // New state for notification modal
  const [notifications, setNotifications] = useState([
    "Rejection reason 1",
    "Rejection reason 2",
    "Rejection reason 3",
  ]); // Sample notifications

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRoles = useSelector(selectCurrentUserRole);
  const validatorState = useSelector(selectValidatorState)
  const capValidatorState = toSentenceCase(validatorState)
  
  const userJurisdiction = useSelector(selectCurrentJurisdiction);

  const dropdownRef = useRef(null);
  const arrowRef = useRef(null);

  const displayDropdown = () => {
    setShowDropdown((prev) => !prev);
    setArrowRotate((prevRotate) => (prevRotate === 0 ? 180 : 0));
  };

  const showPreviewDisplay = () => {
    setShowPreview((prev) => !prev);
    setShowSettings(false);
  };

  const showViewTcsDisplay = () => {
    setShowViewTcs((prev) => !prev);
    setShowSettings(false);
  };

  const showSettingsDisplay = () => {
    setShowSettings((prev) => !prev);
    setShowDropdown(false);
    setArrowRotate(0);
  };
  const showSettingsSuperDisplay = () => {
    setShowSettingsSuper((prev) => !prev);
    setShowDropdown(false);
    setArrowRotate(0);
  };

  const closePage = () => {
    setShowPreview(false);
    setShowViewTcs(false);
    setShowSettings(false);
    setShowSettingsSuper(false);
    setShowDropdown(false);
    setArrowRotate(0);
  };

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/auth");
  };

  const handleNotificationsClick = () => {
    setShowNotifications(true);
  };

  const handleCloseNotifications = () => {
    setShowNotifications(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !arrowRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
        setArrowRotate(0);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const allowedRoles = [
    "Super_Admin",
    "National_Admin",
    "Reviewer",
    "Validator",
    "M_E_OFFICE",
  ];
  const roleMessage =
    allowedRoles.includes(userRoles) === ""
      ? ""
      : userRoles === "Super_Admin"
      ? "Super Admin"
      : userRoles === "National_Admin"
      ? "National Admin"
      : userRoles === "Reviewer" && userJurisdiction === "federal"
      ? "NPCU Reviewer"
      : userRoles === "Reviewer" && userJurisdiction === "state"
      ? "NPMU Reviewer"
      : userRoles === "Validator"
      ? `SPIU Validator(${capValidatorState})`
      : userRoles === "M_E_OFFICE" && userJurisdiction === "federal"
      ? "M&E Officer (Federal)"
      : userRoles === "M_E_OFFICE" && userJurisdiction === "state"
      ? "M&E Officer (State)"
      : "";

  return (
    <div className="w-full bg-white sticky top-0 shadow-sm z-10">
      <div className="w-[94%] my-0 mx-auto py-2 flex items-center justify-between relative">
        <h1 className="text-active_bg text-2xl font-bold">{title}</h1>

        <div className="flex items-center gap-7">
          <Badge badgeContent={messageCount} color="error">
            <NotificationsIcon
              className="cursor-pointer"
              onClick={handleNotificationsClick}
            />
          </Badge>
          <div className="flex items-center gap-1 mr-3">
            <p
              className="text-active_bg font-bold text-sm mr-2"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {roleMessage}
            </p>
            <img src={profilePicture} alt="dp" />
            <ArrowDropDown
              ref={arrowRef}
              onClick={displayDropdown}
              className="text-main_bg cursor-pointer"
              style={{ transform: `rotate(${arrowRotate}deg)` }}
            />
          </div>

          {showDropdown && (
            <div
              className="bg-white w-56 shadow-lg absolute top-16 right-1 rounded-md z-[100]"
              ref={dropdownRef}
            >
              <div className="flex flex-col justify-between">
                {userRoles === "M_E_OFFICE" ? (
                  <div>
                    <div
                      onClick={showPreviewDisplay}
                      className="flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      <Visibility />
                      <p className="text-primary_text text-sm">Preview</p>
                    </div>

                    {showPreview && (
                      <Preview title="Preview" closePage={closePage} />
                    )}
                  </div>
                ) : (
                  userRoles === "Validator" && (
                    <div>
                      <div
                        onClick={showViewTcsDisplay}
                        className="flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-gray-100"
                      >
                        <Visibility />
                        <p className="text-primary_text text-sm">View Tcs</p>
                      </div>

                      {showViewTcs && (
                        <ViewTcs
                          title="Technical Colleges"
                          closePage={closePage}
                        />
                      )}
                    </div>
                  )
                )}

                {userRoles === "Super_Admin" ? (
                  <div
                    onClick={showSettingsSuperDisplay}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100"
                  >
                    <SettingsIcon />
                    <p className="text-primary_text text-sm cursor-pointer">
                      Settings for users
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={showSettingsDisplay}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100"
                  >
                    <SettingsIcon />
                    <p className="text-primary_text text-sm cursor-pointer">
                      Settings
                    </p>
                  </div>
                )}
              </div>

              <hr />
              <div
                onClick={handleLogout}
                className="flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-gray-100"
              >
                <Logout />
                <p className="text-primary_text text-sm">Logout</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showSettings && (
        <Settings
          title="Settings"
          closePage={closePage}
          setProfilePicture={setProfilePicture}
        />
      )}
      {showNotifications && (
        <RejectDataEntry
          openRejectModal1={showNotifications}
          handleCloseRejectModal1={handleCloseNotifications}
          rejectionReason1={notifications.join("\n")}
          handleRejectReasonChange1={() => {}}
          handleSubmit1={handleCloseNotifications}
        />
      )}
    </div>
  );
};

export default PageHeader;
