import { v4 as uuidv4 } from "uuid";
import {
  Speed,
  PeopleAlt,
  BarChart,
  AddLocation,
  CompassCalibration,
} from "@mui/icons-material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export const initialNavListItems = [
  {
    id: uuidv4(),
    icon: <BarChart />,
    text: "Analytics",
    path: '/analytics',
    roles: [
      "Super_Admin",
      "National_Admin",
      "Reviewer",
      "Validator",
      "M_E_OFFICE",
    ],
  },
  {
    id: uuidv4(),
    icon: <PeopleAlt />,
    text: "Beneficiaries Data",
    path: "/beneficiaries-data",
    roles: ["M_E_OFFICE"],
  },
  // {
  //   id: uuidv4(),
  //   icon: <CompassCalibration />,
  //   text: "Track",
  //   path: "/tracking",
  //   roles: [
  //     "Super_Admin",
  //     "National_Admin",
  //     "Reviewer",
  //     "Validator",
  //     "M_E_OFFICE",
  //   ],
  // },

  {
    id: uuidv4(),
    icon: <CheckCircleRoundedIcon />,
    text: "Validate",
    path: "/validate",
    roles: ["Validator"],
  },
  {
    id: uuidv4(),
    icon: <AssignmentTurnedInRoundedIcon />,
    text: "Review",
    path: "/review",
    roles: ["Reviewer"],
  },

  {
    id: uuidv4(),
    icon: <ImportContactsRoundedIcon />,
    text: "Overview",
    path: "/overview",
    roles: ["Super_Admin", "National_Admin"],
  },
  {
    id: uuidv4(),
    icon: <Speed />,
    text: "Cross cutting issues",
    path: "/cross-cutting-issues",
    roles: ["Super_Admin", "National_Admin", "Reviewer", "Validator"],
  },
  {
    icon: <AddLocation />,
    text: "GIS Page",
    path: "/gis",
    roles: ["Super_Admin", "National_Admin", "Reviewer", "Validator"],
  },
  {
    icon: <ContentPasteIcon />,
    text: "Create Users",
    path: "/super_admin/create-users",
    roles: ["Super_Admin" ],
  },
  
];
