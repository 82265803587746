import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../Services/axiosInstance";
import { toast } from "sonner";
import { FormInput, FormCheckbox } from "../form";
import CustomButton from "../button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentTcName,
} from "../../features/auth/authSlice";
import {
  selectProjectSummaryPost,
  setProjectSummaryPost,
} from "../../features/auth/isPostMade";
import { fetchProjectSummary } from "../../utils/apiContext";

const workshopLabels = [
  "Construction Workshops",
  "Mechanical Engineering Workshops",
  "Hospitality Workshops",
  "Electrical Engineering Workshops",
  "ICT Labs",
  "Agricultural Science Workshop",
  "Garment Making Workshop",
];

const ModalSummaryProjectForm = ({ open, handleClose, onFormSubmit }) => {
  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const tc_name = useSelector(selectCurrentTcName);

  const dispatch = useDispatch();

  const {
    data: allPosts,
    isLoading: rQLoading,
    error,
  } = useQuery({
    queryKey: ["projectSummary"],
    queryFn: () => fetchProjectSummary(jurisdiction),
  });

  const [nameOfPrincipal, setNameOfPrincipal] = useState("");
  const [dateOfReceiptOfInitialGrant, setDateOfReceiptOfInitialGrant] = useState("");
  const [industryPartners, setIndustryPartners] = useState([""]);
  const [projectManager, setProjectManager] = useState("");
  const [procurementOfficer, setProcurementOfficer] = useState("");
  const [communicationsOfficer, setCommunicationsOfficer] = useState("");
  const [meOfficer, setMeOfficer] = useState("");
  const [priorityTrades, setPriorityTrades] = useState([]);
  const [listOfWorkshopsRenovated, setListOfWorkshopsRenovated] = useState([]);
  const [listOfWorkshopsEquipped, setListOfWorkshopsEquipped] = useState([]);
  const [totalNoOfStudentsInTc, setTotalNoOfStudentsInTc] = useState("");
  const [priorityTradesError, setPriorityTradesError] = useState(false);
  const [workshopsRenovatedError, setWorkshopsRenovatedError] = useState(false);
  const [workshopsEquippedError, setWorkshopsEquippedError] = useState(false);

  useEffect(() => {
    if (allPosts?.data?.[0]) {
      const data = allPosts.data[0];
      setNameOfPrincipal(data.name_of_principal || "");
      setDateOfReceiptOfInitialGrant(data.date_of_receipt_of_initial_grant || "");
      setIndustryPartners(data.industry_partners || [""]);
      setProjectManager(data.project_manager || "");
      setProcurementOfficer(data.procurement_officer || "");
      setCommunicationsOfficer(data.communications_officer || "");
      setMeOfficer(data.m_and_e_officer || "");
      setPriorityTrades(data.priority_trades || []);
      setListOfWorkshopsRenovated(data.list_of_workshops_renovated || []);
      setListOfWorkshopsEquipped(data.list_of_workshops_equipped || []);
      setTotalNoOfStudentsInTc(data.total_no_of_students_in_tc || "");
    }
  }, [allPosts]);

  const isUpdate = allPosts?.data?.length > 0;

  const createMutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(`/ps/c1/make-project-summary`, formData);
      return response.data;
    },
    onSuccess: (response) => {
      handleSuccess(response);
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.put(`/ps/c1/update-project-summary?jurisdiction=${jurisdiction}`, formData);
      return response.data;
    },
    onSuccess: (response) => {
      handleSuccess(response);
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const handleSuccess = (response) => {
    toast.success("Successful");
    setIsLoading(false);
    const isAvailable = response.data.created_data;
    const isPostMade = isAvailable.some(
      (post) => post.name_of_college === tc_name
    );
    if (isPostMade) {
      dispatch(setProjectSummaryPost(true));
    } else {
      dispatch(setProjectSummaryPost(false));
    }
    handleClose();
    onFormSubmit({
      nameOfCollege: tc_name,
      nameOfPrincipal,
      dateOfReceiptOfInitialGrant,
      industryPartners,
      projectManager,
      procurementOfficer,
      communicationsOfficer,
      meOfficer,
      priorityTrades,
      listOfWorkshopsRenovated,
      listOfWorkshopsEquipped,
      totalNoOfStudentsInTc,
      jurisdiction,
    });
  };

  const handleError = (err) => {
    if (err.response && err.response.status === 400) {
      toast.error("Post already exists");
    } else {
      toast.error("An error occurred");
    }
    setIsLoading(false);
    handleClose();
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleIndustryPartnersChange = (event, index) => {
    const updatedPartners = industryPartners.map((partner, i) =>
      i === index ? event.target.value : partner
    );
    setIndustryPartners(updatedPartners);
  };

  const handleAddField = () => {
    setIndustryPartners([...industryPartners, ""]);
  };

  const handleRemoveField = (index) => {
    const updatedPartners = industryPartners.filter((_, i) => i !== index);
    setIndustryPartners(updatedPartners);
  };

  const handleCheckboxChange = (event, category, setCategory, setError) => {
    const { value, checked } = event.target;
    let updatedArray = [];
    if (checked) {
      if (category.length < 3) {
        updatedArray = [...category, value];
        setError(false); // Reset error if a valid selection is made
      } else {
        setError(true);
        return;
      }
    } else {
      updatedArray = category.filter((item) => item !== value);
      setError(false); // Reset error if a deselection is made
    }
    setCategory(updatedArray);
  };

  const handleNumberInputChange = (event) => {
    const { name, value } = event.target;
    const numberValue = parseInt(value, 10) || 0;
    setTotalNoOfStudentsInTc(numberValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name_of_college: tc_name,
      name_of_principal: nameOfPrincipal,
      date_of_receipt_of_initial_grant: dateOfReceiptOfInitialGrant,
      industry_partners: industryPartners,
      project_manager: projectManager,
      procurement_officer: procurementOfficer,
      communications_officer: communicationsOfficer,
      m_and_e_officer: meOfficer,
      priority_trades: priorityTrades,
      list_of_workshops_renovated: listOfWorkshopsRenovated,
      list_of_workshops_equipped: listOfWorkshopsEquipped,
      total_no_of_students_in_tc: totalNoOfStudentsInTc,
      jurisdiction,
    };
    if (isUpdate) {
      updateMutation.mutate(formData);
    } else {
      createMutation.mutate(formData);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  const isProjectDataPost = useSelector(selectProjectSummaryPost);

  return (
    <div
      className={`fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center ${
        open ? "" : "hidden"
      }`}
    >
      {isProjectDataPost ? (
        <>
          {rQLoading ? (
            <motion.div
              ref={modalRef}
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
            >
              <div className="flex justify-between font-bold">
                <h1 className="text-2xl">TC IDEAS Project Information Edit</h1>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: "2rem" }}
                  className="text-red-500 cursor-pointer absolute top-2 right-4"
                />
              </div>
              <hr className="mt-2 mb-4" />
              <p>Form Data Loading...</p>
            </motion.div>
          ) : (
            <motion.div
              ref={modalRef}
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
            >
              <div className="flex justify-between font-bold">
                <h1 className="text-2xl">TC IDEAS Project Information Edit</h1>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: "2rem" }}
                  className="text-red-500 cursor-pointer absolute top-2 right-4"
                />
              </div>
              <hr className="mt-2 mb-4" />
              <form onSubmit={handleSubmit}>
                <FormInput
                  type="text"
                  name="name_of_college"
                  label="Name of College"
                  disabled
                  value={allPosts?.data?.[0]?.name_of_college || ""}
                />
                <FormInput
                  type="text"
                  name="name_of_principal"
                  label="Name of Principal"
                  value={nameOfPrincipal}
                  onChange={handleInputChange(setNameOfPrincipal)}
                />
                <FormInput
                  type="date"
                  name="date_of_receipt_of_initial_grant"
                  label="Date of receipt of initial grant (USD250,000)"
                  value={dateOfReceiptOfInitialGrant}
                  onChange={handleInputChange(setDateOfReceiptOfInitialGrant)}
                />
                <div className="mb-4">
                  <p className="font-bold">Industry Partners (List)</p>
                  {industryPartners.map((partner, index) => (
                    <div
                      key={index}
                      className="flex items-center mb-2 w-full relative"
                    >
                      <input
                        type="text"
                        name={`industry_partner${index}`}
                        value={partner}
                        onChange={(e) => handleIndustryPartnersChange(e, index)}
                        className="w-full py-2 px-3 border rounded-md"
                      />
                      <div className="absolute right-2 flex items-center">
                        {index > 0 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveField(index)}
                            className="text-black"
                          >
                            <RemoveIcon fontSize="small" />
                          </button>
                        )}
                        {index === industryPartners.length - 1 && (
                          <button
                            type="button"
                            onClick={handleAddField}
                            className="text-black ml-2"
                          >
                            <AddIcon fontSize="small" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <FormInput
                  type="text"
                  name="project_manager"
                  label="Project Manager"
                  value={projectManager}
                  onChange={handleInputChange(setProjectManager)}
                />
                <FormInput
                  type="text"
                  name="procurement_officer"
                  label="Procurement Officer"
                  value={procurementOfficer}
                  onChange={handleInputChange(setProcurementOfficer)}
                />
                <FormInput
                  type="text"
                  name="communications_officer"
                  label="Communications Officer"
                  value={communicationsOfficer}
                  onChange={handleInputChange(setCommunicationsOfficer)}
                />
                <FormInput
                  type="text"
                  name="m_and_e_officer"
                  label="M&E Officer"
                  value={meOfficer}
                  onChange={handleInputChange(setMeOfficer)}
                />
                <FormInput
                  type="number"
                  name="total_no_of_students_in_tc"
                  label="Total number of students in TC"
                  value={totalNoOfStudentsInTc}
                  onChange={handleNumberInputChange}
                />
                <div className="mb-4">
                  <p className="font-bold">Priority Trades (List)</p>
                  {workshopLabels.map((label, index) => (
                    <FormCheckbox
                      key={index}
                      id={`priority-trade-${index}`}
                      label={label}
                      value={label}
                      checked={priorityTrades.includes(label)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          priorityTrades,
                          setPriorityTrades,
                          setPriorityTradesError
                        )
                      }
                    />
                  ))}
                  {priorityTradesError && (
                    <p className="text-red-500 text-sm">
                      You can only select up to 3 options.
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <p className="font-bold">List of Workshops Renovated</p>
                  {workshopLabels.map((label, index) => (
                    <FormCheckbox
                      key={index}
                      id={`workshops-renovated-${index}`}
                      label={label}
                      value={label}
                      checked={listOfWorkshopsRenovated.includes(label)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          listOfWorkshopsRenovated,
                          setListOfWorkshopsRenovated,
                          setWorkshopsRenovatedError
                        )
                      }
                    />
                  ))}
                  {workshopsRenovatedError && (
                    <p className="text-red-500 text-sm">
                      You can only select up to 3 options.
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <p className="font-bold">List of Workshops Equipped</p>
                  {workshopLabels.map((label, index) => (
                    <FormCheckbox
                      key={index}
                      id={`workshops-equipped-${index}`}
                      label={label}
                      value={label}
                      checked={listOfWorkshopsEquipped.includes(label)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          e,
                          listOfWorkshopsEquipped,
                          setListOfWorkshopsEquipped,
                          setWorkshopsEquippedError
                        )
                      }
                    />
                  ))}
                  {workshopsEquippedError && (
                    <p className="text-red-500 text-sm">
                      You can only select up to 3 options.
                    </p>
                  )}
                </div>
                <div className="flex justify-center mt-4">
                  <CustomButton className="bg-active_bg" isLoading={isLoading}>
                    {isLoading ? "Submitting..." : "Submit"}
                  </CustomButton>
                </div>
              </form>
            </motion.div>
          )}
        </>
      ) : (
        <motion.div
          ref={modalRef}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
        >
          <div className="flex justify-between font-bold">
            <h1 className="text-2xl">TC IDEAS Project Information</h1>
            <CloseIcon
              onClick={handleClose}
              style={{ fontSize: "2rem" }}
              className="text-red-500 cursor-pointer absolute top-2 right-4"
            />
          </div>
          <hr className="mt-2 mb-4" />
          <form onSubmit={handleSubmit}>
            <FormInput
              type="text"
              name="name_of_college"
              label="Name of College"
              disabled
              value={tc_name}
            />
            <FormInput
              type="text"
              name="name_of_principal"
              label="Name of Principal"
              value={nameOfPrincipal}
              onChange={handleInputChange(setNameOfPrincipal)}
            />
            <FormInput
              type="date"
              name="date_of_receipt_of_initial_grant"
              label="Date of receipt of initial grant (USD250,000)"
              value={dateOfReceiptOfInitialGrant}
              onChange={handleInputChange(setDateOfReceiptOfInitialGrant)}
            />
            <div className="mb-4">
              <p className="font-bold">Industry Partners (List)</p>
              {industryPartners.map((partner, index) => (
                <div
                  key={index}
                  className="flex items-center mb-2 w-full relative"
                >
                  <input
                    type="text"
                    name={`industry_partner${index}`}
                    value={partner}
                    onChange={(e) => handleIndustryPartnersChange(e, index)}
                    className="w-full py-2 px-3 border rounded-md"
                  />
                  <div className="absolute right-2 flex items-center">
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveField(index)}
                        className="text-black"
                      >
                        <RemoveIcon fontSize="small" />
                      </button>
                    )}
                    {index === industryPartners.length - 1 && (
                      <button
                        type="button"
                        onClick={handleAddField}
                        className="text-black ml-2"
                      >
                        <AddIcon fontSize="small" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <FormInput
              type="text"
              name="project_manager"
              label="Project Manager"
              value={projectManager}
              onChange={handleInputChange(setProjectManager)}
            />
            <FormInput
              type="text"
              name="procurement_officer"
              label="Procurement Officer"
              value={procurementOfficer}
              onChange={handleInputChange(setProcurementOfficer)}
            />
            <FormInput
              type="text"
              name="communications_officer"
              label="Communications Officer"
              value={communicationsOfficer}
              onChange={handleInputChange(setCommunicationsOfficer)}
            />
            <FormInput
              type="text"
              name="m_and_e_officer"
              label="M&E Officer"
              value={meOfficer}
              onChange={handleInputChange(setMeOfficer)}
            />
            <FormInput
              type="number"
              name="total_no_of_students_in_tc"
              label="Total number of students in TC"
              value={totalNoOfStudentsInTc}
              onChange={handleNumberInputChange}
            />
            <div className="mb-4">
              <p className="font-bold">Priority Trades (List)</p>
              {workshopLabels.map((label, index) => (
                <FormCheckbox
                  key={index}
                  id={`priority-trade-${index}`}
                  label={label}
                  value={label}
                  checked={priorityTrades.includes(label)}
                  onChange={(e) =>
                    handleCheckboxChange(
                      e,
                      priorityTrades,
                      setPriorityTrades,
                      setPriorityTradesError
                    )
                  }
                />
              ))}
              {priorityTradesError && (
                <p className="text-red-500 text-sm">
                  You can only select up to 3 options.
                </p>
              )}
            </div>
            <div className="mb-4">
              <p className="font-bold">List of Workshops Renovated</p>
              {workshopLabels.map((label, index) => (
                <FormCheckbox
                  key={index}
                  id={`workshops-renovated-${index}`}
                  label={label}
                  value={label}
                  checked={listOfWorkshopsRenovated.includes(label)}
                  onChange={(e) =>
                    handleCheckboxChange(
                      e,
                      listOfWorkshopsRenovated,
                      setListOfWorkshopsRenovated,
                      setWorkshopsRenovatedError
                    )
                  }
                />
              ))}
              {workshopsRenovatedError && (
                <p className="text-red-500 text-sm">
                  You can only select up to 3 options.
                </p>
              )}
            </div>
            <div className="mb-4">
              <p className="font-bold">List of Workshops Equipped</p>
              {workshopLabels.map((label, index) => (
                <FormCheckbox
                  key={index}
                  id={`workshops-equipped-${index}`}
                  label={label}
                  value={label}
                  checked={listOfWorkshopsEquipped.includes(label)}
                  onChange={(e) =>
                    handleCheckboxChange(
                      e,
                      listOfWorkshopsEquipped,
                      setListOfWorkshopsEquipped,
                      setWorkshopsEquippedError
                    )
                  }
                />
              ))}
              {workshopsEquippedError && (
                <p className="text-red-500 text-sm">
                  You can only select up to 3 options.
                </p>
              )}
            </div>
            <div className="flex justify-center mt-4">
              <CustomButton className="bg-active_bg" isLoading={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </CustomButton>
            </div>
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default ModalSummaryProjectForm;
