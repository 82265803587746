import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FormInput, FormMediaUpload, FormRadioInput } from "../../../form";
import CustomButton from "../../../button";
import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { usePostIndicator } from "../../../../Services/queries";
import { setIoi2Post } from "../../../../features/auth/isPostMade";

const IntermediateOutcome2 = ({ title, closeForm }) => {
  const modalRef = useRef(null);
  const authToken = useSelector((state) => state.auth.token);
  const decodedTkn = jwtDecode(authToken);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const email = useSelector(selectCurrentUser);

  const { tc_name } = decodedTkn;
  const [errors, setErrors] = useState({});

  const [state, setState] = useState("");
  const [internshipArrangements, setInternshipArrangements] = useState("");
  const [noOfIndustryPartners, setNoOfIndustryPartners] = useState("");
  const [noOfInternshipArrangements, setNoOfInternshipArrangements] =
    useState("");
  const [
    developmentOfShortTermSkillsUpgradingCourses,
    setDevelopmentOfShortTermSkillsUpgradingCourses,
  ] = useState("");
  const [
    noDevelopmentOfShortTermSkillsUpgradingCourses,
    setNoDevelopmentOfShortTermSkillsUpgradingCourses,
  ] = useState("");
  const [latestTcStatusReportPdf, setLatestTcStatusReportPdf] = useState([]);
  const [attendanceSheetPdf, setAttendanceSheetPdf] = useState([]);
  const { mutate, isPending } = usePostIndicator();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/ioi/c1/make-post-no-of-training-programs-delivered-monitored",
        formData
      );
      return response;
    },

    onSuccess: (response) => {
      toast.success("Successfully created");
      setIsLoading(false);
      const isCheck = response.data.newDoc;
      const letSee = `${jurisdiction}_tc`;
      const isPostMade = isCheck[letSee].some(
        (post) => post.tc_name === tc_name
      );
      
      if (isPostMade) {
        dispatch(setIoi2Post(true));
      } else {
        dispatch(setIoi2Post(false));
      }
      closeForm();
    },

    onError: (res) => {
      toast.error("Error creating indicators");
      setIsLoading(false);
      closeForm();
    },
  });
  const validate = () => {
    const newErrors = {};
    const requiredFields = {
      state,
    };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleRadioChange = (e) => {
    setInternshipArrangements(e.target.value);
  };
  const handleRadioChange2 = (e) => {
    setDevelopmentOfShortTermSkillsUpgradingCourses(e.target.value);
  };

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]); // Store a single file for each FormMediaUpload
  };

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("state", state);
    formData.append("tc_name", tc_name);
    formData.append("jurisdiction", jurisdiction);
    formData.append("email", email);
    formData.append("internship_arrangements", internshipArrangements);
    formData.append("no_of_industry_partners", noOfIndustryPartners);
    formData.append(
      "no_of_internship_arrangements",
      noOfInternshipArrangements
    );
    formData.append(
      "development_of_short_term_skills_upgrading_courses",
      developmentOfShortTermSkillsUpgradingCourses
    );
    formData.append(
      "no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry",
      noDevelopmentOfShortTermSkillsUpgradingCourses
    );
    if (latestTcStatusReportPdf)
      formData.append("pdfs", latestTcStatusReportPdf);
    if (attendanceSheetPdf) formData.append("pdfs", attendanceSheetPdf);

    mutation.mutate(formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm(); // Assuming closeForm sets the state that controls the modal visibility
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="state"
            label="Enter State Location"
            value={state.toLowerCase()}
            onChange={(e) => handleInputChange(e, setState)}
            errorMessage={errors.state}
            isRequired={true}
          />
          <FormRadioInput
            type="radio"
            name="internship_arrangements"
            label="Internship arrangements with industry partners enhanced by IDEAS Project"
            checkedValue={internshipArrangements}
            onChange={handleRadioChange}
          />
          <FormInput
            type="number"
            name="no_of_industry_partners"
            label="Training Programs delivered and monitored in partnership with the private sector"
            value={noOfIndustryPartners}
            onChange={(e) => handleInputChange(e, setNoOfIndustryPartners)}
          />
          <FormInput
            type="number"
            name="no_of_internship_arrangements"
            label="Number of internship arrangements"
            value={noOfInternshipArrangements}
            onChange={(e) =>
              handleInputChange(e, setNoOfInternshipArrangements)
            }
          />

          <FormRadioInput
            type="radio"
            name="development_of_short_term_skills_upgrading_courses"
            label="Development of short-term skills upgrading courses for unemployed youths in partnership with industry partners"
            checkedValue={developmentOfShortTermSkillsUpgradingCourses}
            onChange={handleRadioChange2}
          />
          <FormInput
            type="number"
            name="no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry"
            value={noDevelopmentOfShortTermSkillsUpgradingCourses}
            label="Number of Newly Developed Short Term Skills Upgrading Courses on Partnership with Industry"
            onChange={(e) =>
              handleInputChange(
                e,
                setNoDevelopmentOfShortTermSkillsUpgradingCourses
              )
            }
          />

          <FormMediaUpload
            type="file"
            id="latest_tc_status_report_pdf"
            name="latest_tc_status_report_pdf"
            label="Upload the latest TC Status report(s) in one PDF Document(file max size 5MB)"
            onChange={(e) => handleFileChange(e, setLatestTcStatusReportPdf)}
            fileNames={latestTcStatusReportPdf?.map((file) => file.name)}
          />
          <FormMediaUpload
            type="file"
            id="attendance_sheet_pdf"
            name="attendance_sheet_pdf"
            label="Upload the attendance sheet of students in each course(file max size 5MB)"
            onChange={(e) => handleFileChange(e, setAttendanceSheetPdf)}
            fileNames={attendanceSheetPdf?.map((file) => file.name)}
          />

          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submiting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default IntermediateOutcome2;
