import React, { useState } from "react";
import PageHeader from "../../../Components/page_header";
import { navListItems } from "../../../Data_Objects/sidenav";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IoiPreview from "../Preview/table_components/Ioi";
import PdoPreview from "../Preview/table_components/Pdo";
import federalLogo from "../../../Assets/fstc-doma_logo.svg";
import stateLogo from "../../../Assets/Gtc-Bagauda.svg";
import Review from "../Review/Review";
import ReviewList from "../../../Components/reviewList";
import {
  fetchAllTechnicalCollegePosts,
  fetchAllTechnicalCollegesForNA,
} from "../../../utils/apiContext";
import { useQuery } from "@tanstack/react-query";
import { selectCurrentUserRole } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import { toast } from "sonner";
import ShowLoader from "../../../utils/showLoader";

const Overview = () => {
  const [showAnswers1, setShowAnswers1] = useState(false);
  const [tabIndex1, setTabIndex1] = useState(0);
  const [tabIndex2, setTabIndex2] = useState(0);
  const userRoles = useSelector(selectCurrentUserRole);

  const displayAnswers1 = () => {
    setShowAnswers1(!showAnswers1);
  };

  const handleChange1 = (event, newValue) => {
    setTabIndex1(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setTabIndex2(newValue);
  };

  const schoolName =
    tabIndex1 === 0
      ? "Federal Science And Technical College Doma"
      : "Government Technical College Bagauda";

  const schoolLogo = tabIndex1 === 0 ? federalLogo : stateLogo;

  const {
    data: tcDatas_NA,
    isLoading: tcDatas_NAIsLoading,
    error: tcDatas_NA_Error,
  } = useQuery({
    queryKey: "fetchAllTcDatasPostsForNA",
    queryFn: userRoles === "National_Admin" && fetchAllTechnicalCollegesForNA,
    enabled: userRoles === "National_Admin",
  });
  let stateData = [];
  if (tcDatas_NA && Array.isArray(tcDatas_NA)) {
    stateData = [...(tcDatas_NA[0]?.all_posts || [])];
  }
  let federalData = [];
  if (tcDatas_NA && Array.isArray(tcDatas_NA)) {
    federalData = [...(tcDatas_NA[1]?.all_posts || [])];
  }
  if (tcDatas_NAIsLoading) {
    return <Loader />;
  }
  if (tcDatas_NA_Error) {
    return toast.error(tcDatas_NA_Error);
  }
  return (
    <div>
      <PageHeader title="Overview" />
      <Tabs
        value={tabIndex1}
        onChange={handleChange1}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0E4F2F",
            height: "5px",
            borderRadius: "1rem",
          },
        }}
        sx={{
          justifyContent: "center",
          paddingLeft: "20px",
          marginBottom: "12px",
        }}
      >
        <Tab
          label="Federal Technical Colleges"
          sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#9BB6A8",
            fontWeight: "bold",
            "&.Mui-selected": {
              color: "#0E4F2F",
            },
          }}
        />
        <Tab
          label="State Technical Colleges"
          sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#9BB6A8",
            fontWeight: "bold",
            "&.Mui-selected": {
              color: "#0E4F2F",
            },
          }}
        />
      </Tabs>

      {/* conditionally render data list for state or federal */}
      {tabIndex1 === 1 &&
        stateData?.map((data) => (
          <ReviewList data={data} propsJurisdiction={"state"} key={data?._id} />
        ))}
      {tabIndex1 === 0 &&
        federalData?.map((data) => (
          <ReviewList
            data={data}
            propsJurisdiction={"federal"}
            key={data?._id}
          />
        ))}

      {showAnswers1 && (
        <div>
          <Tabs
            value={tabIndex2}
            onChange={handleChange2}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#0E4F2F",
                height: "5px",
                borderRadius: "1rem",
              },
            }}
            sx={{
              justifyContent: "center",
              paddingLeft: "30px",
              marginBottom: "12px",
            }}
          >
            <Tab
              label="Intermediate Indicators"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
            <Tab
              label="PDO Indicators"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
          </Tabs>
          {tabIndex2 === 0 && (
            <div className="mx-4 h-[55vh]">
              <IoiPreview /> {/* Integrating IoiPreview component */}
            </div>
          )}
          {tabIndex2 === 1 && (
            <div className="mx-4 h-[55vh]">
              <PdoPreview /> {/* Integrating PdoPreview component */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Overview;
