import React, { useState, useEffect } from "react";
import backgroundImage from "../../Assets/backimage5.png";
import { useLocation, useNavigate } from "react-router-dom";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentTcName,
  setCredentials,
} from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { toast } from "sonner";
import Loader from "../../Components/Loader";
import { jwtDecode } from "jwt-decode";
import { clearPosts, setTcPost } from "../../features/auth/isPostMade";
import { fetchAllTechnicalCollegePosts } from "../../utils/apiContext";

const LoginPage = () => {
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const storedTcName = useSelector(selectCurrentTcName);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formValues;
    const errors = validate(formValues);

    setIsSubmit(true);
    setIsSignedUp(Object.keys(errors).length === 0);
    try {
      const userData = await login({ email, password }).unwrap();

      const { user_role, token, jurisdiction, validator_state, tc_name } =
        userData;
      const accessToken = token.access;

      console.log(userData, jurisdiction);

      // if (storedTcName && storedTcName !== tc_name) {
      //   dispatch(clearPosts()); // Reset all post states if tc_name has changed
      // }

      dispatch(
        setCredentials({
          user: email,
          accessToken,
          user_role,
          jurisdiction,
          validator_state: validator_state,
          tc_name,
        })
      );

      if (user_role === "M_E_OFFICE") {
        const decodedTkn = jwtDecode(accessToken);
        const { tc_name } = decodedTkn;
        const formattedRoute = tc_name.replace(/[ ,]/g, "_");

        const lastRoute =
          location.state?.from?.pathname ||
          `/m_e_officer/analytics/${formattedRoute}`;
        localStorage.setItem("lastRoute", lastRoute);

        navigate(`/m_e_officer/analytics/${formattedRoute}`);
      } else if (user_role === "Validator") {
        navigate(`/validator/analytics`);
      } else if (user_role === "Reviewer") {
        navigate(`/reviewer/analytics`);
      } else if (user_role === "National_Admin") {
        navigate(`/national_admin/analytics`);
      } else if (user_role === "Super_Admin") {
        navigate(`/super_admin/analytics`);
      } else {
        navigate("/auth");
      }

      toast.success("Login succesful");
    } catch (err) {
      if (err?.data?.status) {
        toast.error(err?.data?.message);
      }
    }
  };

  useEffect(() => {
    // Set body style on component mount
    document.body.style.border = "0px solid #000000";

    // Clean up the style when the component unmounts
    return () => {
      document.body.style.border = "";
    };
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      setIsSignedUp(false);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const pass_regex = ""
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 15) {
      errors.password = "Password cannot exceed 10 characters";
    }
    return errors;
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    // You can save the state of rememberMe to localStorage or cookies
  };

  const handleForgotPassword = () => {
    // Handle forgot password functionality, e.g., redirect to password reset page
    console.log("Forgot password clicked");
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="customer-border min-h-screen flex flex-col justify-center items-center bg-cover bg-center  text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          border: "0px solid transparent !important",
        }}
      >
        <div className="flex flex-col w-1/3 mx-auto mt-56">
          <div className="flex items-center justify-center">
            <h1 className="text-center font-bold text-[24px]">IDEAS PROJECT</h1>
            <KeyOutlinedIcon className="text-linear_grad_1 mt-1 ml-1" />
          </div>
          <p className="text-sm text-center">Sign in to access your account</p>
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="email"
              className="text-white font-semibold text-[13px]"
            >
              Email
            </label>
            <input
              type="text"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              placeholder="Enter email"
              required={true}
              className="border border-input_border bg-white py-2 pl-4 mb-4 text-input_text placeholder:text-gray-40 focus:ring-1 focus:ring-main_bg focus:ring-opacity-50 outline-none w-full rounded-md text-[13px]"
            />
            {formErrors.email && (
              <p className="text-red-500">{formErrors.email}</p>
            )}
            <div className="flex justify-between mt-2">
              <label
                htmlFor="password"
                className="text-white text-[13px] font-semibold"
              >
                Password
              </label>
              <div>
                <button
                  type="button"
                  onClick={handleForgotPassword}
                  className="text-white-500 hover:underline font-semibold text-[13px]"
                >
                  Forgot password?
                </button>
              </div>
            </div>

            <input
              type="text"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              placeholder="Enter password"
              required={true}
              className="border border-input_border bg-white py-2 pl-4 mb-4 text-input_text placeholder:text-gray-40 focus:ring-1 focus:ring-main_bg focus:ring-opacity-50 outline-none w-full rounded-md text-[13px]"
            />
            {formErrors.password && (
              <p className="text-red-500">{formErrors.password}</p>
            )}
            <div className="flex justify-between w-full">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                  className=" mr-1 mt-3 "
                />

                <label
                  htmlFor="rememberMe"
                  className="text-white text-[13px] mt-3 font-semibold"
                >
                  Remember me
                </label>
              </div>
            </div>

            <button
              type="submit"
              className="btn-primary mt-10 bg-active_btn rounded-md py-2 w-full text-[15px] font-semibold shadow-md shadow-black"
            >
              Sign In
            </button>
            <p className="text-center mt-4 text-[10px]">
              LEARN MORE ABOUT THE PROJECT
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
