import { jwtDecode } from "jwt-decode";

export const getFormattedToken = (token) => {
  if (!token) {
    throw new Error("Token is required");
  }
  const decodedTkn = jwtDecode(token);
  const { tc_name } = decodedTkn;

  // if (!tc_name) {
  //   throw new Error("Token does not contain tc_name");
  // }

  return tc_name.replace(/[ ,]/g, "_");
};



export const getTcName = (token) => {
  if (!token) {
    throw new Error("Token is required");
  }
  const decodedTkn = jwtDecode(token);
  const { tc_name } = decodedTkn;

  // if (!tc_name) {
  //   throw new Error("Token does not contain tc_name");
  // }

  return tc_name;
};

export default getFormattedToken;
