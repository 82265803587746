import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../Services/axiosInstance";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { toSentenceCase } from "../../utils/toSentenceCase";
import { FormDropdown } from "../form";
import { FormInput } from "../form";
import { setTcPost } from "../../features/auth/isPostMade";

const ModalTcForm = ({ open, handleClose }) => {
  const modalRef = useRef(null);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;

  const toCapJurisdiction = toSentenceCase(jurisdiction);

  const [institutionState, setInstitutionState] = useState("");
  const [institutionCode, setInstitutionCode] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [principalName, setPrincipalName] = useState("");
  const [institutionType, setInstitutionType] = useState("");
  const [institutionCategory, setInstitutionCategory] = useState("");
  const [institutionOwnership, setInstitutionOwnership] = useState("");
  const [institutionLocation, setInstitutionLocation] = useState("");
  const [institutionYOE, setInstitutionYOE] = useState("");
  const [institutionAddress, setInstitutionAddress] = useState("");
  const [institutionPhone, setInstitutionPhone] = useState("");
  const [institutionEmail, setInstitutionEmail] = useState("");
  const [maleHostelNumber, setMaleHostelNumber] = useState("");
  const [femaleHostelNumber, setFemaleHostelNumber] = useState("");
  const [canteensNum, setCanteensNum] = useState("");

  const [institutionSponsor, setInstitutionSponsor] = useState("");
  const [numOfBuses, setNumOfBuses] = useState("");
  const [numOfGens, setNumOfGens] = useState("");
  const [powerType, setPowerType] = useState("");
  const [powerCapacity, setPowerCapacity] = useState("");
  const [agriculturalFields, setAgriculturalFields] = useState("");

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      try {
        setIsLoading(true);

        const response = await axiosInstance.post(
          "/tc/c1/make-post-tc",
          formData
        );

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess: (response) => {
      toast.success("Successful");
      queryClient.invalidateQueries(["tcData"]);
      setIsLoading(false);
      handleClose();
      const isCheck = response.data.new_item;
      const isPostMade = isCheck.some(
        (post) => post.institution_name === tc_name
      );
      if (isPostMade) {
        dispatch(setTcPost(true));
      } else {
        dispatch(setTcPost(false));
      }
      handleClose();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Form Cannot be Empty");
        handleClose();
        // TODO: WHAT ARE THE REQUIRED FORM FIELDS SO I CAN HANDLE THE ERROR MESSAGE PROPERLY
        setIsLoading(false);
      } else {
        handleClose();
        toast.error("An Error Occurred");
      }
    },
  });

  const handleChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newObj = {
      jurisdiction: jurisdiction,
      institution_state: institutionState,
      institution_code: institutionCode,
      institution_name: tc_name,
      principal_name: principalName,
      institution_type: toCapJurisdiction,
      institution_cat: institutionCategory,
      institution_ownership: institutionOwnership,
      institution_year_est: institutionYOE,
      institution_address: institutionAddress,
      institution_phone: institutionPhone,
      institution_email: institutionEmail,
      male_hostel_number: maleHostelNumber,
      female_hostel_number: femaleHostelNumber,
      canteens_num: canteensNum,
      sponsor: institutionSponsor,
      bus_num: numOfBuses,
      gen_num: numOfGens,
      power_type: powerType,
      power_capacity: powerCapacity,
      agricultural_fields: agriculturalFields,
    };

    mutation.mutate(newObj);

    // handleClose(); // Close the modal after submission
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full z-20 h-full bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">Add TC</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="institution_state"
            label="Enter School's State Location"
            value={institutionState.toLowerCase()}
            onChange={(e) => handleChange(e, setInstitutionState)}
          />
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Code
            </label>
            <input
              type="number"
              name="institution_code"
              value={institutionCode}
              onChange={(e) => handleChange(e, setInstitutionCode)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution code"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Name
            </label>
            <input
              disabled
              type="text"
              name="institution_name"
              value={tc_name}
              onChange={(e) => handleChange(e, setInstitutionName)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Principal Name
            </label>
            <input
              type="text"
              name="principal_name"
              value={principalName}
              onChange={(e) => handleChange(e, setPrincipalName)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter principal name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Email
            </label>
            <input
              type="text"
              name="institution_email"
              value={institutionEmail}
              onChange={(e) => handleChange(e, setInstitutionEmail)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Type
            </label>
            <input
              type="text"
              name="institution_type"
              value={toCapJurisdiction}
              disabled
              onChange={(e) => handleChange(e, setInstitutionType)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution type"
            />
          </div>
          <div className="mb-4">
            <FormDropdown
              name="institution_category"
              label="Institution Category"
              options={[
                { value: "Technical", label: "Technical" },
                { value: "Conventional", label: "Conventional" },
                {
                  value: "Science and Technical",
                  label: "Science and Technical",
                },
              ]}
              value={institutionCategory}
              onChange={(e) => handleChange(e, setInstitutionCategory)}
              placeholder="Select institution category"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <FormDropdown
              name="institution_ownership"
              label="Institution Ownership"
              options={[
                { value: "Public", label: "Public" },
                { value: "Private", label: "Private" },
              ]}
              value={institutionOwnership}
              onChange={(e) => handleChange(e, setInstitutionOwnership)}
              placeholder="Select institution ownership"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Year of Establishment
            </label>
            <input
              type="text"
              name="institution_year_est"
              value={institutionYOE}
              onChange={(e) => handleChange(e, setInstitutionYOE)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution year of establishment"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Address
            </label>
            <input
              type="text"
              name="institution_address"
              value={institutionAddress}
              onChange={(e) => handleChange(e, setInstitutionAddress)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution address"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Institution Phone Number
            </label>
            <input
              type="Number"
              name="institution_phone"
              value={institutionPhone}
              onChange={(e) => handleChange(e, setInstitutionPhone)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter institution phone"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Number of Male Hostels
            </label>
            <input
              type="Number"
              name="male_hostel_number"
              value={maleHostelNumber}
              onChange={(e) => handleChange(e, setMaleHostelNumber)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Number of Male Hostels"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Number of Female Hostels
            </label>
            <input
              type="Number"
              name="female_hostel_number"
              value={femaleHostelNumber}
              onChange={(e) => handleChange(e, setFemaleHostelNumber)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Number of Female Hostels"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Number of Canteens
            </label>
            <input
              type="number"
              name="canteens_num"
              value={canteensNum}
              onChange={(e) => handleChange(e, setCanteensNum)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Number of Canteens"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">sponsor</label>
            <input
              type="text"
              name="sponsor"
              value={institutionSponsor}
              onChange={(e) => handleChange(e, setInstitutionSponsor)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter sponsor"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Number of Buses
            </label>
            <input
              type="Number"
              name="bus_num"
              value={numOfBuses}
              onChange={(e) => handleChange(e, setNumOfBuses)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter number of buses"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Number of Generators
            </label>
            <input
              type="Number"
              name="gen_num"
              value={numOfGens}
              onChange={(e) => handleChange(e, setNumOfGens)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Number of Generators"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Power Type</label>
            <input
              type="text"
              name="power_type"
              value={powerType}
              onChange={(e) => handleChange(e, setPowerType)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter power type"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Power Capacity
            </label>
            <input
              type="text"
              name="power_capacity"
              value={powerCapacity}
              onChange={(e) => handleChange(e, setPowerCapacity)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter power capacity"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Agricultural Fields
            </label>
            <input
              type="text"
              name="agricultural_fields"
              value={agriculturalFields}
              onChange={(e) => handleChange(e, setAgriculturalFields)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter agricultural fields"
            />
          </div>

          {/* Add more TC fields here */}

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ModalTcForm;
