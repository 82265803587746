import React from "react";

import axios from "axios";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";

// import CrossCuttingIssuesDropdown from "./DropdownCci";
// import CrossCuttingIssuesCard from "./Cards_component/CrossCuttingIssuesCard";

import AnalyticsCard from "../../Pages/Component1/Analytics_user3/Cards_component/AnalyticsCards";
import IntermediateDropdown from "../../Pages/Component1/Analytics_user3/DropdownIo";
import PdoDropdown from "../../Pages/Component1/Analytics_user3/DropdownPdo";
import PdoCard from "../../Pages/Component1/Analytics_user3/Cards_component/PdoCard";
import GISComponent from "../../Pages/Component1/GIS/Gis";
import IntermediateCard from "../../Pages/Component1/Analytics_user3/Cards_component/IntermediateCard";

const Component1DashboardDisplay = ({
  totalStudents,
  totalTTIs,
  totalPDO2,
}) => {
  return (
    <div className="flex flex-col mt-3 px-3">
      <h2 className=" font-bold text-lg mb-5">Analytics for component 1</h2>
      <div className="flex gap-3">
        <AnalyticsCard
          title="Total TCs"
          value="1140"
          icon={AccountBalanceOutlinedIcon}
        />
        <AnalyticsCard
          title="Total Students"
          value={totalStudents}
          icon={PeopleAltOutlinedIcon}
        />
        <AnalyticsCard
          title="Total Priority Trades"
          value="500"
          icon={SsidChartOutlinedIcon}
        />
        <AnalyticsCard
          title="Total Number of TTIs"
          value={totalTTIs}
          icon={Groups2OutlinedIcon}
        />
      </div>

      <div className="mt-4">
        {/* <div className="flex items-center gap-5 justify-end">
          <p
            className="text-primary_text_light mt-1"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Select Intermediate Outcome Component
          </p>
          <IntermediateDropdown />
        </div> */}
        <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
          <IntermediateCard />
        </div>
      </div>

      <div className="mt-4">
        {/* <div className="flex items-center gap-5 justify-end">
          <p
            className="text-primary_text_light mt-1"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Select Pdo Component
          </p>
          <PdoDropdown />
        </div> */}
        <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
          <PdoCard totalPDO2={totalPDO2} />
        </div>
      </div>
      {/* <div className="mt-4">
  <div className="flex items-center gap-5 justify-end">
    <p
      className="text-primary_text_light mt-1"
      style={{ fontFamily: "Poppins, sans-serif" }}
    >
      Select Cross-cutting Issues Component
    </p>
    <CrossCuttingIssuesDropdown />
  </div>
  <div className="custom-shadow bg-white px-4 py-4 rounded-lg mt-1">
    <CrossCuttingIssuesCard />
  </div>
</div> */}
      <br />
      <br />
      <div className="flex items-center gap-5">
        <p
          className="text-primary_text_light mt-1"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          GIS map
        </p>
      </div>

      <GISComponent />
    </div>
  );
};

export default Component1DashboardDisplay;
