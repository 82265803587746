import { useState, useEffect, useRef } from "react";
import {
  FormRadioInput,
  FormMediaUpload,
  FormInput,
  FormDropdown,
} from "../../../form";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../button";
import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { setIoi1Post } from "../../../../features/auth/isPostMade";

const options = [
  { value: "once", label: "Once" },
  { value: "twice", label: "Twice" },
  { value: "thrice", label: "Thrice" },
  { value: "four times", label: "Four Times" },
  { value: "none", label: "None" },
];

const IntermediateOutcome1 = ({ title, closeForm }) => {
  const modalRef = useRef(null);
  const authToken = useSelector((state) => state.auth.token);
  const decodedTkn = jwtDecode(authToken);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const email = useSelector(selectCurrentUser);

  const { tc_name } = decodedTkn;
  const [errors, setErrors] = useState({});
  const [state, setState] = useState("");
  const [schIdeasProjectTeamEstablished, setSchIdeasProjectTeamEstablished] =
    useState("");
  const [
    noOfMousSignedWithIndustryPartners,
    setNoOfMousSignedWithIndustryPartners,
  ] = useState("");
  const [noOfTimesCiuMetOverPastYear, setNoOfTimesCiuMetOverPastYear] =
    useState("");
  const [reportPdf, setReportPdf] = useState([]);
  const [allSignedMousPdfs, setAllSignedMousPdfs] = useState([]);
  const [minutesPdf, setMinutesPdf] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [maxMOUs, setMaxMOUs] = useState(false);

  const dispatch = useDispatch();
  const validate = () => {
    const newErrors = {};
    const requiredFields = {
      state,
    };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/ioi/c1/make-post-for-no-of-supported-tc",
        formData
      );

      return response;
    },

    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      const isCheck = response.data.newDoc;
      const letSee = `${jurisdiction}_tc`;
      const isPostMade = isCheck[letSee].some(
        (post) => post.tc_name === tc_name
      );
      if (isPostMade) {
        dispatch(setIoi1Post(true));
      } else {
        dispatch(setIoi1Post(false));
      }
      closeForm();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An Error Occurred");
      }
      setIsLoading(false);
      closeForm();
    },
  });

  const handleRadioChange = (e) => {
    setSchIdeasProjectTeamEstablished(e.target.value);
  };

  const handleFileChange = (e, setFile) => {
    const files = Array.from(e.target.files);
    if (files.length > 4) {
      toast.error("You can only upload a maximum of 4 PDFs.");
      return;
    }
    setFile((prevFiles) => [...prevFiles, ...files]);
  };

  const handleInputChange = (e, setValue) => {
    const { name, value } = e.target;

    if (name === "no_of_mous_signed_with_industry_partners" && value > 4) {
      setMaxMOUs(true);
      return;
    } else {
      setMaxMOUs(false);
    }

    setValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }

    const formData = new FormData();
    formData.append("state", state);
    formData.append("tc_name", tc_name);
    formData.append("jurisdiction", jurisdiction);
    formData.append("email", email);
    formData.append(
      "sch_ideas_project_team_established",
      schIdeasProjectTeamEstablished
    );
    formData.append(
      "no_of_mous_signed_with_industry_partners",
      noOfMousSignedWithIndustryPartners
    );
    formData.append(
      "no_of_times_ciu_met_over_past_year",
      noOfTimesCiuMetOverPastYear
    );
    reportPdf.forEach((file) => formData.append("pdfs", file));
    allSignedMousPdfs.forEach((file) => formData.append("pdfs", file));
    minutesPdf.forEach((file) => formData.append("pdfs", file));

    mutation.mutate(formData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="state"
            label="Enter State Location"
            value={state.toLowerCase()}
            onChange={(e) => handleInputChange(e, setState)}
            errorMessage={errors.state}
            isRequired={true}
          />
          <FormRadioInput
            type="radio"
            name="sch_ideas_project_team_established"
            label="CIU established?"
            checkedValue={schIdeasProjectTeamEstablished}
            onChange={handleRadioChange}
          />

          <FormMediaUpload
            type="file"
            id="report"
            name="report"
            label="Upload the IDEAS Project CIU report (file max size 5MB)"
            onChange={(e) => handleFileChange(e, setReportPdf)}
            fileNames={reportPdf?.map((file) => file.name)}
          />

          <FormInput
            type="number"
            name="no_of_mous_signed_with_industry_partners"
            label="Number of MOUs signed with industry partners"
            value={noOfMousSignedWithIndustryPartners}
            onChange={(e) =>
              handleInputChange(e, setNoOfMousSignedWithIndustryPartners)
            }
          />
          {maxMOUs && (
            <p className=" text-red-500 text-sm">
              Can only take a maximum of 4 MOUs.
            </p>
          )}

          <FormMediaUpload
            type="file"
            id="all_signed_mous_pdfs"
            name="all_signed_mous_pdfs"
            label="Upload all SIGNED MOUs (max 4 PDFs, file max size 5MB each)"
            onChange={(e) => handleFileChange(e, setAllSignedMousPdfs)}
            fileNames={allSignedMousPdfs.map((file) => file.name)}
          />

          <FormDropdown
            name="no_of_times_ciu_met_over_past_year"
            label="Number of CIU meetings held in the past 12 Months (1 year)"
            value={noOfTimesCiuMetOverPastYear}
            options={options}
            onChange={(e) =>
              handleInputChange(e, setNoOfTimesCiuMetOverPastYear)
            }
            placeholder="- Choose -"
          />

          <FormMediaUpload
            type="file"
            id="minutes_pdf"
            name="minutes_pdf"
            label="Upload all CIU minutes of meeting in one PDF Document (file max size 5MB)"
            onChange={(e) => handleFileChange(e, setMinutesPdf)}
            fileNames={minutesPdf.map((file) => file.name)}
          />

          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default IntermediateOutcome1;
