import { Button } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  selectCurrentJurisdiction,
  selectCurrentTcName,
} from "../../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import {
  fetchAllIOI4Posts,
  fetchIOI4PostById,
} from "../../../../utils/apiContext";

export const ThirdBarChartDisplay = () => {
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const tc_name = useSelector(selectCurrentTcName);

  const { data: Ioi4Data } = useQuery({
    queryKey: jurisdiction === "state" ? ["ioi4"] : ["ioi4b"],
    queryFn: () => fetchAllIOI4Posts(jurisdiction),
  });
  const postQueries = useQueries({
    queries: Ioi4Data && Ioi4Data?.map((post) => ({
      queryKey:
        jurisdiction === "state" ? ["ioi4", post?._id] : ["ioi4b", post?._id],
      queryFn: () => fetchIOI4PostById(post?._id, jurisdiction),
    })),
  });
  const postsWithDetails = postQueries?.map((query) => query.data);
  const filteredPosts = postsWithDetails?.filter(
    (post) => post?.tc_name === tc_name
  );
  const renovated = filteredPosts[0]?.no_of_workshops_renovated;
  const equipped =
    filteredPosts[0]
      ?.no_of_workshops_equipped_with_modern_tools_and_ready_for_use?.value;

  if (renovated === 0 && equipped === 0) {
    return (
      <div>
        Nothing to display, proceed to enter data in{" "}
        <Link to="/beneficiaries-data">
          <Button sx={{ textTransform: "capitalize" }}>here</Button>
        </Link>
      </div>
    );
  }
  return (
    <BarChart
      series={[
        {
          data: [equipped],
          color: "#CFF0B5",
          label: "Equipped",
          barWidth: 0.2,
        },
        {
          data: [renovated],
          color: "#BC5645",
          label: "Renovated",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={250}
      xAxis={[{ data: ["Workshops"], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
      barLabel="value"
      borderRadius={10}
    />
  );
};
export const FirstBarChartDisplay = ({ maleCount, femaleCount }) => {
  if (maleCount === 0 && femaleCount === 0) {
    return (
      <div>
        Nothing to display, proceed to enter data in{" "}
        <Link to="/beneficiaries-data">
          <Button sx={{ textTransform: "capitalize" }}>here</Button>
        </Link>
      </div>
    );
  }
  return (
    <BarChart
      series={[
        {
          data: [maleCount],
          color: "#CFF0B5",
          label: "Male",
          barWidth: 0.2,
        },
        {
          data: [femaleCount],
          color: "#BC5645",
          label: "Female",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={250}
      xAxis={[{ data: ["Gender"], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
      barLabel="value"
      borderRadius={10}
    />
  );
};

export const SecondBarChartDisplay = ({ maleCount, femaleCount }) => {
  if (maleCount === 0 && femaleCount === 0) {
    return (
      <div>
        Nothing to display, proceed to enter data in{" "}
        <Link to="/beneficiaries-data">
          <Button sx={{ textTransform: "capitalize" }}>here</Button>
        </Link>
      </div>
    );
  }
  return (
    <BarChart
      series={[
        {
          data: [maleCount],
          color: "#CFF0B5",
          label: "Male",
          barWidth: 0.2,
        },
        {
          data: [femaleCount],
          color: "#BC5645",
          label: "Female",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={250}
      xAxis={[{ data: ["Gender"], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
      barLabel="value"
      borderRadius={10}
    />
  );
};
// component 4 displays
export const BarChartDisplayTti = ({ maleCount, femaleCount }) => {
  // console.log(maleCount, femaleCount);
  if (maleCount === 0 && femaleCount === 0) {
    return <div>Nothing to display</div>;
  }
  return (
    <BarChart
      series={[
        {
          data: [maleCount],
          color: "#CFF0B5",
          label: "Male ",
          barWidth: 0.2,
        },
        {
          data: [femaleCount],
          color: "#BC5645",
          label: "Female ",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={250}
      xAxis={[{ data: ["Gender"], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
      barLabel="value"
      borderRadius={10}
    />
  );
};
export const BarChartDisplayTtiAndSacs = ({
  stateCount,
  federalCount,
  skillAcquisitionCenterCount,
}) => {
  if (
    stateCount === 0 &&
    federalCount === 0 &&
    skillAcquisitionCenterCount === 0
  ) {
    return (
      <div>
        Nothing to display
        {/* <Link to="/beneficiaries-data">
          <Button sx={{ textTransform: "capitalize" }}>here</Button>
        </Link> */}
      </div>
    );
  }
  return (
    <BarChart
      series={[
        {
          data: [stateCount],
          color: "#CFF0B5",
          label: "State ",
          barWidth: 0.2,
        },
        {
          data: [federalCount],
          color: "#BC5645",
          label: "Federal ",
          barWidth: 0.2,
        },
        {
          data: [skillAcquisitionCenterCount],
          color: "#45BC5F",
          label: "Skill Aquisition Centers ",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={250}
      xAxis={[{ data: ["Jurisdiction"], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
      barLabel="value"
      borderRadius={10}
    />
  );
};

export const BarChartDisplayComponent4 = ({ maleCount, femaleCount }) => {
  if (maleCount === 0 && femaleCount === 0) {
    return <div>Nothing to display</div>;
  }
  return (
    <BarChart
      series={[
        {
          data: [maleCount],
          color: "#CFF0B5",
          label: "Male ",
          barWidth: 0.2,
        },
        {
          data: [femaleCount],
          color: "#BC5645",
          label: "Female ",
          barWidth: 0.2,
        },
      ]}
      height={300}
      width={250}
      xAxis={[{ data: ["Gender"], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 60, right: 10 }}
      barLabel="value"
      borderRadius={10}
    />
  );
};
