import EastIcon from "@mui/icons-material/East";
import { useDispatch, useSelector } from "react-redux";
import { useQueries, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { toSentenceCase } from "../../../../utils/toSentenceCase";
import ShowError from "../../../../utils/showError";
import {
  fetchAllTechnicalCollegePosts,
  fetchTechnicalCollegePostById,
} from "../../../../utils/apiContext";
import Loader from "../../../../Components/Loader";
import React, { useEffect } from "react";
import { setTcPost } from "../../../../features/auth/isPostMade";

export const TechnicalCollegeDataDisplay = ({ onOpenModal, data }) => {
  const userRoles = useSelector(selectCurrentUserRole);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);

  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;
  const dispatch = useDispatch();

  const allowedRoles = [
    "Super_Admin",
    "National_Admin",
    "Reviewer",
    "Validator",
    "M_E_OFFICE",
  ];
  const roleMessage =
    allowedRoles.includes(userRoles) === ""
      ? ""
      : userRoles === "Reviewer"
      ? "Total no of Technical colleges"
      : userRoles === "Validator"
      ? "Total no of Technical colleges"
      : userRoles === "M_E_OFFICE"
      ? "Technical Colleges"
      : "";

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tcData"],
    queryFn: () => fetchAllTechnicalCollegePosts(jurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey: ["post", post._id],
        queryFn: () => fetchTechnicalCollegePostById(post._id, jurisdiction),
      })) || [],
  });

  const postsWithDetails = postQueries.map((query) => query.data);

  useEffect(() => {
    const isAvailable = postsWithDetails.some(
      (post) => post?.institution_name === tc_name
    );
    if (isAvailable) {
      dispatch(setTcPost(true));
    } else {
      dispatch(setTcPost(false));
    }
  }, [dispatch, postsWithDetails, tc_name]);

  const filteredPosts = postsWithDetails.filter(
    (post) => post?.institution_name === tc_name
  );

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <ShowError />;
  }

  return (
    <div className="px-6 py-6">
      <h1
        className="font-bold text-[20px] text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        {roleMessage}
      </h1>

      <br />
      <br />
      <div className="relative">
        {filteredPosts.map((post) => {
          const tcNameToSentenceCase = post?.institution_name;
          return (
            <div
              key={post?._id}
              className={`${userRoles === "M_E_OFFICE" ? "block" : "hidden"} `}
            >
              <div
                className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
              >
                <div className="w-[30%]">
                  <h1
                    className="font-bold"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Institution Name:
                  </h1>
                </div>
                <div className="w-[65%] flex gap-1 items-center">
                  <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                    {toSentenceCase(tcNameToSentenceCase)}
                  </h1>
                </div>
              </div>
              <div
                className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
              >
                <div className="w-[30%]">
                  <h1
                    className="font-bold"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Institution Address:
                  </h1>
                </div>
                <div className="w-[65%] flex gap-1 items-center">
                  <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                    {post?.institution_address}
                  </h1>
                </div>
              </div>
              <div
                className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
              >
                <div className="w-[30%]">
                  <h1
                    className="font-bold"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Institution Type:
                  </h1>
                </div>
                <div className="w-[65%] flex gap-1 items-center">
                  <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                    {post?.institution_type}
                  </h1>
                </div>
              </div>
              <div
                className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
              >
                <div className="w-[30%]">
                  <h1
                    className="font-bold"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Year of Establishment:
                  </h1>
                </div>
                <div className="w-[65%] flex gap-1 items-center">
                  <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                    {post?.institution_year_est}
                  </h1>
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex items-center">
          {userRoles === "Validator" && (
            <h1 className="text-active_bg font-bold text-4xl mt-2">3</h1>
          )}
          {userRoles === "Reviewer" && (
            <h1 className="text-active_bg font-bold text-4xl mt-2">18</h1>
          )}
          <div
            className={`${
              userRoles === "Validator" || userRoles === "Reviewer"
                ? "flex"
                : "hidden"
            } items-center gap-3 absolute right-6 bottom-5 text-active_bg cursor-pointer `}
            onClick={onOpenModal}
          >
            <EastIcon color="#0E4F2F" className="" />
            <span className="">View Technical Colleges</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ValidatorTechnicalCollegeDataDisplay = ({ data }) => {
  const userRoles = useSelector(selectCurrentUserRole);

  const allowedRoles = [
    "Super_Admin",
    "National_Admin",
    "Reviewer",
    "Validator",
    "M_E_OFFICE",
  ];
  const roleMessage =
    allowedRoles.includes(userRoles) === ""
      ? ""
      : userRoles === "Reviewer"
      ? "Technical College Data"
      : userRoles === "Validator"
      ? "Technical College Data"
      : userRoles === "M_E_OFFICE"
      ? "Technical College "
      : "";

  return (
    <div className="px-6 py-6">
      <h1
        className="font-bold text-[20px] text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        {roleMessage}
      </h1>

      <br />
      <br />
      <div className="relative">
        <div
          key={data?._id}
          className={`${userRoles === "Reviewer" ? "block" : "hidden"} `}
        >
          <div
            className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
          >
            <div className="w-[30%]">
              <h1
                className="font-bold"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Institution Name:
              </h1>
            </div>
            <div className="w-[65%] flex gap-1 items-center">
              <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                {toSentenceCase(data?.institution_name)}
              </h1>
            </div>
          </div>
          <div
            className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
          >
            <div className="w-[30%]">
              <h1
                className="font-bold"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Institution Address:
              </h1>
            </div>
            <div className="w-[65%] flex gap-1 items-center">
              <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                {data?.institution_address}
              </h1>
            </div>
          </div>
          <div
            className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
          >
            <div className="w-[30%]">
              <h1
                className="font-bold"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Institution Type:
              </h1>
            </div>
            <div className="w-[65%] flex gap-1 items-center">
              <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                {data?.institution_type}
              </h1>
            </div>
          </div>
          <div
            className={`flex items-center justify-between border-b border-b-nav_box mb-4 py-2`}
          >
            <div className="w-[30%]">
              <h1
                className="font-bold"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Year of Establishment:
              </h1>
            </div>
            <div className="w-[65%] flex gap-1 items-center">
              <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
                {data?.institution_year_est}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ValidatorTechnicalCollegeDataDisplayList = ({ onOpenModal }) => {
  const userRoles = useSelector(selectCurrentUserRole);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);

  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;
  const isUser1 = tc_name;
  const allowedRoles = [
    "Super_Admin",
    "National_Admin",
    "Reviewer",
    "Validator",
    "M_E_OFFICE",
  ];
  const roleMessage =
    allowedRoles.includes(userRoles) === ""
      ? ""
      : userRoles === "Reviewer"
      ? "Total no of Technical colleges"
      : userRoles === "Validator"
      ? "Total no of Technical colleges"
      : userRoles === "M_E_OFFICE"
      ? "Technical Colleges"
      : "";

  return (
    <div className="px-6 py-6">
      <h1
        className="font-bold text-[20px] text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        {roleMessage}
      </h1>

      <br />
      <br />
      <div className="relative">
        <div className="flex items-center">
          {userRoles === "Validator" && (
            <h1 className="text-active_bg font-bold text-4xl mt-2">3</h1>
          )}
          {userRoles === "Reviewer" && (
            <h1 className="text-active_bg font-bold text-4xl mt-2">18</h1>
          )}
          <div
            className={`${
              userRoles === "Validator" || userRoles === "Reviewer"
                ? "flex items-center"
                : "hidden"
            } items-center gap-3 absolute right-6 bottom-5 text-active_bg cursor-pointer `}
            onClick={onOpenModal}
          >
            <EastIcon color="#0E4F2F" className="" />
            <span className="">View Technical Colleges</span>
          </div>
        </div>
      </div>
    </div>
  );
};
