import VisibilityIcon from "@mui/icons-material/Visibility";

export const PdoPreview2 = [
  {
    id: 1,
    question:
      "TVET sensitization conducted in the host community by the school",
    responseKey: "tvet_sensitization_consucted_in_host_community_by_school",
  },
  {
    id: 2,
    question: "Number of toilet facilities in the school",
    responseKey: "no_of_toilet_facilities_in_school",
  },
  {
    id: 3,
    question: "Number of toilet facilities renovated by IDEAS project",
    responseKey: "no_of_toilet_facilities_renovated_by_ideas_project",
  },
  {
    id: 4,
    question:
      "Number of toilet facilities provided with WASH facilities by IDEAS project",
    responseKey:
      "no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project",
  },
  {
    id: 5,
    question: "Number of female students enrolled in Priority Trades",
    responseKey: "no_of_female_students_enrolled_in_priority_trade",
  },
  {
    id: 6,
    question:
      "Total number of students (both male and female) enrolled in Priority Trades",
    responseKey: "total_no_of_students_enrolled_in_priority_trades",
  },
  {
    id: 7,
    question:
      "Upload TC report showing the Sensitization Activity including pictures all in one PDF",
    responseKey: "tvet_sensitization_report_pdf",
  },
  {
    id: 8,
    question:
      "Fill and upload the template on Student Enrollment data in one pdf",
    responseKey: "student_enrollment_data_doc_pdf",
  },
];

export const PdoPreview3 = [
  {
    id: 1,
    question: "Number of Male graduates in IDEAS Supported Programs in TCs",
    responseKey: "male.graduates",
  },
  {
    id: 2,
    question: "Number of Female graduates in IDEAS Supported Programs in TCs",
    responseKey: "female.graduates",
  },
  {
    id: 3,
    question:
      "Total number of Male students enrolled in IDEAS Supported Programs in TCs",
    responseKey: "male.enrolled",
  },
  {
    id: 4,
    question:
      "Total number of Female students enrolled in IDEAS Supported Programs in TCs",
    responseKey: "female.enrolled",
  },
  {
    id: 5,
    question:
      "Number of Male Labour market workers benefiting from Short-term skills upgrading programs in TCs",
    responseKey: "male.labour_market_workers",
  },
  {
    id: 6,
    question:
      "Number of Female Labour market workers benefiting from Short-term skills upgrading programs in TCs",
    responseKey: "female.labour_market_workers",
  },
  {
    id: 7,
    question:
      "Number of Male Unemployed Youths benefiting from Short-term skills upgrading programs in TCs",
    responseKey: "male.unemployed",
  },
  {
    id: 8,
    question:
      "Number of Female Unemployed Youths benefiting from Short-term skills upgrading programs in TCs",
    responseKey: "female.unemployed",
  },
];
