import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tcDataHeader } from "./tc_data";
import { v4 as uuidv4 } from "uuid";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { getTcName } from "../../../../utils/getFormattedToken";
import { toSentenceCase } from "../../../../utils/toSentenceCase";
import ShowLoader from "../../../../utils/showLoader";
import ShowError from "../../../../utils/showError";
import MoreOptionsMenu from "../../../../Components/MoreOptionsIcon";
import { useState } from "react";
import EditModalForm from "../../../../Components/Modal_Forms/EditModal";
import ConfirmationModal from "../../../../Components/Modal_Forms/ConfirmDelete";
import {
  fetchAllTechnicalCollegePosts,
  fetchTechnicalCollegePostById,
} from "../../../../utils/apiContext";
import { setTcPost } from "../../../../features/auth/isPostMade";

const fields = [
  { id: "institution_code", label: "Institution Code", type: "text" },
  { id: "institution_state", label: "Institution State", type: "text" },
  { id: "institution_name", label: "Institution Name", type: "text" },
  { id: "institution_type", label: "Institution Type" },
  {
    id: "institution_cat",
    label: "Institution Category",
    type: "dropdown",
    options: [
      { value: "Technical", label: "Technical" },
      { value: "Conventional", label: "Conventional" },
      {
        value: "Science and Technical",
        label: "Science and Technical",
      },
    ],
  },
  {
    id: "institution_ownership",
    label: "Institution Ownership",
    type: "dropdown",
    options: [
      { value: "Public", label: "Public" },
      { value: "Private", label: "Private" },
    ],
  },
  { id: "institution_year_est", label: "Year Established", type: "number" },
  { id: "institution_address", label: "Institution Address" },
  { id: "institution_phone", label: "Institution Phone" },
  { id: "institution_email", label: "Institution Email" },
  { id: "admin_office_number", label: "Admin Office Number" },
  { id: "male_hostel_number", label: "Male Hostel Number" },
  { id: "female_hostel_number", label: "Female Hostel Number" },
  { id: "canteens_num", label: "Canteens Number", type: "number" },
  { id: "sponsor", label: "Sponsor" },
  { id: "bus_num", label: "Bus Number", type: "number" },
  { id: "gen_num", label: "Generator Number", type: "number" },
  { id: "power_type", label: "Power Type" },
  { id: "power_capacity", label: "Power Capacity", type: "number" },
  { id: "agricultural_fields", label: "Agricultural Fields" },
];

export default function TcDataTable() {
  const userTkn = useSelector(selectCurrentToken);
  const userRoles = useSelector(selectCurrentUser);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const tc_name = getTcName(userTkn);
  const queryClient = useQueryClient();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const dispatch = useDispatch();

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tcData"],
    queryFn: () => fetchAllTechnicalCollegePosts(jurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey: ["post", post._id],
        queryFn: () => fetchTechnicalCollegePostById(post._id, jurisdiction),
      })) || [],
  });

  const postsWithDetails = postQueries.map((query) => query.data);

  const onEdit = (rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  };

  const handleEditAndSave = async (updatedData) => {
    try {
      const updatedDataWithJurisdiction = { ...updatedData, jurisdiction };
      await axiosInstance.put(
        `/tc/c1/update-post-tc/${updatedData._id}`,
        updatedDataWithJurisdiction
      );
      queryClient.invalidateQueries(["tcData"]);
    } catch (error) {
      console.error("Failed to save the updated data:", error);
    }
  };

  const onDelete = (rowId) => {
    setRowToDelete(rowId);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(
        `/tc/c1/delete-post-tc/${rowToDelete}/?jurisdiction=${jurisdiction}`
      );
      setIsConfirmationModalOpen(false);
      queryClient.invalidateQueries(["tcData"]);
      const remainingPosts = postsWithDetails.filter(
        (post) => post._id !== rowToDelete
      );
      const matchingPost = remainingPosts.find(
        (post) => post?.institution_name === tc_name
      );
      if (!matchingPost) {
        dispatch(setTcPost(false));
      }
    } catch (error) {
      console.error("Failed to delete the data:", error);
    }
  };

  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  const filteredPosts = postsWithDetails.filter(
    (post) => post?.institution_name === tc_name
  );

  if (filteredPosts.length === 0) {
    return <ShowError />;
  }

  return (
    <>
      <TableContainer
        component={Paper}
        className="scrollbar scrollbar-w-2 scrollbar-h-1.5 scrollbar-thumb-rounded-[50px] scrollbar-track-gray-300 scrollbar-track-rounded-[50px] scrollbar-thumb-[#A5A2A2] overflow-x-scroll h-[20vh]"
      >
        <Table aria-label="simple table" sx={{ backgroundColor: "#F2F2F2" }}>
          <TableHead sx={{ backgroundColor: "#FFFFFF" }}>
            <TableRow>
              {userRoles !== "Validator" && (
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                >
                  Options
                </TableCell>
              )}
              {tcDataHeader.map((item) => (
                <TableCell
                  key={item.id}
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                  align="center"
                >
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="border-2 mt-2">
            {filteredPosts.map((row) => {
              let capTcName = toSentenceCase(row.institution_name);
                
              return (
                <TableRow
                  key={row._id}
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                  }}
                >
                  {userRoles !== "Validator" && (
                    <TableCell align="center">
                      <MoreOptionsMenu
                        rowId={row.id}
                        onEdit={() => onEdit(row)}
                        onDelete={() => onDelete(row._id)}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center">{row.institution_code}</TableCell>
                  {/* <TableCell align="center">{row.institution_state}</TableCell> */}
                  <TableCell align="center">{capTcName}</TableCell>
                  <TableCell align="center">{row.institution_type}</TableCell>
                  <TableCell align="center">{row.institution_cat}</TableCell>
                  <TableCell align="center">
                    {row.institution_ownership}
                  </TableCell>
                  <TableCell align="center">
                    {row.institution_year_est}
                  </TableCell>
                  <TableCell align="center">
                    {row.institution_address}
                  </TableCell>
                  <TableCell align="center">{row.institution_phone}</TableCell>
                  <TableCell align="center">{row.institution_email}</TableCell>
                  <TableCell align="center">{row.male_hostel_number}</TableCell>
                  <TableCell align="center">
                    {row.female_hostel_number}
                  </TableCell>
                  <TableCell align="center">{row.sponsor}</TableCell>
                  <TableCell align="center">{row.bus_num}</TableCell>
                  <TableCell align="center">{row.gen_num}</TableCell>
                  <TableCell align="center">{row.power_type}</TableCell>
                  <TableCell align="center">{row.power_capacity}</TableCell>
                  <TableCell align="center">
                    {row.agricultural_fields}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <EditModalForm
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        rowData={selectedRowData}
        handleSave={handleEditAndSave}
        fields={fields}
        header="Edit Technical College Data"
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this item?"
      />
    </>
  );
}
