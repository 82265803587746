import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import axiosInstance from "../../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../../features/auth/authSlice";
import { getTcName } from "../../../../../utils/getFormattedToken";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import ShowLoader from "../../../../../utils/showLoader";
import ShowError from "../../../../../utils/showError";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import RejectDataEntry from "../../../../../Components/Modal_Forms/RejectMessage/Reject";
import ViewPdf from "../../../../../Components/PdfModalView/ViewPdf";
import { IndicatorEditModalFormIoi1 } from "../../../../../Components/Modal_Forms/IndicatorEditModal";
import {
  fetchAllIOI1Posts,
  fetchIOI1PostById,
} from "../../../../../utils/apiContext";
import { toast } from "sonner";
import { setIoi1Post } from "../../../../../features/auth/isPostMade";

const fields = [
  {
    id: "sch_ideas_project_team_established_value",
    label: "CIU Established",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "sch_ideas_project_team_established_report_pdf",
    label: "Upload the IDEAS Project CIU report(file max size 5MB)",
    type: "file",
  },
  {
    id: "no_of_mous_signed_with_industry_partners_value",
    label: "Number of MoUs",
    type: "number",
  },
  {
    id: "no_of_mous_signed_with_industry_partners_all_signed_mous_pdf",
    label: "Upload all SIGNED MOUs in one PDF Document(file max size 5MB)",
    type: "file",
  },
  {
    id: "no_of_times_ciu_met_over_past_year_value",
    label: "CIU Meetings",
    type: "select",
    options: [
      { value: "once", label: "Once" },
      { value: "twice", label: "Twice" },
      { value: "thrice", label: "Thrice" },
      { value: "four times", label: "Four Times" },
      { value: "none", label: "None" },
    ],
  },
  {
    id: "no_of_times_ciu_met_over_past_year_minutes_pdf",
    label:
      "Upload all CIU minutes of meeting in one PDF Document(file max size 5MB)",
    type: "file",
  },
];

const Ioi1Display = ({ questions, propsTc_name, propsJurisdiction }) => {
  const [openRejectModal1, setOpenRejectModal1] = useState(false);
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [postIsLoading, setPostIsLoading] = useState(false);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const dispatch = useDispatch();

  const tc_name = getTcName(userTkn);
  const userRoles = useSelector(selectCurrentUserRole);
  const queryClient = useQueryClient();
  const isUser1 = userRoles === "M_E_OFFICE";
  const combJurisdiction =
    userRoles !== "National_Admin" ? jurisdiction : propsJurisdiction;
  const handleReject1 = () => {
    setOpenRejectModal1(true);
  };

  const handleCloseRejectModal1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const handleRejectReasonChange1 = (event) => {
    setRejectionReason1(event.target.value);
  };

  const handleSubmit1 = () => {
    // Handle submit action here
    // console.log("Rejection Reason:", rejectionReason1);
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const closeForm = () => {
    setOpenPdfModal(false);
  };

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: combJurisdiction === "state" ? ["ioi1"] : ["ioi1b"],
    queryFn: () => fetchAllIOI1Posts(combJurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey:
          combJurisdiction === "state"
            ? ["ioi1", post._id]
            : ["ioi1b", post._id],
        queryFn: () => fetchIOI1PostById(post?._id, combJurisdiction),
      })) || [],
  });

  const openEditModal = (data) => {
    setEditValues({
      sch_ideas_project_team_established_value:
        data.sch_ideas_project_team_established.value,
      sch_ideas_project_team_established_report_pdf:
        data.sch_ideas_project_team_established.report_pdf,
      no_of_mous_signed_with_industry_partners_value:
        data.no_of_mous_signed_with_industry_partners.value,
      no_of_mous_signed_with_industry_partners_all_signed_mous_pdf:
        data.no_of_mous_signed_with_industry_partners.all_signed_mous_pdf,
      no_of_times_ciu_met_over_past_year_value:
        data.no_of_times_ciu_met_over_past_year.value,
      no_of_times_ciu_met_over_past_year_minutes_pdf:
        data?.no_of_times_ciu_met_over_past_year?.minutes_pdf,
      _id: data?._id,
    });

    setModalOpen(true);
  };

  const saveEdits = async (formData) => {
    try {
      setPostIsLoading(true);
      await axiosInstance.put(
        `/ioi/c1/update-post-for-no-of-supported-tc/${formData.get(
          "_id"
        )}/?jurisdiction=${jurisdiction}`,
        formData
      );
      queryClient.invalidateQueries(["ioipost1"]);
      setModalOpen(false);
      setPostIsLoading(false);
      // console.log(formData);
    } catch (error) {
      toast.error("Error saving edits:", error);
      setPostIsLoading(false);
    }
  };

  const postsWithDetails = postQueries.map((query) => query.data);

  // useEffect(() => {
  //   const isAvailable = postsWithDetails.some(
  //     (post) => post?.tc_name === tc_name
  //   );
  //   if (isAvailable) {
  //     dispatch(setIoi1Post(true));
  //   } else {
  //     dispatch(setIoi1Post(false));
  //   }
  // }, [dispatch, postsWithDetails, tc_name]);

  const filteredPosts = postsWithDetails.filter((post) => {
    if (isUser1) {
      // console.log("fetching using user1", post?.tc_name, propsTc_name);
      return post?.tc_name === tc_name;
    } else {
      // console.log("fetching user reviewer", post?.tc_name, propsTc_name);
      return post?.tc_name === propsTc_name;
    }
  });

  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  return questions.map((list) => {
    const responseValue = list.responseKey
      .split(".")
      .reduce((o, i) => o?.[i], filteredPosts[0]);
    return (
      <>
        <div
          key={list?.id}
          className="border-b-2 border-b-indicator_border ml-6 text-sm py-2"
        >
          <div className="w-[80%] flex justify-between items-center">
            <div>{list.question}</div>
            <div className="text-active_bg font-bold">
              {list.responseKey.includes("pdf") ? (
                <FileDownloadDoneIcon />
              ) : (
                responseValue
              )}
            </div>
          </div>
          {userRoles === "Reviewer" && (
            <div className="text-active_bg rounded-md text-sm absolute bottom-0 right-6 flex gap-3">
              <Button
                onClick={handleReject1}
                variant="outlined"
                color="error"
                sx={{
                  bgcolor: "#F44336",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": { bgcolor: "#F44336" },
                }}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{
                  bgcolor: "#0E4F2F",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
              >
                Approve
              </Button>
              <Button
                onClick={() => setOpenPdfModal(true)}
                variant="outline"
                sx={{ textTransform: "capitalize" }}
              >
                View Documents
              </Button>
            </div>
          )}{" "}
          {userRoles === "Validator" && (
            <div className="text-active_bg rounded-md text-sm absolute bottom-6 right-6 flex gap-3">
              <Button
                onClick={handleReject1}
                variant="outlined"
                color="error"
                sx={{
                  bgcolor: "#F44336",
                  color: "white",
                  textTransform: "capitalize",
                  "&:hover": { bgcolor: "#F44336" },
                }}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{
                  bgcolor: "#0E4F2F",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
              >
                Validate
              </Button>
              <Button
                onClick={() => setOpenPdfModal(true)}
                variant="outline"
                sx={{ textTransform: "capitalize" }}
              >
                View Documents
              </Button>
            </div>
          )}{" "}
          {userRoles === "M_E_OFFICE" && (
            <div className="flex gap-3 absolute bottom-6 right-6">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#0E4F2F",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  "&:hover": { bgcolor: "#0E4F2F" },
                }}
                onClick={() => openEditModal(filteredPosts[0])}
              >
                Edit
              </Button>
              <Button
                onClick={() => setOpenPdfModal(true)}
                variant="outline"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": { bgcolor: "#0E4F2F", color: "#ffffff" },
                }}
              >
                View Documents
              </Button>
            </div>
          )}
        </div>
        <RejectDataEntry
          openRejectModal1={openRejectModal1}
          handleCloseRejectModal1={handleCloseRejectModal1}
          rejectionReason1={rejectionReason1}
          handleRejectReasonChange1={handleRejectReasonChange1}
          handleSubmit1={handleSubmit1}
        />
        {openPdfModal && <ViewPdf closeForm={closeForm} />}
        <IndicatorEditModalFormIoi1
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          rowData={editValues}
          handleSave={saveEdits}
          fields={fields}
          header="Edit Industry Partnership"
          isLoading={postIsLoading}
          setIsLoading={setPostIsLoading}
        />
      </>
    );
  });
};

export default Ioi1Display;
