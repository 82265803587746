import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SideNav from "./Layout/SideNav";
import BeneficiariesData from "./Pages/Component1/Beneficiaries/Beneficiaries_data";
// import CrossCuttingIssues from "./Pages/Component1/Component1/Cross_Cutting_Issues/Cross_Cutting_Issues";
import CrossCuttingIssues from "./Pages/Component1/Cross_Cutting_Issues/Cross_Cutting_Issues";
// import GIS from "./Pages/Component1/GIS/Gis";
import GIS from "./Pages/Component1/GIS/Gis";

import AnalyticsDashboard from "./Pages/Component1/Analytics_Dashboard/Analytics_Dashboard";
import MandEDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/User1";
import ValidatorDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/Validator";
import ReviewerDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/Reviewer";
import NationalAdminDashboard from "./Pages/Component1/Analytics_user3/NationalAdmin";
import NPCUReviewerDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/NPCUReviewer";
import NPMUReviewerDashboard from "./Pages/Component1/Analytics_Dashboard/dashboard_views/NPMUReviewer";
import LoginPage from "./Pages/Component1/Login";
import Track from "./Pages/Component1/Track/Track";
import Validate from "./Pages/Component1/Validate/validate";
import Overview from "./Pages/Component1/Overview/Overview";
import Review from "./Pages/Component1/Review/Review";
import ProtectedRoute from "./Pages/Component1/ProtectedRoute";
import RequireAuth from "./Pages/Component1/RequireAuth";
import Unauthorized from "./Pages/Component1/Unauthorized";
import AnalyticsSchools from "./Pages/Component1/AnalyticsSchoolPage";
import Layout from "./Pages/Component1/Layout";
import { useEffect } from "react";
import AuthCheckWrapper from "./Pages/Component1/AuthCheck";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./features/auth/authSlice";
import CreateUsers from "./Pages/Component1/SuperAdmin_CreateUsers/CreateUsers";

const router = createBrowserRouter(
  [
    {
      path: "/auth",
      element: <LoginPage />,
    },
    {
      path: "/",
      element: <RequireAuth />, // Ensure user is authenticated
      children: [
        {
          element: <SideNav />,
          children: [
            {
              path: "m_e_officer/analytics/:id",
              element: (
                <ProtectedRoute
                  element={<MandEDashboard />}
                  roles={[
                    "Super_Admin",
                    "National_Admin",
                    "Reviewer",
                    "Validator",
                    "M_E_OFFICE",
                  ]}
                />
              ),
            },
            {
              path: "validator/analytics",
              element: (
                <ProtectedRoute
                  element={<ValidatorDashboard />}
                  roles={["Validator"]}
                />
              ),
            },
            {
              path: "reviewer/analytics",
              element: (
                <ProtectedRoute
                  element={<ReviewerDashboard />}
                  roles={["Reviewer"]}
                />
              ),
            },
            {
              path: "national_admin/analytics",
              element: (
                <ProtectedRoute
                  element={<NationalAdminDashboard />}
                  roles={["National_Admin"]}
                />
              ),
            },
            {
              path: "super_admin/analytics",
              element: (
                <ProtectedRoute
                  element={<NationalAdminDashboard />}
                  roles={["Super_Admin"]}
                />
              ),
            },
            {
              path: "beneficiaries-data",
              element: (
                <ProtectedRoute
                  element={<BeneficiariesData />}
                  roles={["M_E_OFFICE"]}
                />
              ),
            },
            {
              path: "validate",
              element: (
                <ProtectedRoute element={<Validate />} roles={["Validator"]} />
              ),
            },
            {
              path: "review",
              element: (
                <ProtectedRoute element={<Review />} roles={["Reviewer"]} />
              ),
            },
            {
              path: "overview",
              element: (
                <ProtectedRoute
                  element={<Overview />}
                  roles={["Super_Admin", "National_Admin"]}
                />
              ),
            },
            {
              path: "gis",
              element: (
                <ProtectedRoute
                  element={<GIS />}
                  roles={[
                    "Super_Admin",
                    "National_Admin",
                    "Reviewer",
                    "Validator",
                  ]}
                />
              ),
            },
            {
              path: "tracking",
              element: (
                <ProtectedRoute
                  element={<Track />}
                  roles={[
                    "Super_Admin",
                    "National_Admin",
                    "Reviewer",
                    "Validator",
                    "M_E_OFFICE",
                  ]}
                />
              ),
            },
            {
              path: "cross-cutting-issues",
              element: (
                <ProtectedRoute
                  element={<CrossCuttingIssues />}
                  roles={[
                    "Super_Admin",
                    "National_Admin",
                    "Reviewer",
                    "Validator",
                    "M_E_OFFICE",
                  ]}
                />
              ),
            },
            {
              path: "analytics-schools/:id", // Add this route
              element: (
                <ProtectedRoute
                  element={<AnalyticsSchools />}
                  roles={[
                    "Super_Admin",
                    "National_Admin",
                    "Reviewer",
                    "Validator",
                    "M_E_OFFICE",
                  ]}
                />
              ),
            },
            {
              path: "super_admin/create-users",
              element: (
                <ProtectedRoute
                  element={<CreateUsers />}
                  roles={["Super_Admin"]}
                />
              ),
            },
          ],
        },
      ],
    },
  ],

  {
    path: "/unauthorized",
    element: <Unauthorized />,
  }
  // {
  //   path: "/auth-check",
  //   element: <AuthCheckWrapper />,
  // },
);

function App() {
  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === "number") {
        event.preventDefault();
      }
    };

    const handleRouteChange = () => {
      if (token) {
        localStorage.setItem("lastRoute", window.location.pathname);
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("beforeunload", handleRouteChange);

    return () => {
      document.removeEventListener("wheel", handleWheel);
      window.removeEventListener("beforeunload", handleRouteChange);
    };
  }, [token]);
  return <RouterProvider router={router} />;
}

export default App;
