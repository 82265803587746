import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../Services/axiosInstance";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../features/auth/authSlice";
import { getTcName } from "../../utils/getFormattedToken";
import FormRadioInputBool, { FormDropdown } from "../form";

const options = [
  { value: "Construction", label: "Construction" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "ICT", label: "ICT" },
  { value: "Agricultural Science", label: "Agricultural Science" },
  { value: "Garment Making", label: "Garment Making" },
];

const ModalTeacherForm = ({ open, handleClose }) => {
  const modalRef = useRef(null);
  const queryClient = useQueryClient();
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const [isLoading, setIsLoading] = useState(false);

  const tc_name = getTcName(userTkn);

  const [schoolStateLocation, setSchoolStateLocation] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [nsqfAssesor, setNsqfAssesor] = useState("");
  const [nsqfVerifier, setNsqfVerifier] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [designationCollege, setDesignationCollege] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [experience, setExperience] = useState();
  const [retirementDate, setRetirementDate] = useState("");
  const [teachingLevel, setTeachingLevel] = useState("");
  const [engagement, setEngagement] = useState("");
  const [eduCompleted, setEduCompleted] = useState("");
  const [teacherTraining, setTeacherTraining] = useState("");
  const [proCertificate, setProCertificate] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [tradeArea, setTradeArea] = useState("");
  const [subjectsTaught, setSubjectsTaught] = useState("");
  const [ictProficiency, setIctProficiency] = useState("");
  const [participationTti, setParticipationTti] = useState("");
  const [specialNeeds, setSpecialNeeds] = useState("");
  const [digitalLiteracyTraining, setDigitalLiteracyTraining] = useState("");
  const [pedagogyTraining, setPedagogyTraining] = useState("");
  const [errors, setErrors] = useState({});
  const mutation = useMutation({
    mutationFn: async (formData) => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/teachers/c1/create-teacher",
        formData
      );
    },
    onSuccess: (response) => {
      toast.success("Successful");
      queryClient.invalidateQueries(["teachersData"]);
      setIsLoading(false);
      handleClose();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error(err.response.message);
        setIsLoading(false);
        handleClose();
      } else {
        toast.error(err.response.message);
        setIsLoading(false);
        handleClose();
      }
    },
  });

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
    setErrors({ ...errors, [e.target.name]: "" });
  };
  const handleSelectChange = (event) => {
    setGender(event.target.value);
    setErrors({ ...errors, sex: "" });
  };
  const handleServiceTypeSelectChange = (event) => {
    setServiceType(event.target.value);
    setErrors({ ...errors, serviceType: "" });
  };
  const handleSelectChange2 = (event) => {
    setIctProficiency(event.target.value);
  };
  const handleSelectChange3 = (event) => {
    setParticipationTti(event.target.value);
  };
  const handleNsqfAssesorChange = (event) => {
    setNsqfAssesor(event.target.value === "true");
    setErrors({ ...errors, nsqfAssesor: "" });
  };
  const handleNsqfVerifierChange = (event) => {
    setNsqfVerifier(event.target.value === "true");
    setErrors({ ...errors, nsqfVerifier: "" });
  };

  const handleDigitalLiteracyTrainingChange = (event) => {
    setDigitalLiteracyTraining(event.target.value === "true");
    setErrors({ ...errors, digitalLiteracyTraining: "" });
  };

  const handlePedagogyTrainingChange = (event) => {
    setPedagogyTraining(event.target.value === "true");
    setErrors({ ...errors, pedagogyTraining: "" });
  };

  const handleSpecialNeedsChange = (event) => {
    setSpecialNeeds(event.target.value);
  };

  const formatDateToString = (date) => {
    const dateObj = new Date(date);
    return dateObj.toISOString().split("T")[0];
  };

  const validate = () => {
    const newErrors = {};
    const requiredFields = {
      schoolStateLocation,
      name,
      gender,
      phoneNumber,
      email,
      dob,
      appointmentDate,
      retirementDate,
      specialization,
      serviceType,
      digitalLiteracyTraining,
      pedagogyTraining,
    };

    Object.keys(requiredFields).forEach((field) => {
      if (
        requiredFields[field] === "" ||
        requiredFields[field] === undefined ||
        requiredFields[field] === null
      ) {
        newErrors[field] = "This field is required";
      }
    });

    if (nsqfAssesor === null) {
      newErrors.nsqfAssesor = "This field is required";
    }

    if (nsqfVerifier === null) {
      newErrors.nsqfVerifier = "This field is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      toast.error("Required Fields can't be empty");
      return;
    }
    const formattedDob = formatDateToString(dob);
    const formattedAppointmentDate = formatDateToString(appointmentDate);
    const formattedRetirementDate = formatDateToString(retirementDate);

    if (
      !formattedDob ||
      !formattedAppointmentDate ||
      !formattedRetirementDate
    ) {
      toast.error(
        "Please enter valid dates for Date of Birth, Appointment Date, and Retirement Date."
      );
      return;
    }

    const newObj = {
      jurisdiction,
      school_state_location: schoolStateLocation,
      name,
      service_type: serviceType,
      gender,
      certified_as_NSQF_assesors: nsqfAssesor,
      certified_as_NSQF_verifiers: nsqfVerifier,
      phone_number: phoneNumber,
      email,
      date_of_birth: formattedDob,
      age,
      designation_in_the_college: designationCollege,
      date_of_appointment: formattedAppointmentDate,
      years_of_experience: experience,
      possible_date_of_retirement: formattedRetirementDate,
      teaching_level: teachingLevel,
      nature_of_engagement: engagement,
      highest_level_of_education_completed: eduCompleted,
      highest_level_ofteacher_training: teacherTraining,
      professional_certificate: proCertificate,
      area_of_specialization: specialization,
      trade_area_taught_in_the_college: tradeArea,
      subjects_taught_in_the_college: subjectsTaught,
      ict_proficiency: ictProficiency,
      did_you_participate_in_the_ideas_tti_training: participationTti,
      tc_name,
      special_need: specialNeeds,
      occupational_training: {
        digital_literacy_training: digitalLiteracyTraining,
        pedagogy_training: pedagogyTraining,
        trade_area: specialization,
      },
    };
    console.log("pedagogyTraining", pedagogyTraining);
    console.log("digitalLiteracyTraining", digitalLiteracyTraining);

    mutation.mutate(newObj);
    // handleClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full z-20 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between font-bold">
          <h1 className="text-2xl">Add Teacher</h1>

          <CloseIcon
            onClick={handleClose}
            style={{ fontSize: "2rem" }}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>
        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              School's State Location <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="school_state_location"
              value={schoolStateLocation.toLowerCase()}
              onChange={(e) => handleInputChange(e, setSchoolStateLocation)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.schoolStateLocation
                  ? "border-red-500"
                  : "border-gray-300"
              } `}
              placeholder="Enter Your name"
            />
            {errors.schoolStateLocation && (
              <p className="text-red-500 text-xs mt-1">
                {errors.schoolStateLocation}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">TC Name</label>
            <input
              type="text"
              name="tc_name"
              value={tc_name}
              disabled
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Full Name <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => handleInputChange(e, setName)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.name ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter Your name"
            />
            {errors.name && (
              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
            )}
          </div>

          <FormDropdown
            name="service_type"
            value={serviceType}
            label="Training Type "
            onChange={handleServiceTypeSelectChange}
            options={[
              { value: "Pre-service", label: "Pre-Service" },
              { value: "In-service", label: "In-Service" },
            ]}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.serviceType}
          />
          <FormDropdown
            type="radio"
            name="certified_as_NSQF_assesors"
            value={
              nsqfAssesor === true
                ? "true"
                : nsqfAssesor === false
                ? "false"
                : ""
            }
            label="Certified NSQF Assesor?"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            onChange={handleNsqfAssesorChange}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.nsqfAssesor}
          />
          <FormDropdown
            type="radio"
            name="certified_as_NSQF_verifiers"
            value={
              nsqfVerifier === true
                ? "true"
                : nsqfVerifier === false
                ? "false"
                : ""
            }
            label="Certified NSQF Verifier?"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            onChange={handleNsqfVerifierChange}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.nsqfVerifier}
          />
          <FormDropdown
            name="gender"
            value={gender}
            label="Gender"
            onChange={handleSelectChange}
            options={[
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
            ]}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.gender}
          />

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Phone Number <span className=" text-red-600">*</span>
            </label>
            <input
              type="text"
              name="phone_number"
              value={phoneNumber}
              onChange={(e) => handleInputChange(e, setPhoneNumber)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.phoneNumber ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter Phone Number"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Email <span className=" text-red-600">*</span>
            </label>
            <input
              type="email"
              name="Email"
              value={email}
              onChange={(e) => handleInputChange(e, setEmail)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.email ? "border-red-500" : "border-gray-300"
              } `}
              placeholder="Enter Email"
            />
          </div>
          <FormDropdown
            name="area_of_specialization"
            label="Select Occupational Trade"
            value={specialization}
            options={options}
            onChange={(e) => handleInputChange(e, setSpecialization)}
            placeholder="- Choose -"
            errorMsg={errors.specialization}
            isRequired={true}
          />
          <FormDropdown
            name="digital_literacy_training"
            value={
              digitalLiteracyTraining === true
                ? "true"
                : digitalLiteracyTraining === false
                ? "false"
                : ""
            }
            label="Has the Teacher complete Digital Literacy Training?"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            onChange={handleDigitalLiteracyTrainingChange}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.digitalLiteracyTraining}
          />

          <FormDropdown
            name="pedagogy_training"
            value={
              pedagogyTraining === true
                ? "true"
                : pedagogyTraining === false
                ? "false"
                : ""
            }
            label="Did the Teacher complete Pedagogy Training?"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            onChange={handlePedagogyTrainingChange}
            placeholder="- Choose -"
            isRequired={true}
            errorMsg={errors.pedagogyTraining}
          />

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Date of Birth <span className=" text-red-600">*</span>
            </label>
            <input
              type="date"
              name="date_of_birth"
              value={dob}
              onChange={(e) => handleInputChange(e, setDob)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.dob ? "border-red-500" : "border-gray-300"
              } `}
            />
            {errors.dob && (
              <p className="text-red-500 text-xs mt-1">{errors.dob}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Age</label>
            <input
              type="number"
              name="age"
              value={age}
              onChange={(e) => handleInputChange(e, setAge)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Age"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Designation in the College
            </label>
            <input
              type="text"
              name="designation_in_the_college"
              value={designationCollege}
              onChange={(e) => handleInputChange(e, setDesignationCollege)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Designation in the College"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Date of Appointment <span className=" text-red-600">*</span>
            </label>
            <input
              type="date"
              name="date_of_appointment"
              value={appointmentDate}
              onChange={(e) => handleInputChange(e, setAppointmentDate)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.appointmentDate ? "border-red-500" : "border-gray-300"
              } `}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Years of Experience
            </label>
            <input
              type="number"
              name="years_of_experience"
              value={experience}
              onChange={(e) => handleInputChange(e, setExperience)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Years of Experience"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Possible Date of Retirement{" "}
              <span className=" text-red-600">*</span>
            </label>
            <input
              type="date"
              name="possible_date_of_retirement"
              value={retirementDate}
              onChange={(e) => handleInputChange(e, setRetirementDate)}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${
                errors.retirementDate ? "border-red-500" : "border-gray-300"
              } `}
            />
            {errors.retirementDate && (
              <p className="text-red-500 text-xs mt-1">
                {errors.retirementDate}
              </p>
            )}
          </div>
          <FormDropdown
            name="teaching_level"
            label="Teaching Level"
            options={[
              { value: "Senior", label: "Senior" },
              { value: "Junior", label: "Junior" },
            ]}
            value={teachingLevel}
            onChange={(e) => handleInputChange(e, setTeachingLevel)}
            placeholder="Enter Teaching Level"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <FormDropdown
            name="nature_of_engagement"
            label="Nature of Engagement"
            options={[
              { value: "Full-time", label: "Full-time" },
              { value: "Part-time", label: "Part-time" },
            ]}
            value={engagement}
            onChange={(e) => handleInputChange(e, setEngagement)}
            placeholder="Enter Nature of Engagement"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Highest Level of Education Completed
            </label>
            <input
              type="text"
              name="highest_level_of_education_completed"
              value={eduCompleted}
              onChange={(e) => handleInputChange(e, setEduCompleted)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Highest Level of Education Completed"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Highest Level of Teacher Training
            </label>
            <input
              type="text"
              name="highest_level_ofteacher_training"
              value={teacherTraining}
              onChange={(e) => handleInputChange(e, setTeacherTraining)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Highest Level of Teacher Training"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Professional Certification
            </label>
            <input
              type="text"
              name="professional_certificate"
              value={proCertificate}
              onChange={(e) => handleInputChange(e, setProCertificate)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Professional Certification"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Trade area Taught in the College
            </label>
            <input
              type="text"
              name="trade_area_taught_in_the_college"
              value={tradeArea}
              onChange={(e) => handleInputChange(e, setTradeArea)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Trade area Taught in the College"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Subject(s) Taught in the College
            </label>
            <input
              type="text"
              name="subjects_taught_in_the_college"
              value={subjectsTaught}
              onChange={(e) => handleInputChange(e, setSubjectsTaught)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Subject(s) Taught in the College"
            />
          </div>
          <FormDropdown
            name="ict_proficiency"
            label="ICT Proficiency"
            value={ictProficiency}
            options={[
              { value: "Beginner", label: "Beginner" },
              { value: "Intermediate", label: "Intermediate" },
              { value: "Advanced", label: "Advanced" },
            ]}
            onChange={handleSelectChange2}
            placeholder="- Choose -"
          />
          <FormDropdown
            name="did_you_participate_in_the_ideas_tti_training"
            label="IDEAS TTI Training"
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            value={participationTti}
            onChange={handleSelectChange3}
            placeholder="Select an option"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />

          <div className="mb-4">
            <FormDropdown
              name="special_need"
              label="Does teacher have special needs?"
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              value={specialNeeds}
              onChange={(e) => handleSpecialNeedsChange(e)}
              placeholder="Select an option"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-active_bg text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ModalTeacherForm;
