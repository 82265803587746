export const PDOHeaders = [
    {
        id: 1,
        header: "Graduate Performance"
    },
    {
        id: 2,
        header: "Female Enrollment"
    },
    {
        id: 3,
        header: "Job Focused Interventions"
    },
]

export const PDO = [
    {
        id: 1,
        indicator: "Percentage of employers of graduates from project supported TC programs indicating they are satisfied with the performance of the graduates (percentage points) (CE indicator)"
    },
    {
        id: 2,
        indicator: "Female enrollment rate in project supported Technical Colleges programs (percentage) = No. of Female Students enrolled in supported programs/Total No. of Students enrolled in Supported programs X 100"
    },
    {
        id: 3,
        indicator: "BENEFICIARIES OF JOB-FOCUSED   INTERVENTIONS (STUDENTS) Direct Project beneficiaries   (disaggregated by gender for Students)"
    },
    
]