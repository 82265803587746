import React, { useState } from "react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { Tab, Tabs } from "@mui/material";
import IoiPreview from "../Pages/Component1/Preview/table_components/Ioi";
import PdoPreview from "../Pages/Component1/Preview/table_components/Pdo";
import { toSentenceCase } from "../utils/toSentenceCase";

const ReviewList = ({ data, propsJurisdiction }) => {
  const [showAnswers1, setShowAnswers1] = useState(false);
  const [tabIndex1, setTabIndex1] = useState(0);

  const displayAnswers1 = () => {
    setShowAnswers1(!showAnswers1);
    // fetchDatas();
  };

  const handleChange1 = (event, newValue) => {
    setTabIndex1(newValue);
  };
  
  return (
    <>
      <div
        className="text-primary_text cursor-pointer ml-4 mr-2 mt-4"
        onClick={displayAnswers1}
      >
        <div
          className="flex justify-between items-center border-2 border-indicator_border mb-2 rounded-md px-4 h-16"
          style={{
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "white",
          }}
        >
          <div className="w-[85%] text-md font-bold">
            {toSentenceCase(data.institution_name)}
          </div>

          <ArrowDropDownCircleIcon
            sx={{
              fontSize: "30px",
              color: "#0E4F2F",
              transform: showAnswers1 ? "rotate(180deg)" : "",
            }}
          />
        </div>
      </div>
      {showAnswers1 && (
        <div>
          <Tabs
            value={tabIndex1}
            onChange={handleChange1}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#0E4F2F",
                height: "5px",
                borderRadius: "1rem",
              },
            }}
            sx={{
              justifyContent: "center",
              paddingLeft: "30px",
              marginBottom: "12px",
            }}
          >
            <Tab
              label="Intermediate Indicators"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
            <Tab
              label="PDO Indicators"
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                color: "#9BB6A8",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#0E4F2F", // Active state color
                },
              }}
            />
          </Tabs>
          {tabIndex1 === 0 && (
            <div className="mx-4">
              <IoiPreview data={data} propsJurisdiction={propsJurisdiction} />
              {/* Integrating IoiPreview component */}
            </div>
          )}
          {tabIndex1 === 1 && (
            <div className="mx-4">
              <PdoPreview data={data} propsJurisdiction={propsJurisdiction} />{" "}
              {/* Integrating PdoPreview component */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ReviewList;
