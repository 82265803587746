import { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import CustomButton from "./button";
import { IndicatorEditModalFormIoi1 } from "./Modal_Forms/IndicatorEditModal";
import Preview from "../Pages/Component1/Preview/Preview";

const UpdateNavigationModal = ({
  formTitle,
  closeModal,
  onClick,
  setShowPreview,
}) => {
  const modalRef = useRef(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  return (
    <>
      <div className="fixed left-0 top-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
        <motion.div
          ref={modalRef}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="scrollbar-hide overflow-auto w-[50%] h-[50vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
        >
          <div className="flex justify-between font-bold">
            <h1 className="text-2xl text-orange-500">Notice! </h1>
            <CloseIcon
              onClick={closeModal}
              sx={{ fontSize: "2rem" }}
              className="text-red-500 cursor-pointer absolute top-2 right-4"
            />
          </div>
          <hr className="mt-2 mb-4" />
          <div>
            <p className="mb-4">
              Sorry, there is data in <strong>{formTitle}</strong>. You can only
              edit and update this data. Click the button below to update.
            </p>
            <div className="flex justify-center">
              <CustomButton className="bg-active_bg" onClick={onClick}>
                Update
              </CustomButton>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

UpdateNavigationModal.propTypes = {
  formTitle: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  ioiType: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
};

export default UpdateNavigationModal;
