import React, { useState } from "react";
import { TechnicalCollegeDataDisplay } from "../card_components/TC";
import TeachersAnalyticsDisplay from "../card_components/Teachers";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../features/auth/authSlice";
import axiosInstance from "../../../../Services/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
import {
  FirstBarChartDisplay,
  SecondBarChartDisplay,
  ThirdBarChartDisplay,
} from "../chart_components/BarChartDisplays";
import { StudentsDoughnutChartDisplay } from "../chart_components/DoughnutChart";
import PageHeader from "../../../../Components/page_header";
import TotalStudentsInTCCard from "../card_components/TotalStudentsInTCCard";
import SummaryProjectDataCard from "../card_components/SummaryProjectDataCard";

import { fetchAllStudents } from "../../../../utils/apiContext";
import ShowError from "../../../../utils/showError";
import Loader from "../../../../Components/Loader";
import {
  useIoi1Post,
  useIoi2Post,
  useIoi3Post,
  useIoi4Post,
} from "../../../../Hooks/useIoiPosts";
import { usePdo2Post, usePdo3Post } from "../../../../Hooks/usePdoPosts";
import { useCCIPost, useProjectSummaryData } from "../../../../Hooks/useOtherPosts";

const M_E_Dashboard = ({ options }) => {
  // const userRoles = useSelector((state) => state.userRole.roles);
  const userRoles = useSelector(selectCurrentUserRole);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);

  const decodedTkn = jwtDecode(userTkn);
  const { tc_name } = decodedTkn;

  // Ioi1Post fetch hook to check if post exist and update state
  useIoi1Post(jurisdiction, tc_name);

  // Ioi2Post fetch hook to check if post exist and update state
  useIoi2Post(jurisdiction, tc_name);

  // Ioi3Post fetch hook to check if post exist and update state
  useIoi3Post(jurisdiction, tc_name);

  // Ioi4Post fetch hook to check if post exist and update state
  useIoi4Post(jurisdiction, tc_name);

  // Pdo2Post fetch hook to check if post exist and update state
  usePdo2Post(jurisdiction, tc_name);

  // Pdo3Post fetch hook to check if post exist and update state
  usePdo3Post(jurisdiction, tc_name);

  // CciPost fecth to check if post exist and update state
  useCCIPost(jurisdiction, tc_name)

  useProjectSummaryData(jurisdiction, tc_name)

  const filterAndAggregateData = (data, tc_name) => {
    let maleCount = 0;
    let femaleCount = 0;
    let maleGraduates = 0;
    let femaleGraduates = 0;

    const tradeCounts = data.reduce((acc, obj) => {
      if (!acc[obj.occupational_trade]) {
        acc[obj.occupational_trade] = { male: 0, female: 0 };
      }
      return acc;
    }, {});

    const filteredData = data.filter((obj) => obj.school_name === tc_name);

    filteredData.forEach((obj) => {
      if (obj.sex === "male") {
        maleCount += 1;
        if (obj.grad_status === "yes") {
          maleGraduates += 1;
        }
        if (tradeCounts[obj.occupational_trade]) {
          tradeCounts[obj.occupational_trade].male += 1;
        }
      } else if (obj.sex === "female") {
        femaleCount += 1;
        if (obj.grad_status === "yes") {
          femaleGraduates += 1;
        }
        if (tradeCounts[obj.occupational_trade]) {
          tradeCounts[obj.occupational_trade].female += 1;
        }
      }
    });

    return {
      maleCount,
      femaleCount,
      maleGraduates,
      femaleGraduates,
      totalStudents: filteredData.length,
      tradeCounts,
    };
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["studentData"],
    queryFn: () => fetchAllStudents(jurisdiction),
  });

  const aggregatedData = data
    ? filterAndAggregateData(data, tc_name)
    : {
        maleCount: 0,
        femaleCount: 0,
        maleGraduates: 0,
        femaleGraduates: 0,
        totalStudents: 0,
        tradeCounts: {},
      };
    
      

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return (
      <div>
        <ShowError />
      </div>
    );
  }

  return (
    <div>
      <PageHeader title="Analytics" />

      <div className="mt-3 px-3">
        <div className="flex gap-x-4">
          <div className="w-[45%]">
            <div className=" flex gap-x-6">
              <TotalStudentsInTCCard
                totalStudentsInTC={aggregatedData.totalStudents}
              />
              <div className="custom-shadow bg-white px-4 py-4 rounded-lg">
                <h1
                  className="text-[20px] font-bold text-primary_text"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Total number of Students in TVET
                </h1>
                <h1
                  className="text-active_bg text-3xl font-bold mt-2"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  {userRoles === "Validator" || userRoles === "Reviewer"
                    ? 15140
                    : userRoles === "M_E_OFFICE"
                    ? `${aggregatedData.totalStudents}`
                    : ""}
                </h1>
              </div>
            </div>
            <br />
            <div className="custom-shadow h-auto bg-white rounded-lg">
              <TechnicalCollegeDataDisplay />
            </div>
          </div>

          <div className="w-[55%] custom-shadow h-auto bg-white rounded-lg">
            <SummaryProjectDataCard />
          </div>
        </div>

        <br />

        <div className="flex justify-between">
          <div className="custom-shadow w-[30%] flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold text-[20px]"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Enrolled Students
            </h1>
            <SecondBarChartDisplay
              maleCount={aggregatedData.maleCount}
              femaleCount={aggregatedData.femaleCount}
            />
          </div> 
          <div className="custom-shadow w-[30%] flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold text-[20px]"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Graduate Students
            </h1>
            <FirstBarChartDisplay
              maleCount={aggregatedData.maleGraduates}
              femaleCount={aggregatedData.femaleGraduates}
            />
          </div>
          <div className="custom-shadow w-[30%] flex flex-col items-center bg-white px-4 py-4 rounded-lg">
            <h1
              className="text-primary_text font-bold text-[20px]"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Workshops in TC
            </h1>
            <ThirdBarChartDisplay
              
            />
          </div>
          
        </div>

        <br />
        <div className="custom-shadow h-auto bg-white rounded-lg">
          <div className="px-4 py-4">
            <h1
              className="text-primary_text font-bold text-[20px]"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Students in Supported Trades
            </h1>
            <br />

            <div>
              <StudentsDoughnutChartDisplay data={aggregatedData} />
            </div>
          </div>
        </div>
        <br />
        <div className="custom-shadow h-auto bg-white rounded-lg">
          <TeachersAnalyticsDisplay />
        </div>
        {/* <div className="w-[350px] h-auto">
          <TotalStudentsInTCCard
            totalStudentsInTC={aggregatedData.totalStudents}
          />
          <br />
          <div className="custom-shadow bg-white px-6 py-6 rounded-lg">
            <h1
              className="text-[20px] font-bold text-primary_text"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Total number of Students in TVET
            </h1>
            <h1
              className="text-active_bg text-3xl font-bold mt-2"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {userRoles === "Validator" || userRoles === "Reviewer"
                ? 15140
                : userRoles === "M_E_OFFICE"
                ? `${aggregatedData.totalStudents}`
                : ""}
            </h1>
          </div>
          <br />
          <div className="custom-shadow bg-white px-4 py-14 rounded-lg">
            {/* <StudentsCard /> */}
        {/* <div className="">
              <div>
                <h1
                  className="text-primary_text font-bold mb-8 text-[20px]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Total Number of enrolled students
                </h1>
                <SecondBarChartDisplay
                  maleCount={aggregatedData.maleCount}
                  femaleCount={aggregatedData.femaleCount}
                />
              </div>
              <div className="">
                <h1
                  className="text-primary_text font-bold mt-8 mb-4 text-[20px]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Total Number of graduates{" "}
                </h1>
                <FirstBarChartDisplay
                  maleCount={aggregatedData.maleGraduates}
                  femaleCount={aggregatedData.femaleGraduates}
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="w-[710px] flex flex-col gap-4">
          {/* <div className="custom-shadow h-auto bg-white rounded-lg">
            <SummaryProjectDataCard />
          </div> */}
          {/* <div className="custom-shadow h-auto bg-white rounded-lg">
            <TechnicalCollegeDataDisplay />
          </div> */}
          {/* <div className="custom-shadow h-auto bg-white rounded-lg">
            <div className="px-4 py-4">
              <h1
                className="text-primary_text font-bold text-[20px]"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Students in Supported Trades
              </h1>
              <br />
              <br />
              <div>
                <StudentsDoughnutChartDisplay data={aggregatedData} />
              </div>
            </div>
          </div> */}
          {/* <div className="custom-shadow h-auto bg-white rounded-lg">
            <TeachersAnalyticsDisplay />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default M_E_Dashboard;
