import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

import { BarChartDisplayTti } from "../../Analytics_Dashboard/chart_components/BarChartDisplays";

const IntermediateCardComponent3 = ({
  maleCount,
  femaleCount,

  percentageMale,
  percentageFemale,
}) => {
  return (
    <div className="">
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Intermediate Outcome Indicators
      </h1>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={2}
        navigation={{ nextEl: ".button-next1", prevEl: ".button-prev1" }}
      >
        <SwiperSlide>
          <BarChartDisplayTti maleCount={maleCount} femaleCount={femaleCount} />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Number of technical teachers and instructors participating in
            supported skills upgrading programs.{" "}
            <strong> {percentageFemale}% </strong>Female and{" "}
            <strong> {percentageMale}%</strong>
            Male
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <BarChartDisplayTti maleCount={maleCount} femaleCount={femaleCount} />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Number of technical teachers and instructors TRAINING COURSES in
            pre-and in-service programs that are enriched with
            technology-enabled teaching and learning instruments (cumulated)
            (Number)
          </p>
        </SwiperSlide>
      </Swiper>
      <Box className="flex items-center justify-between text-primary_text_light mt-3">
        <motion.div whileTap={{ scale: 0.9 }} className="button-prev1">
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
        <motion.div whileTap={{ scale: 0.9 }} className="button-next1">
          <KeyboardArrowRightIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
      </Box>
    </div>
  );
};

export default IntermediateCardComponent3;
