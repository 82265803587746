import React, { useState } from "react";
import PageHeader from "../../../Components/page_header";

import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import IoiPreview from "../Preview/table_components/Ioi";
import PdoPreview from "../Preview/table_components/Pdo";
import { useQuery } from "@tanstack/react-query";
import { fetchAllTechnicalCollegePosts } from "../../../utils/apiContext";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectValidatorState,
} from "../../../features/auth/authSlice";
import ReviewList from "../../../Components/reviewList";
import ShowLoader from "../../../utils/showLoader";
import ShowError from "../../../utils/showError";

const Validate = () => {
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const validatorState = useSelector(selectValidatorState);

  const {
    data: allTcData,
    isLoading:validatorLoading,
    error: validatorError,
  } = useQuery({
    queryKey: ["all_Tc"],
    queryFn: () => fetchAllTechnicalCollegePosts(jurisdiction),
  });

  const filteredPost = allTcData?.filter(
    (post) => post?.institution_state === validatorState
  );

  if(validatorLoading) {
    return <ShowLoader />
  }
  if(validatorError){
    return <ShowError />
  }

  return (
    <div>
      <PageHeader title="Validate" />
      {filteredPost?.map((data) => (
        <ReviewList key={data._id} data={data} />
      ))}
    </div>
  );
};

export default Validate;
