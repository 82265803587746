import React, { useState } from "react";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import {
  intermediateOutcomeHeader,
  intermediateOutcomeIndicator,
} from "../../Models/ComponentOne/IntermediateOIndicators/data";
import IntermediateOutcome1 from "../Modal_Forms/Component1/IntermediateOI/Indicator1";
import IntermediateOutcome2 from "../Modal_Forms/Component1/IntermediateOI/Indicator2";
import IntermediateOutcome3 from "../Modal_Forms/Component1/IntermediateOI/Indicator3";
import IntermediateOutcome4 from "../Modal_Forms/Component1/IntermediateOI/Indicator4";
import { PDO, PDOHeaders } from "../../Models/ComponentOne/PDO/data";
import PDO2 from "../Modal_Forms/Component1/PDO/pdo2";
import PDO3 from "../Modal_Forms/Component1/PDO/pdo3";

import { useSelector } from "react-redux";
import {
  selectIoi1Post,
  selectIoi2Post,
  selectIoi3Post,
  selectIoi4Post,
  selectPdo2Post,
  selectPdo3Post,
  selectTcPost,
} from "../../features/auth/isPostMade";
import UpdateNavigationModal from "../UpdatePromptModal";
import Preview from "../../Pages/Component1/Preview/Preview";

const TcCard = ({ openModal, isDataAvailable }) => {
  const [openPdoModal, setOpenPdoModal] = useState(null);
  const [openIntermediateModal, setOpenIntermediateOpenModal] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(null); // State to control UpdateNavigationModal
  const [showPreview, setShowPreview] = useState(false);
  const isIoi1Post = useSelector(selectIoi1Post);
  const isIoi2Post = useSelector(selectIoi2Post);
  const isIoi3Post = useSelector(selectIoi3Post);
  const isIoi4Post = useSelector(selectIoi4Post);
  const [propsIndex, setPropsIndex] = useState("");
  // Pdo posts in redux state
  const isPdo2Post = useSelector(selectPdo2Post);
  const isPdo3Post = useSelector(selectPdo3Post);
  const isTcPost = useSelector(selectTcPost);

  const selectIndex = (index, type) => {
    if (type === "intermediate") {
      return index === 0
        ? isIoi1Post
        : index === 1
        ? isIoi2Post
        : index === 2
        ? isIoi3Post
        : index === 3
        ? isIoi4Post
        : undefined;
    } else if (type === "pdo") {
      return index === 0
        ? true
        : index === 1
        ? isPdo2Post
        : index === 2
        ? isPdo3Post
        : undefined;
    } else if (type === "tc") {
      return isTcPost;
    }
  };

  const selectIoiIndex = (index) => {
    return index === 0
      ? isIoi1Post
      : index === 1
      ? isIoi2Post
      : index === 2
      ? isIoi3Post
      : index === 3
      ? isIoi4Post
      : undefined;
  };

  const closePage = () => {
    setShowPreview(false);
  };

  const handleButtonClick = (index, type) => {
    if (type === "intermediate") {
      setPropsIndex(3);

      if (selectIndex(index, type)) {
        setOpenUpdateModal({ index, type });
      } else {
        setOpenIntermediateOpenModal(index);
      }
    } else if (type === "pdo") {
      setPropsIndex(4);

      if (selectIndex(index, type)) {
        setOpenUpdateModal({ index, type });
      } else {
        setOpenPdoModal(index);
      }
    } else if (type === "tc") {
      setPropsIndex(0);
      if (selectIndex(index, type)) {
        setOpenUpdateModal({ index, type });
      } else {
        openModal();
      }
    }
  };

  const showPreviewDisplay = () => {
    setShowPreview((prev) => !prev);
  };

  const displayTcModal = () => {};

  return (
    <div className="flex-col w-auto rounded-xl shadow-xl mt-3 bg-white">
      <div className="p-6">
        <div className="flex items-center">
          <AccountBalanceOutlinedIcon
            className="text-linear_grad_1"
            style={{ fontSize: "2.0rem" }}
          />
          <p
            className="font-bold text-[20px] pt-2 ml-2 text-active_bg"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Technical Colleges
          </p>
        </div>
        <div className="flex mt-4">
          <p
            className="text-gray-500 text-sm font-sm mt-2"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Enter Technical colleges data here. Click save when you're done.
          </p>
          <button
            onClick={() => handleButtonClick(0, "tc")}
            className="text-active_bg text-nowrap font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white ml-4 mt-3 text-sm"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            + ADD DATA
          </button>
        </div>
      </div>

      <div className="px-4 pt-4">
        <h2
          className="text-active_bg font-bold py-2 pb-8"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Intermediate Outcome Indicators
        </h2>
        {intermediateOutcomeHeader.map((item, index) => (
          <div
            key={item.id}
            className="flex items-center justify-between px-2 border-2 border-indicator_border mb-4 text-sm py-2 text-primary_text rounded-md"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <div className="w-2/3">
              <p className="uppercase ">{item.header}</p>
            </div>
            <button
              onClick={() => handleButtonClick(index, "intermediate")}
              disabled={selectIoiIndex(index, "intermediate") ? false : false}
              className="text-active_bg font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              + ADD DATA
            </button>
          </div>
        ))}

        {openIntermediateModal === 0 && (
          <IntermediateOutcome1
            title={
              intermediateOutcomeIndicator[openIntermediateModal].indicator
            }
            closeForm={() => setOpenIntermediateOpenModal(null)}
          />
        )}
        {openIntermediateModal === 1 && (
          <IntermediateOutcome2
            title={
              intermediateOutcomeIndicator[openIntermediateModal].indicator
            }
            closeForm={() => setOpenIntermediateOpenModal(null)}
          />
        )}
        {openIntermediateModal === 2 && (
          <IntermediateOutcome3
            title={
              intermediateOutcomeIndicator[openIntermediateModal].indicator
            }
            closeForm={() => setOpenIntermediateOpenModal(null)}
          />
        )}
        {openIntermediateModal === 3 && (
          <IntermediateOutcome4
            title={
              intermediateOutcomeIndicator[openIntermediateModal].indicator
            }
            closeForm={() => setOpenIntermediateOpenModal(null)}
          />
        )}
      </div>

      <div className="p-4">
        <h2
          className="text-active_bg font-bold py-2 pb-6"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          PDO Indicators
        </h2>
        {PDOHeaders.map((item, index) => (
          <div
            key={item.id}
            className="flex items-center justify-between px-2 border-2 border-indicator_border mb-4 text-sm py-2 text-primary_text rounded-md"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            <div className="w-[70%]">
              <p className="uppercase ">{item.header}</p>
            </div>

            <button
              onClick={() => handleButtonClick(index, "pdo")}
              className="text-active_bg font-bold py-2 rounded-md px-4 cursor-pointer hover:bg-active_bg hover:text-white"
            >
              + ADD DATA
            </button>
          </div>
        ))}
        {openPdoModal === 1 && (
          <PDO2
            title={PDO[openPdoModal].indicator}
            closeForm={() => setOpenPdoModal(null)}
          />
        )}
        {openPdoModal === 2 && (
          <PDO3
            title={PDO[openPdoModal].indicator}
            closeForm={() => setOpenPdoModal(null)}
          />
        )}
      </div>

      {openUpdateModal !== null && (
        <UpdateNavigationModal
          urlLink={`beneficiaries-data`} // Customize URL as needed
          formTitle={
            openUpdateModal.type === "intermediate"
              ? intermediateOutcomeIndicator[openUpdateModal.index].indicator
              : openUpdateModal.type === "pdo"
              ? PDO[openUpdateModal.index].indicator
              : "Technical College"
          }
          closeModal={() => setOpenUpdateModal(null)}
          onClick={showPreviewDisplay}
        />
      )}

      {showPreview && (
        <Preview
          title="Preview"
          closePage={closePage}
          isProps={true}
          propsIndex={propsIndex}
        />
      )}
    </div>
  );
};

export default TcCard;
