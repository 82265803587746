import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import ShowLoader from "../../../../utils/showLoader";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../../features/auth/authSlice";

const tradeColorMap = {
  Construction: "#0E4F2F",
  "Mechanical Engineering": "#45BC5F",
  Hospitality: "#843C30",
  "Electrical Engineering": "#BC5645",
  ICT: "#BC5645",
  "Agricultural Science": "#C0AA54",
  "Garment Making": "#CFF0B5",
};

export const TeachersDoughnutChartDisplay = ({ data }) => {
  const userRole = useSelector(selectCurrentUserRole);

  if (!data) {
    return <ShowLoader />; // Or any other fallback UI
  }
  return (
    <PieChart
      series={[
        {
          data: data.map((item) => ({
            ...item,
            label: `${item.label} (${item.value})`,
          })),
          arcLabel: (item) => `${item.label}`,
          arcLabelMinAngle: 1,
          innerRadius: 30,
          cornerRadius: 5,
          // cx: 300,
          cx: userRole === "M_E_OFFICE" ? 300 : userRole === "Validator" ? 170 : 170,
          // cy: 175,
          cy: 175,
        },
      ]}
      width={
        userRole === "M_E_OFFICE" ? 800 : userRole === "Validator" ? 680 : 680
      }
      // width={680}
      height={350}
      // height={350}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
          fontSize: "10px",
        },
      }}
    />
  );
};

export const StudentsDoughnutChartDisplay = ({ data }) => {
  const userRole = useSelector(selectCurrentUserRole);
  const { tradeCounts } = data;

  if (!data) {
    return <ShowLoader />;
  }

  const chartData = Object.keys(tradeCounts).map((trade) => ({
    id: trade,
    value: tradeCounts[trade].male + tradeCounts[trade].female,
    color: tradeColorMap[trade] || "#000000",
    label: trade,
    male: tradeCounts[trade].male,
    female: tradeCounts[trade].female,
  }));

  return (
    <PieChart
      series={[
        {
          data: chartData.map((item) => ({
            ...item,
            label: `${item.label} (Male: ${item.male}, Female: ${item.female})`,
          })),
          arcLabel: (item) => `${item.label}`,
          arcLabelMinAngle: 5,
          innerRadius: 10,
          cornerRadius: 5,
          cx: userRole === "M_E_OFFICE" ? 300 : userRole === "Validator" ? 155 : 155,
          // cx: 155,
          cy: userRole === "M_E_OFFICE" ? 175 : userRole === "Validator" ? 140 : 140,
          // cy: 140,
        },
      ]}
      width={userRole === "M_E_OFFICE" ? 900 : userRole === "Validator" ? 680 : 680}
      height={userRole === "M_E_OFFICE" ? 350 : userRole === "Validator" ? 300 : 300}
      // width={680}
      // height={300}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
          fontSize: "10px",
        },
      }}
    />
  );
};
