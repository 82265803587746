import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectCurrentUserRole } from "../../features/auth/authSlice";
import Unauthorized from "./Unauthorized";

const ProtectedRoute = ({ element, roles = [] }) => {
  // const userRoles = useSelector((state) => state.userRole.roles) || [];
  const userRoles = useSelector(selectCurrentUserRole) || [];

  const hasAccess = roles.some((role) => userRoles.includes(role));

  return hasAccess ? element : <Navigate to="/unauthorized" />;

  // return roles.includes(userRole) ? element : <Unauthorized />;
};

export default ProtectedRoute;
