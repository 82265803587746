import { useSelector } from "react-redux";
import axiosInstance from "../Services/axiosInstance";

export const fetchAllStudents = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/student/c1/get-all-students/?jurisdiction=${jurisdiction}`
  );

  return response.data.all_students;
};

export const filterStudentByClass = async (tc, jurisdiction, studentClass) => {
  const response = await axiosInstance.get(
    `/student/c1/get-classified-students/?school_name=${tc}&&jurisdiction=${jurisdiction}&&class=${studentClass}`
  );

  return response.data.filteredGroup;
};

// fetch request for CCI
export const fetchAllCCIPosts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/ccs/c1/get-cross-cutting-issues/?jurisdiction=${jurisdiction}`
  );
  return response.data.data;
};
export const fetchCCIById = async (id, jurisdiction) => {
  const response = await axiosInstance.get(
    `/ccs/c1/get-specific-cross-cutting-issue/${id}/?jurisdiction=${jurisdiction}`
  );

  return response.data.data;
};

// fetch request for teachers
export const fetchAllTeachersPosts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/teachers/c1/get-all-teachers/?jurisdiction=${jurisdiction}`
  );
  // console.log(response.data.all_posts);
  return response.data.all_posts;
};
// fetch request for teachers service type
export const fetchAllTeachersServiceType = async () => {
  const response = await axiosInstance.get(
    `/teachers/c1/get-service-typed-teachers`
  );

  return response.data.Filtered_data;
};

// fetch request for tc
export const fetchAllTechnicalCollegePosts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/tc/c1/get-all-posts/?jurisdiction=${jurisdiction}`
  );
  // console.log("API response:", response.data); // Log the API response
  return response.data.all_posts;
};

export const fetchTechnicalCollegePostById = async (id, jurisdiction) => {
  const response = await axiosInstance.get(
    `/tc/c1/get-post/${id}/?jurisdiction=${jurisdiction}`
  );

  return response.data.subDoc;
};

// fetch request for ioi1
export const fetchAllIOI1Posts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-posts-for-no-of-supported-tc-by-tc-for-${jurisdiction}`
  );
  return response.data.found_posts;
};
export const fetchIOI1PostById = async (id, jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-post-for-no-of-supported-tc/${id}/?jurisdiction=${jurisdiction}`
  );

  return response.data.tc_post;
};

// fetch request for ioi2
export const fetchAllIOI2Posts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-posts-no-of-training-programs-delivered-monitored-for-${jurisdiction}`
  );
  // console.log(response.data);
  return response.data.found_posts;
};
export const fetchIOI2PostById = async (id, jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-post-no-of-training-programs-delivered-monitored/${id}/?jurisdiction=${jurisdiction}`
  );

  return response.data.tc_post;
};

// fetch request for ioi3

export const fetchAllIOI3Posts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-posts-no-of-supported-tc-with-reporting-and-referral-mechanisms-for-gbv-affected-youth-for-${jurisdiction}`
  );

  return response.data.found_posts;
};
export const fetchIOI3PostById = async (id, jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-post-no-of-supported-tc-with-reporting-and-referral-mechanisms-for-gbv-affected-youth/${id}/?jurisdiction=${jurisdiction}`
  );

  return response.data.tc_post;
};

// fetch request for ioi4
export const fetchAllIOI4Posts = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-posts-no_of_fully_functioning_upgraded_workshops_in_supported_tc-for-${jurisdiction}`
  );

  return response.data.found_posts;
};
export const fetchIOI4PostById = async (id, jurisdiction) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-post-no-of-fully-functioning-upgraded-workshops-in-supported-tc/${id}/?jurisdiction=${jurisdiction}`
  );

  return response.data.tc_post;
};
export const fetchIOIPostForValidator = async (state) => {
  const response = await axiosInstance.get(
    `/ioi/c1/get-all-user2-specific-posts/?state=${state}`
  );

  return response.data.results;
};

export const fetchAllPDO2Posts = async (jurisdiction, tc_name) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-post-female-enrollment-rate-in-project-supportedTc/?jurisdiction=${jurisdiction}&tc_name=${tc_name}`
  );

  return response.data.requested_tc_data;
};
export const fetchAllPDO2PostsByValidator = async (jurisdiction, tc_name) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-post-female-enrollment-rate-in-project-supportedTc/?jurisdiction=${jurisdiction}&tc_name=${tc_name}`
  );

  return response.data.requested_tc_data;
};

export const fetchAllPDO2 = async (jurisdiction, tc_name) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-post-female-enrollment-rate-in-project-supportedTc/?jurisdiction=${jurisdiction}&tc_name=${tc_name}`
  );

  return response.data.requested_tc_data;
};
export const fetchAllPDO2PostsByJurisdiction = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-posts-female-enrollment-rate-in-project-supportedTc/?jurisdiction=${jurisdiction}`
  );

  return response.data.found_posts;
};
export const fetchPDO2PostForValidator = async (state) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-status-for-female-enrollment-rate-in-project-supportedTc-for-specific-state/?state=${state}`
  );

  return response.data.found_posts;
};
export const fetchPDO3PostForValidator = async (state) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-update-status-for-beneficiaries-of-job-focused-interventions-for-specific-state/?state=${state}`
  );

  return response.data.found_posts;
};
// export const fetchAllPDO3PostsByJurisdiction = async (state) => {
//   const response = await axiosInstance.get(
//     `/pdo/c1/get-update-status-for-beneficiaries-of-job-focused-interventions-for-specific-state/?state=${state}`
//   );

//   return response.data.found_posts;
// };
export const fetchAllPDO3Posts = async (jurisdiction, tc_name) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-post-beneficiaries-of-job-focused-interventions/?jurisdiction=${jurisdiction}&tc_name=${tc_name}`
  );
  // console.log(response.data.requested_tc_data);
  return response.data.requested_tc_data;
};
export const fetchAllPDO3PostsByJurisdiction = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/pdo/c1/get-posts-beneficiaries-of-job-focused-interventions/?jurisdiction=${jurisdiction}`
  );
  // console.log(response.data.requested_tc_data);
  return response.data.found_posts;
};

// FETCH FOR NATIONAL ADMIN

const studentsUrl = [
  "/student/c1/get-all-students/?jurisdiction=state",
  "/student/c1/get-all-students/?jurisdiction=federal",
];
const CCIUrl = [
  "/ccs/c1/get-cross-cutting-issues/?jurisdiction=state",

  "/ccs/c1/get-cross-cutting-issues/?jurisdiction=federal",
];
const technicalCollegesUrl = [
  "/tc/c1/get-all-posts/?jurisdiction=state",
  "/tc/c1/get-all-posts/?jurisdiction=federal",
];
const teachersUrl = [
  "/teachers/c1/get-all-teachers/?jurisdiction=state",
  "/teachers/c1/get-all-teachers/?jurisdiction=federal",
];
const IOI1Url = [
  "/ioi/c1/get-posts-for-no-of-supported-tc-by-tc-for-state",
  "/ioi/c1/get-posts-for-no-of-supported-tc-by-tc-for-federal",
];
const IOI2Url = [
  "/ioi/c1/get-posts-no-of-training-programs-delivered-monitored-for-state",
  "/ioi/c1/get-posts-no-of-training-programs-delivered-monitored-for-federal",
];
const IOI3Url = [
  "/ioi/c1/get-posts-no-of-supported-tc-with-reporting-and-referral-mechanisms-for-gbv-affected-youth-for-state",
  "/ioi/c1/get-posts-no-of-supported-tc-with-reporting-and-referral-mechanisms-for-gbv-affected-youth-for-federal",
];
const IOI4sUrl = [
  "/ioi/c1/get-posts-no_of_fully_functioning_upgraded_workshops_in_supported_tc-for-state",
  "/ioi/c1/get-posts-no_of_fully_functioning_upgraded_workshops_in_supported_tc-for-federal",
];
const PDO2Url = [
  "/pdo/c1/get-posts-female-enrollment-rate-in-project-supportedTc/?jurisdiction=state",
  "/pdo/c1/get-posts-female-enrollment-rate-in-project-supportedTc/?jurisdiction=federal",
];
const PDO3Url = [
  "/pdo/c1/get-posts-beneficiaries-of-job-focused-interventions/?jurisdiction=state",
  "/pdo/c1/get-posts-beneficiaries-of-job-focused-interventions/?jurisdiction=federal",
];

export const fetchAllStudentsForNA = async () => {
  const fetchPromises = studentsUrl.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllCCIForNA = async () => {
  const fetchPromises = CCIUrl.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};

export const fetchAllIOI1PostsForNA = async () => {
  const fetchPromises = IOI1Url.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllIOI2PostsForNA = async () => {
  const fetchPromises = IOI2Url.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllIOI3PostsForNA = async () => {
  const fetchPromises = IOI3Url.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllIOI4PostsForNA = async () => {
  const fetchPromises = IOI4sUrl.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllPDO2PostsForNA = async () => {
  const fetchPromises = PDO2Url.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllPDO3PostsForNA = async () => {
  const fetchPromises = PDO3Url.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};

//
export const fetchAllTeachersForNA = async () => {
  const fetchPromises = teachersUrl.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};
export const fetchAllTechnicalCollegesForNA = async () => {
  const fetchPromises = technicalCollegesUrl.map((url) =>
    axiosInstance.get(url).then((res) => res.data)
  );

  const results = await Promise.all(fetchPromises);
  return results;
};

// Fetch project summary
export const fetchProjectSummary = async (jurisdiction) => {
  const response = await axiosInstance.get(
    `/ps/c1/get-project-summary/?jurisdiction=${jurisdiction}`
  );
  return response.data;
};
