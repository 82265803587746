import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ShowError from "../../../../utils/showError";
import ShowLoader from "../../../../utils/showLoader";
import { fetchProjectSummary } from "../../../../utils/apiContext";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../../../features/auth/authSlice";
import { getTcName } from "../../../../utils/getFormattedToken";
import { toSentenceCase } from "../../../../utils/toSentenceCase";

const SummaryProjectDataCard = () => {
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const tc_name = getTcName(userTkn);

  const { data: allPosts, isLoading, error } = useQuery({
    queryKey: ["projectSummary"],
    queryFn: () => fetchProjectSummary(jurisdiction),
  });

  if (isLoading) {
    return <ShowLoader />;
  }

  if (error) {
    return <ShowError />;
  }

  const projectData = allPosts?.data?.filter((post) => post?.name_of_college === tc_name) || [];

  const {
    name_of_college = '',
    name_of_principal = '',
    date_of_receipt_of_initial_grant = '',
    industry_partners = [],
    project_manager = '',
    procurement_officer = '',
    communications_officer = '',
    m_and_e_officer = '',
    priority_trades = [],
    list_of_workshops_renovated = [],
    list_of_workshops_equipped = [],
    total_no_of_students_in_tc = ''
  } = projectData[0] || {};

  const formattedTcName = toSentenceCase(tc_name);

  const isEmptyData = !name_of_college && !name_of_principal && !date_of_receipt_of_initial_grant && !industry_partners.length && !project_manager && !procurement_officer && !communications_officer && !m_and_e_officer && !priority_trades.length && !list_of_workshops_renovated.length && !list_of_workshops_equipped.length && !total_no_of_students_in_tc;

  return (
    <div className="custom-shadow bg-white px-6 py-6 rounded-lg">
      <h1 className="text-[20px] font-bold text-primary_text" style={{ fontFamily: "Poppins, sans-serif" }}>
        Project Summary Data
      </h1>
      {isEmptyData ? (
        <div className="mt-4">
          <p>No data to display. Proceed to enter data.</p>
        </div>
      ) : (
        <div className="mt-4 space-y-4">
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">College Name:</span>
            <span>{formattedTcName}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Principal Name:</span>
            <span>{name_of_principal}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Date of Initial Grant:</span>
            <span>{date_of_receipt_of_initial_grant}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Industry Partners:</span>
            <span>{industry_partners.join(', ')}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Project Manager:</span>
            <span>{project_manager}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Procurement Officer:</span>
            <span>{procurement_officer}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Communications Officer:</span>
            <span>{communications_officer}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">M&E Officer:</span>
            <span>{m_and_e_officer}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Priority Trades:</span>
            <span>{priority_trades.join(', ')}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Workshops Renovated:</span>
            <span>{list_of_workshops_renovated.join(', ')}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Workshops Equipped:</span>
            <span>{list_of_workshops_equipped.join(', ')}</span>
          </div>
          <div className="flex justify-between border-b border-green-200 pb-2">
            <span className="font-bold">Total Students in TC:</span>
            <span>{total_no_of_students_in_tc}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryProjectDataCard;
