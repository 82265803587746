import { Chart } from "react-google-charts";
import PageHeader from "../../../Components/page_header";

const Track = () => {
  const data = [
    [
      { type: "string", label: "Task ID" },
      { type: "string", label: "Task Name" },
      { type: "string", label: "Resource" },
      { type: "date", label: "Start Date" },
      { type: "date", label: "End Date" },
      { type: "number", label: "Duration" },
      { type: "number", label: "Percent Complete" },
      { type: "string", label: "Dependencies" },
    ],
    [
      "1",
      "Selection of participating technical colleges",
      "",
      new Date(2022, 0, 1), // January 1, 2022
      new Date(2022, 0, 31), // January 31, 2022
      null,
      100,
      null,
    ],
    [
      "2",
      "Selection of priority trades/occupational areas",
      "TCs",
      new Date(2023, 3, 1), // April 1, 2023
      new Date(2023, 5, 30), // June 30, 2023
      null,
      100,
      null,
    ],
    [
      "3",
      "Development of college development plans",
      "TCs (with support of WB)",
      new Date(2024, 0, 1), // January 1, 2024
      new Date(2024, 11, 31), // December 31, 2024
      null,
      100,
      null,
    ],
    [
      "4",
      "General upgrading of college facilities",
      "TCs",
      new Date(2025, 0, 1), // January 1, 2025
      new Date(2025, 2, 31), // March 31, 2025
      null,
      50,
      null,
    ],
    [
      "5",
      "Upgrading of Priority Trade workshops",
      "TCs",
      new Date(2026, 0, 1), // January 1, 2026
      new Date(2027, 11, 31), // December 31, 2027
      null,
      50,
      null,
    ],
    [
      "5a",
      "Renovation of workshops",
      "TCs",
      new Date(2026, 5, 1), // June 1, 2026
      new Date(2026, 11, 31), // December 31, 2026
      null,
      50,
      "5",
    ],
    [
      "5b",
      "Procurement and installation of tools and equipment",
      "TCs",
      new Date(2027, 0, 1), // January 1, 2027
      new Date(2027, 5, 30), // June 30, 2027
      null,
      50,
      "5",
    ],
    [
      "6",
      "Training of staff in the use of equipment",
      "Vendors",
      new Date(2028, 2, 1), // March 1, 2028
      new Date(2028, 8, 30), // September 30, 2028
      null,
      0,
      "5b",
    ],
    [
      "7",
      "Sensitization of host communities on TVET, GBV etc.",
      "TCs",
      new Date(2029, 0, 1), // January 1, 2029
      new Date(2029, 11, 31), // December 31, 2029
      null,
      0,
      null,
    ],
  ];

  const options = {
    height: 500,
    gantt: {
      trackHeight: 50,
      criticalPathEnabled: false,
      innerGridHorizLine: {
        stroke: "#2B8056",
        strokeWidth: 2,
      },
      innerGridTrack: { fill: "#E0DEDE" },
      innerGridDarkTrack: { fill: "#CCCCCC" },
      labelStyle: {
        fontSize: 13,
        bold: true,
        italics: false,
      },
      arrow: {
        angle: 100,
        width: 2,
        color: "#0E4F2F", // Red color for arrows
        radius: 0,
      },
    },
  };

  return (
    <div>
      <PageHeader title="Activities Tracking System" />
      <Chart
        chartType="Gantt"
        width="100%"
        height="90vh"
        data={data}
        options={options}
        chartEvents={[
          {
            eventName: "error",
            callback: ({ chartWrapper, google }) => {
              console.error("Error loading chart:", chartWrapper.getChart());
            },
          },
        ]}
      />
    </div>
  );
};

export default Track;
