import React, { useState } from 'react';
import PageHeader from "../../../Components/page_header";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import MEComponent from './MEComponent';
import ValidatorComponent from './ValidatorComponent';
import ReviewerComponent from './ReviewerComponent';
import NationalAdminComponent from './NationalAdminComponent';

const CreateUsers = () => {
  const [tabIndex, setTabIndex] = useState(-1); 
  const [open, setOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTabIndex(-1);
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
      <PageHeader title="Create User" />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0E4F2F", 
            height: "5px",
            borderRadius: "1rem",
          },
        }}
        sx={{
          display: 'flex',
          justifyContent: 'space-around', 
          marginBottom: '12px',
        }}
      >
        <Tab
          label="M&E"
          sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#9BB6A8",
            fontWeight: "bold",
            flex: 1, // Make each tab take up equal space
            "&.Mui-selected": {
              color: "#0E4F2F", 
            },
          }}
        />
        <Tab
          label="Validator"
          sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#9BB6A8",
            fontWeight: "bold",
            flex: 1,
            "&.Mui-selected": {
              color: "#0E4F2F", 
            },
          }}
        />
        <Tab
          label="Reviewer"
          sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#9BB6A8",
            fontWeight: "bold",
            flex: 1,
            "&.Mui-selected": {
              color: "#0E4F2F", 
            },
          }}
        />
        <Tab
          label="National Admin"
          sx={{
            textTransform: "capitalize",
            fontSize: "15px",
            color: "#9BB6A8",
            fontWeight: "bold",
            flex: 1,
            "&.Mui-selected": {
              color: "#0E4F2F", 
            },
          }}
        />
      </Tabs>
      <Box sx={{ padding: '2rem', backgroundColor: 'white', borderRadius: '8px', marginTop: '1rem' }}>
        <Typography variant="h6" sx={{ textAlign: 'center', color: '#0E4F2F' }}>
          Welcome to the Create User page. Please select a tab to create a user.
        </Typography>
        {tabIndex === 0 && <MEComponent open={open} handleClose={handleClose} />}
        {tabIndex === 1 && <ValidatorComponent open={open} handleClose={handleClose} />}
        {tabIndex === 2 && <ReviewerComponent open={open} handleClose={handleClose} />}
        {tabIndex === 3 && <NationalAdminComponent open={open} handleClose={handleClose} />}
      </Box>
    </Box>
  );
};

export default CreateUsers;
