import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axiosInstance from "../../../../Services/axiosInstance";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
} from "../../../../features/auth/authSlice";
import { getTcName } from "../../../../utils/getFormattedToken";
import { toSentenceCase } from "../../../../utils/toSentenceCase";
import ShowLoader from "../../../../utils/showLoader";
import ShowError from "../../../../utils/showError";
import MoreOptionsMenu from "../../../../Components/MoreOptionsIcon";
import EditModalForm from "../../../../Components/Modal_Forms/EditModal";
import ConfirmationModal from "../../../../Components/Modal_Forms/ConfirmDelete";
import {
  fetchAllStudents,
  filterStudentByClass,
} from "../../../../utils/apiContext";
import { FormDropdown } from "../../../../Components/form";
import { studentClassOptions } from "../../../../Data_Objects/dummyData";
import { Button } from "@mui/material";

function valueToUpperCase(value) {
  return value.toUpperCase();
  return value.toUpperCase();
}
const columns = [
  {
    id: "school_state_location",
    label: "School State Location",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "school_type",
    label: "School Type",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "school_name",
    label: "School Name",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "entry_level",
    label: "Entry Level",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "surname",
    label: "Surname",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "first_name",
    label: "First Name",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "other_name",
    label: "Other Names",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "center",
    type: "email",
  },
  {
    id: "occupational_trade",
    label: "Occupational Trade",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Construction", label: "Construction" },
      { value: "Mechanical Engineering", label: "Mechanical Engineering" },
      { value: "Hospitality", label: "Hospitality" },
      { value: "Electrical Engineering", label: "Electrical Engineering" },
      { value: "ICT", label: "ICT" },
      { value: "Agricultural Science", label: "Agricultural Science" },
      { value: "Garment Making", label: "Garment Making" },
    ],
  },
  {
    id: "nationality",
    label: "Nationality",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "state",
    label: "State",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "lga",
    label: "LGA",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "sex",
    label: "Sex",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ],
  },
  {
    id: "reg_num",
    label: "Registration Number",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "class",
    label: "Student Class",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "Jss 1", label: "JSS1" },
      { value: "Jss 2", label: "JSS2" },
      { value: "Jss 3", label: "JSS3" },
      { value: "Sss 1", label: "SS1" },
      { value: "Sss 2", label: "SS2" },
      { value: "Sss 3", label: "SS3" },
    ],
  },
  {
    id: "married",
    label: "Married",
    minWidth: 170,
    align: "center",
    type: "dropdown",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "date_of_birth",
    label: "Date of Birth",
    minWidth: 170,
    align: "center",
    type: "date",
  },
  {
    id: "home_town",
    label: "Home Town",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "home_address",
    label: "Home Address",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 170,
    align: "center",
    type: "number",
  },
  {
    id: "parent_name",
    label: "Parent Name",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "parent_address",
    label: "Parent Address",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "nin",
    label: "NIN",
    minWidth: 170,
    align: "center",
    type: "number",
  },

  {
    id: "gpa",
    label: "GPA",
    minWidth: 170,
    align: "center",
    type: "number",
  },
  {
    id: "grad_status",
    label: "Graduation Status",
    minWidth: 170,
    align: "center",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "year_of_cert",
    label: "Year of Certification",
    minWidth: 170,
    align: "center",
    type: "number",
  },

  {
    id: "grade_award",
    label: "Grade Award",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "dept",
    label: "Department",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "entry_year",
    label: "Entry Year",
    minWidth: 170,
    align: "center",
    type: "number",
  },
  {
    id: "qualification_in_view",
    label: "Qualification in View",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "exit_year",
    label: "Exit Year",
    minWidth: 170,
    align: "center",
    type: "number",
  },
  {
    id: "current_session",
    label: "Current Session",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "current_semester",
    label: "Current Semester",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "final_yr",
    label: "Final Year",
    minWidth: 170,
    align: "center",
    type: "number",
  },
  {
    id: "duration_of_study",
    label: "Duration of Study",
    minWidth: 170,
    align: "center",
    type: "text",
  },
  {
    id: "cgpa",
    label: "CGPA",
    minWidth: 170,
    align: "center",
    type: "text",
  },
];

export default function StudentDataTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const tc_name = getTcName(userTkn);
  const queryClient = useQueryClient();
  const [studentClass, setStudentClass] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["studentData"],
    queryFn: () => fetchAllStudents(jurisdiction),
  });

  const {
    data: filteredStudentData,
    isLoading: filteredIsLoading,
    error: filteredError,
  } = useQuery({
    queryKey: ["filteredStudentData", studentClass],
    queryFn: () => filterStudentByClass(tc_name, jurisdiction, studentClass),
    enabled: !!studentClass, // Only fetch filtered data if studentClass is set
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onEdit = (rowData) => {
    setSelectedRowData(rowData);
    setIsEditModalOpen(true);
  };

  const handleEditAndSave = async (updatedData) => {
    try {
      const updatedDataWithJurisdiction = { ...updatedData, jurisdiction };
      await axiosInstance.put(
        `/student/c1/update-student-bio/${updatedData._id}`,
        updatedDataWithJurisdiction
      );
      queryClient.invalidateQueries(["studentData"]);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Failed to save the updated data:", error);
    }
  };

  const onDelete = (rowId) => {
    setRowToDelete(rowId);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(
        `/student/c1/delete-post-student-bio/${rowToDelete}/?jurisdiction=${jurisdiction}`
      );
      setIsConfirmationModalOpen(false);
      queryClient.invalidateQueries(["studentData"]);
    } catch (error) {
      console.error("Failed to delete the data:", error);
    }
  };

  const handleClearFilter = () => {
    setStudentClass("");
  };

  if (isLoading || filteredIsLoading) {
    return <ShowLoader />;
  }

  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  const filteredData = data.filter(
    (student) => student.school_name === tc_name
  );

  const dataToDisplay = studentClass ? filteredStudentData || [] : filteredData;

  const noDataMessage =
    studentClass && filteredStudentData?.length === 0
      ? `No student data in this ${studentClass} yet`
      : null;

  return (
    <>
      <div className="flex items-center mb-4 gap-4 w-full justify-end">
        <FormDropdown
          name="class"
          label="Student Class"
          options={studentClassOptions}
          value={studentClass}
          onChange={(e) => handleChange(e, setStudentClass)}
          placeholder="Filter By Class"
          className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {filteredStudentData && (
          <Button
            variant="contained"
            onClick={handleClearFilter}
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            Clear Filter
          </Button>
        )}
      </div>
      <Paper>
        <TableContainer
          className="scrollbar scrollbar-w-2 scrollbar-h-1.5 scrollbar-thumb-rounded-[50px] scrollbar-track-gray-300 scrollbar-track-rounded-[50px] scrollbar-thumb-[#A5A2A2] w-full  overflow-x-scroll"
          sx={{ backgroundColor: "#F2F2F2" }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#FFFFFF" }}>
              <TableRow>
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    width: "1%",
                    fontWeight: "bold",
                    color: "#2D3142",
                  }}
                >
                  Options
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      whiteSpace: "nowrap",
                      width: "1%",
                      fontWeight: "bold",
                      color: "#2D3142",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="border-2 mt-2">
              {noDataMessage ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="left">
                    {noDataMessage}
                  </TableCell>
                </TableRow>
              ) : (
                dataToDisplay
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                      <TableCell>
                        <MoreOptionsMenu
                          onEdit={() => onEdit(row)}
                          onDelete={() => onDelete(row._id)}
                        />
                      </TableCell>
                      {columns.map((column) => {
                        let value = row[column.id];
                        if (column.id === "school_name") {
                          value = toSentenceCase(value);
                        }
                        if (column.id === "school_type") {
                          value = toSentenceCase(value);
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              whiteSpace: "nowrap",
                              width: "1%",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataToDisplay.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataToDisplay.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <EditModalForm
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        rowData={selectedRowData}
        handleSave={handleEditAndSave}
        fields={columns}
        header="Edit Student Data"
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this item?"
      />
    </>
  );
}

// export default StudentDataTable
