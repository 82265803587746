import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../Components/Loader";
import PageHeader from "../../../Components/page_header";
import Component1DashboardDisplay from "../../../Components/nationalAdminDashboardComponents/Component1DashboardDisplay";
import Component3DashboardDisplay from "../../../Components/nationalAdminDashboardComponents/Component3DashboardDisplay";
import Component4DashboardDisplay from "../../../Components/nationalAdminDashboardComponents/Component4DashboardDisplay";
import {
  fetchAllStudentsForNA,
  fetchAllTeachersForNA,
  fetchAllPDO2PostsForNA,
  fetchAllTeachersServiceType,
} from "../../../utils/apiContext";

const Analytics_Dashboard2 = () => {
  const [selectedComponent, setSelectedComponent] = useState("component1");

  const {
    data: studentData,
    error: studentError,
    isLoading: studentLoading,
  } = useQuery({
    queryKey: ["fetchAllStudents"],
    queryFn: fetchAllStudentsForNA,
  });

  const {
    data: ttiData,
    error: ttiError,
    isLoading: ttiLoading,
  } = useQuery({
    queryKey: ["fetchAllTeachers"],
    queryFn: fetchAllTeachersForNA,
  });

  const {
    data: pdo2Data,
    error: pdo2Error,
    isLoading: pdo2Loading,
  } = useQuery({
    queryKey: ["fetchAllPDO2Posts"],
    queryFn: fetchAllPDO2PostsForNA,
  });

  const {
    data: teachersServiceData,
    error: teachersServiceError,
    isLoading: teachersServiceLoading,
  } = useQuery({
    queryKey: ["fetchAllTtisService"],
    queryFn: fetchAllTeachersServiceType,
  });

  if (studentLoading || ttiLoading || pdo2Loading || teachersServiceLoading) {
    return <Loader />;
  }

  if (studentError || ttiError || pdo2Error || teachersServiceError) {
    return <div>Error loading data</div>;
  }

  if (!studentData || !ttiData || !pdo2Data || !teachersServiceData) {
    return null;
  }

  const stateStudents = studentData[0]?.all_students?.length || 0;
  const federalStudents = studentData[1]?.all_students?.length || 0;
  const totalStudents = stateStudents + federalStudents;

  const stateTTIs = ttiData[0]?.all_posts || [];
  const federalTTIs = ttiData[1]?.all_posts || [];
  const ttiArray = [...stateTTIs, ...federalTTIs];
  const totalTTIs = ttiArray.length;

  const totalPDO2 = pdo2Data.reduce((sum, item) => sum + item.length, 0);

  const handleChange = (event) => {
    setSelectedComponent(event.target.value);
  };

  const preServiceDataFederal =
    teachersServiceData.federal.pre_service_data || [];
  const inServiceDataFederal =
    teachersServiceData.federal.in_service_data || [];
  const preServiceDataState = teachersServiceData.state.pre_service_data1 || [];
  const inServiceDataState = teachersServiceData.state.in_service_data1 || [];

  const preServiceData = [...preServiceDataFederal, ...preServiceDataState];
  const inServiceData = [...inServiceDataFederal, ...inServiceDataState];

  const filterAndAggregateData = (data) => {
    let maleCount = 0;
    let femaleCount = 0;

    data?.forEach((obj) => {
      if (obj.gender === "Male") {
        maleCount += 1;
      } else if (obj.gender === "Female") {
        femaleCount += 1;
      }
    });

    return {
      maleCount,
      femaleCount,
    };
  };

  const ttiAggregatedData = filterAndAggregateData(ttiArray);
  const preServiceAggregatedData = filterAndAggregateData(preServiceData);
  const inServiceAggregatedData = filterAndAggregateData(inServiceData);

  return (
    <div>
      <PageHeader title="Analytics" />
      <select
        value={selectedComponent}
        onChange={handleChange}
        className="mt-1 block w-full pl-2 pr-10 py-2 bg-white border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-lg text-lg font-bold"
      >
        <option value="component1">Component 1</option>
        <option value="component3">Component 3</option>
        <option value="component4">Component 4</option>
      </select>

      {selectedComponent === "component1" && (
        <Component1DashboardDisplay
          totalStudents={totalStudents}
          totalPDO2={totalPDO2}
          totalTTIs={totalTTIs}
        />
      )}
      {selectedComponent === "component3" && (
        <Component3DashboardDisplay
          aggregatedData={ttiAggregatedData}
          totalTTIs={totalTTIs}
          preServiceData={preServiceData}
          inServiceData={inServiceData}
          preServiceAggregatedData={preServiceAggregatedData}
          inServiceAggregatedData={inServiceAggregatedData}
        />
      )}
      {selectedComponent === "component4" && (
        <Component4DashboardDisplay
          aggregatedData={ttiAggregatedData}
          totalTTIs={totalTTIs}
          preServiceData={preServiceData}
          inServiceData={inServiceData}
          preServiceAggregatedData={preServiceAggregatedData}
          inServiceAggregatedData={inServiceAggregatedData}
        />
      )}
    </div>
  );
};

export default Analytics_Dashboard2;
