import React from "react";
import { StudentsDoughnutChartDisplay } from "../chart_components/DoughnutChart";


const StudentsDonutAnalyticsDisplay = ({ aggregatedData }) => {
  // console.log("aggregatedData:", aggregatedData);
  return (
    <div className="px-4 py-4">
      <h1
        className="text-primary_text font-bold text-[20px]"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Students in Supported Trades
      </h1>
      <br />
      <br />
      <div>
        <StudentsDoughnutChartDisplay data={aggregatedData} />
      </div>
    </div>
  );
};

export default StudentsDonutAnalyticsDisplay;
