import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { FormInput } from "../../../form";
import CustomButton from "../../../button";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../Services/axiosInstance";
import { toast } from "sonner";
import {
  selectCurrentJurisdiction,
  selectCurrentUser,
} from "../../../../features/auth/authSlice";
import { setPdo3Post } from "../../../../features/auth/isPostMade";

const PDO3 = ({ title, closeForm }) => {
  const modalRef = useRef(null);
  const authToken = useSelector((state) => state.auth.token);
  const decodedTkn = jwtDecode(authToken);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const email = useSelector(selectCurrentUser);

  const { tc_name } = decodedTkn;
  const [state, setState] = useState("");
  const [maleGraduates, setMaleGraduates] = useState();
  const [maleEnrolled, setMaleEnrolled] = useState();
  const [maleLabourMarketWorkers, setMaleLabourMarketWorkers] = useState();
  const [maleUnemployed, setMaleUnemployed] = useState();

  const [femaleGraduates, setFemaleGraduates] = useState();
  const [femaleEnrolled, setFemaleEnrolled] = useState();
  const [femaleLabourMarketWorkers, setFemaleLabourMarketWorkers] = useState();
  const [femaleUnemployed, setFemaleUnemployed] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      // formData.append["token"] = authToken;
      setIsLoading(true);

      const response = await axiosInstance.post(
        `/pdo/c1/make-post-beneficiaries-of-job-focused-interventions/?jurisdiction=${jurisdiction}`,
        formData
      );
      console.log(response.data)
      return response;
    },
    onSuccess: (response) => {
      toast.success("Successful");
      setIsLoading(false);
      const isCheck = response.data.new_entry
      const isPostMade = isCheck.tc_name === tc_name
      if (isPostMade) {
        dispatch(setPdo3Post(true));
      } else {
        dispatch(setPdo3Post(false));
      }
      closeForm();
    },
    onError: (err) => {
      if (err.response.status === 400) {
        toast.error("Post already exists");
      } else {
        toast.error("An Error Occurred");
      }
      setIsLoading(false);
      closeForm();
    },
  });

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if all fields are empty
    if (
      !state &&
      !maleGraduates &&
      !maleEnrolled &&
      !maleLabourMarketWorkers &&
      !maleUnemployed &&
      !femaleGraduates &&
      !femaleEnrolled &&
      !femaleLabourMarketWorkers &&
      !femaleUnemployed
    ) {
      toast.error("Cannot submit an empty form");
      return;
    }

    const newObj = {};
    newObj.state = state;
    newObj.tc_name = tc_name;
    newObj.jurisdiction = jurisdiction;
    newObj.email = email;
    newObj.male = {
      graduates: maleGraduates,
      enrolled: maleEnrolled,
      labour_market_workers: maleLabourMarketWorkers,
      unemployed: maleUnemployed,
    };

    newObj.female = {
      graduates: femaleGraduates,
      enrolled: femaleEnrolled,
      labour_market_workers: femaleLabourMarketWorkers,
      unemployed: femaleUnemployed,
    };

    mutation.mutate(newObj);
    // closeForm();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeForm(); // Assuming closeForm sets the state that controls the modal visibility
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeForm]);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[50%] h-[98vh] px-5 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="flex justify-between items-center font-bold">
          <h1 className="text-2xl">{title}</h1>

          <CloseIcon
            onClick={closeForm}
            sx={{ fontSize: "2rem" }}
            fontWeight={"30"}
            className="text-red-500 cursor-pointer absolute top-2 right-4"
          />
        </div>

        <hr className="mt-2 mb-4" />
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="state"
            label="Enter State Location"
            value={state.toLowerCase()}
            onChange={(e) => handleInputChange(e, setState)}
          />
          <FormInput
            type="number"
            name="male_graduates"
            label="Number of Male graduates in IDEAS Supported Programs in TCs"
            value={maleGraduates}
            onChange={(e) => handleInputChange(e, setMaleGraduates)}
          />
          <FormInput
            type="number"
            name="female_graduates"
            label="Number of Female graduates in IDEAS Supported Programs in TCs"
            value={femaleGraduates}
            onChange={(e) => handleInputChange(e, setFemaleGraduates)}
          />
          <FormInput
            type="number"
            name="male_enrolled"
            label="Total number of Male students enrolled in IDEAS Supported Programs in TCs"
            value={maleEnrolled}
            onChange={(e) => handleInputChange(e, setMaleEnrolled)}
          />
          <FormInput
            type="number"
            name="female_enrolled"
            label="Total number of Female students enrolled in IDEAS Supported Programs in TCs"
            value={femaleEnrolled}
            onChange={(e) => handleInputChange(e, setFemaleEnrolled)}
          />

          <FormInput
            type="number"
            name="male_labour_market_workers"
            label="Number of Male Labour market workers benefiting from Short-term skills upgrading programs in TCs"
            value={maleLabourMarketWorkers}
            onChange={(e) => handleInputChange(e, setMaleLabourMarketWorkers)}
          />
          <FormInput
            type="number"
            name="female_labour_market_workers"
            label="Number of Female Labour market workers benefiting from Short-term skills upgrading programs in TCs"
            value={femaleLabourMarketWorkers}
            onChange={(e) => handleInputChange(e, setFemaleLabourMarketWorkers)}
          />
          <FormInput
            type="number"
            name="male_unemployed"
            label="Number of   Male Unemployed Youths benefiting from Short-term skills upgrading   programs in TCs"
            value={maleUnemployed}
            onChange={(e) => handleInputChange(e, setMaleUnemployed)}
          />
          <FormInput
            type="number"
            name="female_unemployed"
            label="Number of Female Unemployed Youths benefiting from Short-term skills upgrading   programs in TCs"
            value={femaleUnemployed}
            onChange={(e) => handleInputChange(e, setFemaleUnemployed)}
          />

          <div className="flex justify-center">
            <CustomButton className="bg-active_bg" isLoading={isLoading}>
              {isLoading ? "Submiting..." : "Submit"}
            </CustomButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default PDO3;
