import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import axiosInstance from "../../../../../Services/axiosInstance";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../../features/auth/authSlice";
import { getTcName } from "../../../../../utils/getFormattedToken";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Link } from "@mui/material";
import ShowError from "../../../../../utils/showError";
import ShowLoader from "../../../../../utils/showLoader";
import { useState } from "react";
import RejectDataEntry from "../../../../../Components/Modal_Forms/RejectMessage/Reject";
import { IndicatorEditModalFormPdo2 } from "../../../../../Components/Modal_Forms/IndicatorEditModal";
import ViewPdf from "../../../../../Components/PdfModalView/ViewPdf";
import {
  fetchAllPDO2Posts,
  fetchAllPDO2PostsByJurisdiction,
} from "../../../../../utils/apiContext";

const fields = [
  {
    id: "tvet_sensitization_consucted_in_host_community_by_school",
    label: "TVET Sensitization conducted in host community by school",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "no_of_toilet_facilities_in_school",
    label: "Number of toilet facilities in school",
    type: "number",
  },
  {
    id: "no_of_toilet_facilities_renovated_by_ideas_project",
    label: "Number of toilet facilities renovated by IDEAS project",
    type: "number",
  },
  {
    id: "no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project",
    label:
      "Number of toilet facilities provided with WASH facilities by IDEAS project",
    type: "number",
  },
  {
    id: "no_of_female_students_enrolled_in_priority_trade",
    label: "Number of female students enrolled in priority trade",
    type: "number",
  },
  {
    id: "total_no_of_students_enrolled_in_priority_trades",
    label: "Total number of students enrolled in priority trades",
    type: "number",
  },
  {
    id: "no_of_tvet_sensitizations_conducted_by_school_tc_report",
    label:
      "Upload TC report showing the Sensitization Activity including pictures all in one PDF",
    type: "file",
  },
  {
    id: "student_enrollment_data_doc_pdf",
    label: "Upload Student Enrollment Data Document (file max size 5MB)",
    type: "file",
  },
];

const Pdo2Display = ({ questions, propsTc_name, propsJurisdiction }) => {
  const [openRejectModal1, setOpenRejectModal1] = useState(false);
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);

  const tc_name = getTcName(userTkn);
  const userRoles = useSelector(selectCurrentUserRole);
  const queryClient = useQueryClient();
  const isUser1 = userRoles === "M_E_OFFICE";
  const combJurisdiction =
    userRoles !== "National_Admin" ? jurisdiction : propsJurisdiction;
  const handleReject1 = () => {
    setOpenRejectModal1(true);
  };

  const handleCloseRejectModal1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const handleRejectReasonChange1 = (event) => {
    setRejectionReason1(event.target.value);
  };

  const handleSubmit1 = () => {
    // Handle submit action here
    console.log("Rejection Reason:", rejectionReason1);
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const closeForm = () => {
    setOpenPdfModal(false);
  };

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: combJurisdiction === "state" ? ["pdo2"] : ["pdo2b"],
    queryFn: () => fetchAllPDO2Posts(combJurisdiction, tc_name),
  });

  const {
    data: allJurisdictionPosts,
    isLoading: jurisdictionIsLoading,
    error: jurisdictionError,
  } = useQuery({
    queryKey:
      combJurisdiction === "state"
        ? ["pdo2_Jurisdiction"]
        : ["pdo2b_Jurisdiction"],
    queryFn: () => fetchAllPDO2PostsByJurisdiction(combJurisdiction),
  });

  const openEditModal = (data) => {
    // console.log("data:", data);
    setEditValues({
      tvet_sensitization_consucted_in_host_community_by_school:
        data?.tvet_sensitization_consucted_in_host_community_by_school || "",
      no_of_toilet_facilities_in_school:
        data?.no_of_toilet_facilities_in_school || "",
      no_of_toilet_facilities_renovated_by_ideas_project:
        data?.no_of_toilet_facilities_renovated_by_ideas_project || "",
      no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project:
        data?.no_of_toilet_facilities_provided_with_wash_facilities_by_ideas_project ||
        "",
      no_of_female_students_enrolled_in_priority_trade:
        data?.no_of_female_students_enrolled_in_priority_trade || "",

      total_no_of_students_enrolled_in_priority_trades:
        data?.total_no_of_students_enrolled_in_priority_trades || "",
      student_enrollment_data_doc_pdf:
        data?.student_enrollment_data_doc_pdf || "",

      _id: data?._id || "",
    });
    setModalOpen(true);
  };

  const saveEdits = async (formData) => {
    try {
      await axiosInstance.put(
        `/pdo/c1/update-post-female-enrollment-rate-in-project-supportedTc/${formData.get(
          "_id"
        )}`,
        formData
      );
      queryClient.invalidateQueries(["pdo2"]);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving edits:", error);
    }
  };

  if (isUser1) {
    if (isLoading) {
      return <ShowLoader />;
    }
    if (error?.response?.status === 400) {
      return <ShowError />;
    }
  }

  const post = isUser1
    ? allPosts && allPosts.tc_name === tc_name
      ? allPosts
      : allJurisdictionPosts?.filter((post) => {
          return post?.tc_name === propsTc_name;
        })[0] || null
    : allJurisdictionPosts?.filter((post) => {
        return post?.tc_name === propsTc_name;
      })[0] || null;

  // Use post variable below this line

  return questions.map((list) => {
    const responseValue = list.responseKey
      .split(".")
      .reduce((o, i) => o?.[i], post);
    return (
      <div
        key={list.id}
        className="border-b-2 border-b-indicator_border ml-6 text-sm py-2"
      >
        <div className="w-[80%] flex justify-between items-center">
          <div>{list.question}</div>
          <div className="text-active_bg font-bold">
            {list.responseKey.includes("pdf") ? (
              <FileDownloadDoneIcon />
            ) : (
              responseValue
            )}
          </div>
        </div>
        {userRoles === "Reviewer" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-0 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "Validator" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-6 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Validate
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "M_E_OFFICE" && (
          <div className="flex gap-3 absolute bottom-6 right-6">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F" },
              }}
              onClick={() => openEditModal(post)}
            >
              Edit
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F", color: "#ffffff" },
              }}
            >
              View Documents
            </Button>
          </div>
        )}
        <RejectDataEntry
          openRejectModal1={openRejectModal1}
          handleCloseRejectModal1={handleCloseRejectModal1}
          rejectionReason1={rejectionReason1}
          handleRejectReasonChange1={handleRejectReasonChange1}
          handleSubmit1={handleSubmit1}
        />
        {openPdfModal && <ViewPdf closeForm={closeForm} />}
        <IndicatorEditModalFormPdo2
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          rowData={editValues}
          handleSave={saveEdits}
          fields={fields}
          jurisdiction={jurisdiction}
          header="Female Enrollment"
        />
      </div>
    );
  });
};

export default Pdo2Display;
