import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectCurrentJurisdiction,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../../features/auth/authSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchAllTechnicalCollegePosts } from "../../../utils/apiContext";
import ShowLoader from "../../../utils/showLoader";


const ValidateSchool = () => {
  const [showAnswers1, setShowAnswers1] = useState(false);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userRole = useSelector(selectCurrentUserRole);
  const validatorState = useSelector(selectValidatorState);

  const {
    data: techColleges,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tcData"],
    queryFn: () => fetchAllTechnicalCollegePosts(jurisdiction),
  });

  const filteredPosts =
    userRole === "Validator"
      ? techColleges?.filter(
          (post) => post?.institution_state === validatorState
        )
      : techColleges;

  const displayAnswers1 = () => {
    setShowAnswers1(!showAnswers1);
  };

  if (isLoading) {
    return <ShowLoader />;
  }
  

  return (
    <div>
      <h1 className="text-primary_text">Technical Colleges</h1>
      <div className="mt-4 ml-4 mr-2"></div>
      {filteredPosts?.map((college, index) => (
        <div
          key={index}
          className="text-primary_text cursor-pointer ml-4 mr-2"
          onClick={displayAnswers1}
        >
          <div
            className="flex justify-between items-center border-2 border-indicator_border mb-2 rounded-md px-4 h-16"
            style={{
              fontFamily: "Poppins, sans-serif",
              backgroundColor: "white",
            }}
          >
            <div className="text-md font-bold">{college.institution_name}</div>
            <Link
              to={`/analytics-schools/${college._id}`}
              className="text-active_bg"
            >
              View Dashboard
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};



export default ValidateSchool;
