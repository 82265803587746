import { useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { motion } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import pdf from "../../Assets/high_value.pdf";
import pdf2 from "../../Assets/invoice.pdf";
import pdf3 from "../../Assets/FlutterCheatSheet.pdf";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ViewPdf = ({ closeForm }) => {
  const pdfArray = [
    {
      id: 1,
      file: pdf,
    },
    {
      id: 2,
      file: pdf2,
    },
    {
      id: 3,
      file: pdf3,
    },
  ];
  const [numPagesArray, setNumPagesArray] = useState(
    new Array(pdfArray.length).fill(null)
  );
  const [pageNumberArray, setPageNumberArray] = useState(
    new Array(pdfArray.length).fill(1)
  );
  const [pageHeights, setPageHeights] = useState({});
  const [currentHeight, setCurrentHeight] = useState("auto");
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);

  const modalRef = useRef(null);
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
  };

  const onDocumentLoadSuccess = (index, { numPages }) => {
    if (numPages) {
      const newNumPagesArray = [...numPagesArray];
      newNumPagesArray[index] = numPages;
      setNumPagesArray(newNumPagesArray);
    }
  };
  const handlePageChange = (index, page) => {
    const newPageNumberArray = [...pageNumberArray];
    newPageNumberArray[index] = page;
    setPageNumberArray(newPageNumberArray);
  };
  const handleRenderSuccess = (index, pageIndex, page) => {
    setPageHeights((prev) => {
      const newHeights = { ...prev };
      if (!newHeights[index]) newHeights[index] = [];
      newHeights[index][pageIndex] = page.originalHeight;
      return newHeights;
    });
  };

  const updateHeight = (index) => {
    if (pageHeights[index]) {
      const heights = pageHeights[index];
      const maxHeight = Math.max(...heights);
      setCurrentHeight(maxHeight);
    }
  };
  const handleSlideChange = (swiper) => {
    const { activeIndex } = swiper;
    setCurrentPdfIndex(activeIndex);
    updateHeight(activeIndex);
  };
  return (
    <div className="fixed left-0 top-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        ref={modalRef}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="scrollbar-hide overflow-auto w-[70%] h-[98vh] px-8 pt-8 pb-6 bg-white rounded-lg relative"
      >
        <div className="w-full flex items-center justify-between px-2 py-1 bg-gray-200 sticky top-0 z-10">
          <p className="text-sm font-bold">
            Viewing PDF {currentPdfIndex + 1} of {pdfArray.length}
          </p>
          <div className="flex">
            <div className="button-prev" ref={prevRef}>
              <KeyboardArrowLeftIcon
                sx={{ fontSize: "50px" }}
                className="cursor-pointer"
              />
            </div>
            <div className="button-next" ref={nextRef}>
              <KeyboardArrowRightIcon
                sx={{ fontSize: "50px" }}
                className="text-3xl cursor-pointer"
              />
            </div>
                  
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          ref={swiperRef}
          onSlideChange={handleSlideChange}
        >
          {pdfArray.map((item, index) => (
            <SwiperSlide
              key={item.id}
              style={{
                backgroundColor: "#2B8056",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="w-fit px-2 py-1 bg-gray-200 sticky top-0 z-10">
                <p className="text-sm font-bold">
                  Page {pageNumberArray[index]} of {numPagesArray[index]}
                </p>
              </div>
              <div className="flex justify-center">
                <Document
                  file={item.file}
                  onLoadSuccess={(pdf) => onDocumentLoadSuccess(index, pdf)}
                >
                  {Array.from(
                    { length: numPagesArray[index] || 1 },
                    (_, pageIndex) => (
                      <Page
                        key={`page_${pageIndex + 1}`}
                        pageNumber={pageIndex + 1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        width={800}
                        onRenderSuccess={(page) => {
                          const { height } = page.getViewport({ scale: 1 });
                          handleRenderSuccess(index, pageIndex, height);
                        }}
                      />
                    )
                  )}
                </Document>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>
      <div
        onClick={closeForm}
        className="flex items-center bg-active_bg_light py-1 px-2 rounded-md absolute top-6 left-[8.5%] cursor-pointer"
      >
        <ArrowBackIcon sx={{ color: "#ffffff" }} />
        <div className=" text-white">Back</div>
      </div>
    </div>
  );
};

export default ViewPdf;
