import VisibilityIcon from '@mui/icons-material/Visibility';

export const IoiPreview1 = [
  {
    id: 1,
    question: "School IDEAS Project team established?",
    responseKey: "sch_ideas_project_team_established.value",
  },
  {
    id: 2,
    question: "Upload the IDEAS Project team inauguration report",
    responseKey: "sch_ideas_project_team_established.report_pdf",
  },
  {
    id: 3,
    question: "Number of MOUs signed with industry partners",
    responseKey: "no_of_mous_signed_with_industry_partners.value",
  },
  {
    id: 4,
    question: "Upload all SIGNED MOUs in one PDF Document",
    responseKey: "no_of_mous_signed_with_industry_partners.all_signed_mous_pdf",
  },
  {
    id: 5,
    question: "Number of times CIU met over the past 12 Months (1 year)",
    responseKey: "no_of_times_ciu_met_over_past_year.value",
  },
  {
    id: 6,
    question: "Upload all CIU minutes of meeting in one PDF Document",
    responseKey: "no_of_times_ciu_met_over_past_year.minutes_pdf",
  },
];

export const IoiPreview2 = [
  {
    id: 1,
    question: "Internship arrangements with industry partners enhanced by IDEAS Project",
    responseKey: "internship_arrangements",
  },
  {
    id: 2,
    question: "Number of industry partners with enhanced internship arrangements",
    responseKey: "no_of_industry_partners",
  },
  {
    id: 3,
    question: "Number of internship arrangements with industry partners",
    responseKey: "no_of_internship_arrangements",
  },
  {
    id: 4,
    question: "Development of short-term skills upgrading courses for unemployed youths in partnership with industry partners",
    responseKey: "development_of_short_term_skills_upgrading_courses",
  },
  {
    id: 5,
    question: "Number of newly developed short-term skills upgrading courses in partnership with industry",
    responseKey: "no_of_newly_developed_short_term_skills_upgrading_courses_on_partnership_with_industry",
  },
  {
    id: 6,
    question: "Upload the latest TC Status report(s) in one PDF Document(file max size 5MB)",
    responseKey: "latest_tc_status_report_pdf",
  },
  {
    id: 7,
    question: "Upload the attendance sheet PDF",
    responseKey: "attendance_sheet_pdf",
  },
];
export const IoiPreview3 = [
  {
    id: 1,
    question: "Gender Based Violence (GBV) Sensitization conducted by the school",
    responseKey: "gbv_sensitization_conducted_by_the_school.value",
  },
  {
    id: 2,
    question: "Upload updated Sensitization report(s) in one PDF Document",
    responseKey: "gbv_sensitization_conducted_by_the_school.sensitization_pdf",
  },
  {
    id: 3,
    question: "GBV Policy developed by school",
    responseKey: "gbv_policy_developed_by_school",
  },
  {
    id: 4,
    question: "GBV Policy published by school",
    responseKey: "gbv_policy_published_by_school.value",
  },
  {
    id: 5,
    question: "GBV reporting and referral system for affected youths in place at the school",
    responseKey: "gbv_reporting_and_referral_system_for_youths_in_place_at_the_school",
  },
  {
    id: 6,
    question: "Presence of grievance redress mechanism (GRM) at the school.",
    responseKey: "presence_of_grievance_redress_mechanism_at_the_school",
  },
  {
    id: 7,
    question: "Upload Updated Reports Showing Cases Received & Addressed, and Pictures of Complains box all in one PDF document",
    responseKey: "reports_showing_addressed_complaints_box_pdf",
  },
  {
    id: 8,
    question: "Upload a copy of your school’s GBV Policy",
    responseKey: "gbv_policy_published_by_school.school_gbv_policy_pdf",
  },
];
export const IoiPreview4 = [
  {
    id: 1,
    question: "Workshops available at the school",
    responseKey: "workshops",
  },
  {
    id: 2,
    question: "Initial disbursement of 250,000 USD received by the school?",
    responseKey: "initial_disbursement_of_250kusd_received.value",
  },
  {
    id: 3,
    question: "CDP received by the NPCU",
    responseKey: "cdp_received_by_the_npcu",
  },
  {
    id: 4,
    question: "CDP approved by the World Bank",
    responseKey: "cdp_approved_by_the_world_bank",
  },
  {
    id: 5,
    question: "Number of workshops renovated",
    responseKey: "no_of_workshops_renovated",
  },
  {
    id: 6,
    question: "Number of workshops equipped with modern tools/equipment and ready for use",
    responseKey: "no_of_workshops_equipped_with_modern_tools_and_ready_for_use.value",
  },
  {
    id: 7,
    question: "Upload Updated Status Report Showing all renovated and equipped workshops/labs, including picture evidence in one PDF",
    responseKey: "no_of_workshops_equipped_with_modern_tools_and_ready_for_use.status_report_pdf",
  },
  {
    id: 8,
    question: "Training of TTIs on the use of newly installed tools",
    responseKey: "training_of_ttis_on_the_use_of_newly_installed_tools",
  },
  {
    id: 9,
    question: "Number of TTIs trained on the use of newly installed tools",
    responseKey: "no_of_ttis_trained_on_the_use_of_newly_installed_tools.value",
  },
];
