import { useQuery, useQueries } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAllIOI1Posts, fetchAllIOI2Posts, fetchAllIOI3Posts, fetchAllIOI4Posts, fetchIOI1PostById, fetchIOI2PostById, fetchIOI3PostById, fetchIOI4PostById } from "../utils/apiContext";
import { setIoi1Post, setIoi2Post, setIoi3Post, setIoi4Post } from "../features/auth/isPostMade";


export const useIoi1Post = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: jurisdiction === "state" ? ["ioi1"] : ["ioi1b"],
    queryFn: () => fetchAllIOI1Posts(jurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey:
          jurisdiction === "state"
            ? ["ioi1", post._id]
            : ["ioi1b", post._id],
        queryFn: () => fetchIOI1PostById(post?._id, jurisdiction),
      })) || [],
  });

  const postsWithDetails = postQueries.map((query) => query.data);

  useEffect(() => {
    const isAvailable = postsWithDetails.some(
      (post) => post?.tc_name === tc_name
    );
    if(isAvailable) {
      dispatch(setIoi1Post(true));
    } else {
      dispatch(setIoi1Post(false));
    }
    
  }, [dispatch, postsWithDetails, tc_name]);

  return { isLoading, error, postsWithDetails };
};

export const useIoi2Post = (jurisdiction, tc_name) => {
  const dispatch = useDispatch();

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: jurisdiction === "state" ? ["ioi2"] : ["ioi2b"],
    queryFn: () => fetchAllIOI2Posts(jurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey:
          jurisdiction === "state"
            ? ["ioi2", post._id]
            : ["ioi2b", post._id],
        queryFn: () => fetchIOI2PostById(post?._id, jurisdiction),
      })) || [],
  });

  const postsWithDetails = postQueries.map((query) => query.data);

  useEffect(() => {
    const isAvailable = postsWithDetails.some(
      (post) => post?.tc_name === tc_name
    );
    if(isAvailable) {
      dispatch(setIoi2Post(true));
    } else {
      dispatch(setIoi2Post(false));
    }
  }, [dispatch, postsWithDetails, tc_name]);

  return { isLoading, error, postsWithDetails };
};

export const useIoi3Post = (jurisdiction, tc_name) => {
    const dispatch = useDispatch();
  
    const {
      data: allPosts,
      isLoading,
      error,
    } = useQuery({
      queryKey: jurisdiction === "state" ? ["ioi3"] : ["ioi3b"],
      queryFn: () => fetchAllIOI3Posts(jurisdiction),
    });
  
    const postQueries = useQueries({
      queries:
        allPosts?.map((post) => ({
          queryKey:
            jurisdiction === "state"
              ? ["ioi3", post._id]
              : ["ioi3b", post._id],
          queryFn: () => fetchIOI3PostById(post?._id, jurisdiction),
        })) || [],
    });
  
    const postsWithDetails = postQueries.map((query) => query.data);
  
    useEffect(() => {
      const isAvailable = postsWithDetails.some(
        (post) => post?.tc_name === tc_name
      );
      if(isAvailable) {
        dispatch(setIoi3Post(true));
      } else {
        dispatch(setIoi3Post(false));
      }
    }, [dispatch, postsWithDetails, tc_name]);
  
    return { isLoading, error, postsWithDetails };
  };

export const useIoi4Post = (jurisdiction, tc_name) => {
    const dispatch = useDispatch();
  
    const {
      data: allPosts,
      isLoading,
      error,
    } = useQuery({
      queryKey: jurisdiction === "state" ? ["ioi4"] : ["ioi4b"],
      queryFn: () => fetchAllIOI4Posts(jurisdiction),
    });
  
    const postQueries = useQueries({
      queries:
        allPosts?.map((post) => ({
          queryKey:
            jurisdiction === "state"
              ? ["ioi4", post._id]
              : ["ioi4b", post._id],
          queryFn: () => fetchIOI4PostById(post?._id, jurisdiction),
        })) || [],
    });
  
    const postsWithDetails = postQueries.map((query) => query.data);
  
    useEffect(() => {
      const isAvailable = postsWithDetails.some(
        (post) => post?.tc_name === tc_name
      );
      if(isAvailable) {
        dispatch(setIoi4Post(true));
      } else {
        dispatch(setIoi4Post(false));
      }
    }, [dispatch, postsWithDetails, tc_name]);
  
    return { isLoading, error, postsWithDetails };
  };


