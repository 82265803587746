import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import axiosInstance from "../../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../../features/auth/authSlice";
import { getTcName } from "../../../../../utils/getFormattedToken";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Link } from "@mui/material";
import ShowError from "../../../../../utils/showError";
import ShowLoader from "../../../../../utils/showLoader";
import { useEffect, useState } from "react";
import RejectDataEntry from "../../../../../Components/Modal_Forms/RejectMessage/Reject";
import ViewPdf from "../../../../../Components/PdfModalView/ViewPdf";
import { IndicatorEditModalFormIoi4 } from "../../../../../Components/Modal_Forms/IndicatorEditModal";
import {
  fetchAllIOI4Posts,
  fetchIOI4PostById,
} from "../../../../../utils/apiContext";
import { setIoi4Post } from "../../../../../features/auth/isPostMade";

const fields = [
  {
    id: "workshops",
    label: "Workshops",
    type: "checkbox",
    options: [
      { value: "Construction Workshops", label: "Construction Workshops" },
      {
        value: "Mechanical Engineering Workshops",
        label: "Mechanical Engineering Workshops",
      },

      {
        value: "Hospitality Workshops",
        label: "Hospitality Workshops",
      },
      {
        value: "Electrical Engineering Workshops",
        label: "Electrical Engineering Workshops",
      },
      {
        value: "ICT Labs",
        label: "ICT Labs",
      },
      {
        value: "Agricultural Engineering Workshops",
        label: "Agricultural Engineering Workshops",
      },
      {
        value: "Garment Making Workshop",
        label: "Garment Making Workshop",
      },
    ],
  },
  {
    id: "initial_disbursement_of_250kusd_received",
    label: "Initial Disbursement of 250K USD Received",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "cdp_received_by_the_npcu",
    label: "CDP Received by the NPCU",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "cdp_approved_by_the_world_bank",
    label: "CDP Approved by the World Bank",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "no_of_workshops_renovated",
    label: "Number of Workshops Renovated",
    type: "number",
  },
  {
    id: "no_of_workshops_equipped_with_modern_tools_and_ready_for_use",
    label: "Number of Workshops Equipped with Modern Tools and Ready for Use",
    type: "number",
  },
  {
    id: "workshop_status_report_pdf",
    label: "Upload Workshop Status Report (file max size 5MB)",
    type: "file",
  },
  {
    id: "training_of_ttis_on_the_use_of_newly_installed_tools",
    label: "Training of TTIs on the Use of Newly Installed Tools",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "no_of_ttis_trained_on_the_use_of_newly_installed_tools",
    label: "Number of TTIs Trained on the Use of Newly Installed Tools",
    type: "number",
  },
  {
    id: "training_status_report_pdf",
    label: "Upload Training Status Report (file max size 5MB)",
    type: "file",
  },
];

const Ioi4Display = ({ questions, propsTc_name, propsJurisdiction }) => {
  const [openRejectModal1, setOpenRejectModal1] = useState(false);
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const dispatch = useDispatch();

  const tc_name = getTcName(userTkn);
  const userRoles = useSelector(selectCurrentUserRole);
  const queryClient = useQueryClient();
  const isUser1 = userRoles === "M_E_OFFICE";
  const combJurisdiction =
    userRoles !== "National_Admin" ? jurisdiction : propsJurisdiction;

  const handleReject1 = () => {
    setOpenRejectModal1(true);
  };

  const handleCloseRejectModal1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const handleRejectReasonChange1 = (event) => {
    setRejectionReason1(event.target.value);
  };

  const handleSubmit1 = () => {
    // Handle submit action here
    console.log("Rejection Reason:", rejectionReason1);
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const closeForm = () => {
    setOpenPdfModal(false);
  };

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: combJurisdiction === "state" ? ["ioi4"] : ["ioi4b"],
    queryFn: () => fetchAllIOI4Posts(combJurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey:
          combJurisdiction === "state"
            ? ["ioi4", post._id]
            : ["ioi4b", post._id],
        queryFn: () => fetchIOI4PostById(post?._id, combJurisdiction),
      })) || [],
  });

  const openEditModal = (data) => {
    setEditValues({
      workshops: data.workshops,
      initial_disbursement_of_250kusd_received:
        data.initial_disbursement_of_250kusd_received.value,
      cdp_received_by_the_npcu: data.cdp_received_by_the_npcu,
      cdp_approved_by_the_world_bank: data.cdp_approved_by_the_world_bank,
      no_of_workshops_renovated: data.no_of_workshops_renovated,
      no_of_workshops_equipped_with_modern_tools_and_ready_for_use:
        data.no_of_workshops_equipped_with_modern_tools_and_ready_for_use.value,
      training_of_ttis_on_the_use_of_newly_installed_tools:
        data.training_of_ttis_on_the_use_of_newly_installed_tools,
      no_of_ttis_trained_on_the_use_of_newly_installed_tools:
        data.no_of_ttis_trained_on_the_use_of_newly_installed_tools.value,
      doc_confirming_disbursment_received_pdf:
        data.initial_disbursement_of_250kusd_received
          .doc_confirming_disbursment_received_pdf,
      workshop_status_report_pdf:
        data.no_of_workshops_equipped_with_modern_tools_and_ready_for_use
          .status_report_pdf,
      training_status_report_pdf:
        data.no_of_ttis_trained_on_the_use_of_newly_installed_tools
          .status_report_pdf,
      _id: data?._id || "",
    });
    setModalOpen(true);
  };

  const saveEdits = async (formData) => {
    try {
      await axiosInstance.put(
        `/ioi/c1/update-post-no-of-fully-functioning-upgraded-workshops-in-supported-tc/${formData.get(
          "_id"
        )}/?jurisdiction=${jurisdiction}`,
        formData
      );
      queryClient.invalidateQueries(["ioipost4"]);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving edits:", error);
    }
  };

  const postsWithDetails = postQueries.map((query) => query.data);

  const filteredPosts = postsWithDetails.filter((post) => {
    if (isUser1) {
      return post?.tc_name === tc_name;
    } else {
      return post?.tc_name === propsTc_name;
    }
  });

  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  return questions.map((list) => {
    const responseValue = list.responseKey
      .split(".")
      .reduce((o, i) => o?.[i], filteredPosts[0]);
    return (
      <div
        key={list.id}
        className="border-b-2 border-b-indicator_border ml-6 text-sm py-2"
      >
        <div className="w-[80%] flex justify-between items-center">
          <div>{list.question}</div>
          <div className="text-active_bg font-bold">
            {list.responseKey.includes("pdf") ? (
              <FileDownloadDoneIcon />
            ) : (
              responseValue
            )}
          </div>
        </div>
        {userRoles === "Reviewer" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-0 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "Validator" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-6 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Validate
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "M_E_OFFICE" && (
          <div className="flex gap-3 absolute bottom-6 right-6">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F" },
              }}
              onClick={() => openEditModal(filteredPosts[0])}
            >
              Edit
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F", color: "#ffffff" },
              }}
            >
              View Documents
            </Button>
          </div>
        )}
        <RejectDataEntry
          openRejectModal1={openRejectModal1}
          handleCloseRejectModal1={handleCloseRejectModal1}
          rejectionReason1={rejectionReason1}
          handleRejectReasonChange1={handleRejectReasonChange1}
          handleSubmit1={handleSubmit1}
        />
        {openPdfModal && <ViewPdf closeForm={closeForm} />}
        <IndicatorEditModalFormIoi4
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          rowData={editValues}
          handleSave={saveEdits}
          fields={fields}
          header="Workshops/Labs Information"
        />
      </div>
    );
  });
};

export default Ioi4Display;
