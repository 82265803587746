import { BarChart } from "@mui/x-charts/BarChart";

export const FirstBarChartDisplayPdo = ({ data }) => {
  return (
    <BarChart
      series={[
        {
          data: [740],
          color: "#CFF0B5",
          label: "Baseline",
        },
        {
          data: [400],
          color: "#BC5645",
          label: "Actual",
        },
        {
          data: [680],
          color: "#C0AA54",
          label: "Target",
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};

export const SecondBarChartDisplayPdo = ({ data }) => {
  const percentageSum = Array.isArray(data)
    ? data
        .map((item) => Number(item.percentage) || 0)
        .reduce((a, b) => a + b, 0)
    : 0;

  const averagePercentage =
    Array.isArray(data) && data.length > 0
      ? (percentageSum / data.length).toFixed(2)
      : 0;

  return (
    <BarChart
      series={[
        {
          data: [650],
          color: "#CFF0B5",
          label: "Baseline",
        },
        {
          data: [averagePercentage],
          color: "#BC5645",
          label: "Actual",
        },
        {
          data: [750],
          color: "#C0AA54",
          label: "Target",
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};

export const ThirdBarChartDisplayPdo = ({ data }) => {
  return (
    <BarChart
      series={[
        {
          data: [650],
          color: "#CFF0B5",
          label: "Baseline",
        },
        {
          data: [550],
          color: "#BC5645",
          label: "Actual",
        },
        {
          data: [700],
          color: "#C0AA54",
          label: "Target",
        },
      ]}
      height={300}
      width={350}
      xAxis={[{ data: [""], scaleType: "band" }]}
      margin={{ top: 70, bottom: 30, left: 30, right: 10 }}
      barLabel="value"
      borderRadius={8}
    />
  );
};
