import React, { useState } from "react";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';


const StudentCard = ({ openModal }) => {

    return (
        
        <div className="flex mt-3 w-auto rounded-xl shadow-xl bg-white"
        >
            <div className="p-6 space-y-4">
                <div className="flex items-center">
                    <SchoolOutlinedIcon className="text-linear_grad_1" style={{ fontSize: "2.0rem" }} />
                    <p className="font-bold text-active_bg text-[20px] ml-2" style={{ fontFamily: 'Poppins, sans-serif' }}>Students</p>
                </div>
                <div className="flex mt-4 pb-6">
                    <p className="text-input_text text-sm font-sm mt-2"style={{ fontFamily: 'Poppins, sans-serif' }}>
                        Enter Students data here. Click save when you're done.
                    </p>
                    <button
                    onClick={openModal}
                    className="text-active_bg text-nowrap font-bold py-2 rounded-md cursor-pointer hover:bg-active_bg hover:text-white mx-12 px-4 mt-3"style={{ fontFamily: 'Poppins, sans-serif' }}
                    >
                    + ADD DATA
                    </button>
                </div>
            </div> 
        </div>
    );
};

export default StudentCard;
