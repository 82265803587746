import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import axiosInstance from "../../../../../Services/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
} from "../../../../../features/auth/authSlice";
import { getTcName } from "../../../../../utils/getFormattedToken";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import ShowLoader from "../../../../../utils/showLoader";
import ShowError from "../../../../../utils/showError";
import { useEffect, useState } from "react";
import RejectDataEntry from "../../../../../Components/Modal_Forms/RejectMessage/Reject";
import ViewPdf from "../../../../../Components/PdfModalView/ViewPdf";
import { Button } from "@mui/material";
import { IndicatorEditModalFormIoi3 } from "../../../../../Components/Modal_Forms/IndicatorEditModal";
import {
  fetchAllIOI3Posts,
  fetchIOI3PostById,
} from "../../../../../utils/apiContext";
import { setIoi3Post } from "../../../../../features/auth/isPostMade";

const fields = [
  {
    id: "gbv_sensitization_conducted_by_the_school",
    label: "Gender Based Violence (GBV) Sensitization conducted by the school",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "sensitization_pdf",
    label:
      "Upload updated Sensitization report(s) in one PDF Document(file max size 5MB)",
    type: "file",
  },
  {
    id: "gbv_policy_developed_by_school",
    label: "GBV Policy Developed by School",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "gbv_policy_published_by_school",
    label: "GBV Policy Published by School",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "school_gbv_policy_pdf",
    label: "Upload School GBV Policy (file max size 5MB)",
    type: "file",
  },

  {
    id: "gbv_reporting_and_referral_system_for_youths_in_place_at_the_school",
    label:
      "GBV Reporting and Referral System for Youths in Place at the School",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "presence_of_grievance_redress_mechanism_at_the_school",
    label: "Presence of Grievance Redress Mechanism at the School",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    id: "reports_showing_addressed_complaints_box_pdf",
    label: "Upload Reports Showing Addressed Complaints (file max size 5MB)",
    type: "file",
  },
];

const Ioi3Display = ({ questions, propsTc_name, propsJurisdiction }) => {
  const [openRejectModal1, setOpenRejectModal1] = useState(false);
  const [rejectionReason1, setRejectionReason1] = useState("");
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const userTkn = useSelector(selectCurrentToken);
  const dispatch = useDispatch();

  const tc_name = getTcName(userTkn);
  const userRoles = useSelector(selectCurrentUserRole);
  const isUser1 = userRoles === "M_E_OFFICE";
  const queryClient = useQueryClient();
  const combJurisdiction =
    userRoles !== "National_Admin" ? jurisdiction : propsJurisdiction;

  const handleReject1 = () => {
    setOpenRejectModal1(true);
  };

  const handleCloseRejectModal1 = () => {
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const handleRejectReasonChange1 = (event) => {
    setRejectionReason1(event.target.value);
  };

  const handleSubmit1 = () => {
    // Handle submit action here
    console.log("Rejection Reason:", rejectionReason1);
    setOpenRejectModal1(false);
    setRejectionReason1("");
  };

  const closeForm = () => {
    setOpenPdfModal(false);
  };

  const {
    data: allPosts,
    isLoading,
    error,
  } = useQuery({
    queryKey: combJurisdiction === "state" ? ["ioi3"] : ["ioi3b"],
    queryFn: () => fetchAllIOI3Posts(combJurisdiction),
  });

  const postQueries = useQueries({
    queries:
      allPosts?.map((post) => ({
        queryKey:
          combJurisdiction === "state"
            ? ["ioi3", post._id]
            : ["ioi3b", post._id],
        queryFn: () => fetchIOI3PostById(post?._id, combJurisdiction),
      })) || [],
  });

  const openEditModal = (data) => {
    setEditValues({
      gbv_sensitization_conducted_by_the_school:
        data?.gbv_sensitization_conducted_by_the_school.value || "",
      gbv_policy_developed_by_school:
        data?.gbv_policy_developed_by_school || "",
      gbv_policy_published_by_school:
        data?.gbv_policy_published_by_school.value || "",
      gbv_reporting_and_referral_system_for_youths_in_place_at_the_school:
        data?.gbv_reporting_and_referral_system_for_youths_in_place_at_the_school ||
        "",
      presence_of_grievance_redress_mechanism_at_the_school:
        data?.presence_of_grievance_redress_mechanism_at_the_school || "",

      school_gbv_policy_pdf: data?.school_gbv_policy_pdf || "",
      reports_showing_addressed_complaints_box_pdf:
        data?.reports_showing_addressed_complaints_box_pdf || "",
      sensitization_pdf: data?.sensitization_pdf || "",
      _id: data?._id || "",
    });
    setModalOpen(true);
  };

  const saveEdits = async (formData) => {
    try {
      await axiosInstance.put(
        `/ioi/c1/update-post-no-of-supported-tc-with-reporting-and-referral-mechanisms-for-gbv-affected-youth/${formData.get(
          "_id"
        )}/?jurisdiction=${jurisdiction}`,
        formData
      );
      queryClient.invalidateQueries(["ioipost3"]);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving edits:", error);
    }
  };

  const postsWithDetails = postQueries.map((query) => query.data);
  
  const filteredPosts = postsWithDetails.filter((post) => {
    if (isUser1) {
      // console.log("fetching using user1", post?.tc_name, propsTc_name);
      return post?.tc_name === tc_name;
    } else {
      // console.log("fetching user reviewer", post?.tc_name, propsTc_name);
      return post?.tc_name === propsTc_name;
    }
  });
  if (isLoading) {
    return <ShowLoader />;
  }
  if (error?.response?.status === 400) {
    return <ShowError />;
  }

  return questions.map((list) => {
    const responseValue = list.responseKey
      .split(".")
      .reduce((o, i) => o?.[i], filteredPosts[0]);
    return (
      <div
        key={list.id}
        className="border-b-2 border-b-indicator_border ml-6 text-sm py-2"
      >
        <div className="w-[80%] flex justify-between items-center">
          <div>{list.question}</div>
          <div className="text-active_bg font-bold">
            {list.responseKey.includes("pdf") ? (
              <FileDownloadDoneIcon />
            ) : (
              responseValue
            )}
          </div>
        </div>
        {userRoles === "Reviewer" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-0 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "Validator" && (
          <div className="text-active_bg rounded-md text-sm absolute bottom-6 right-6 flex gap-3">
            <Button
              onClick={handleReject1}
              variant="outlined"
              color="error"
              sx={{
                bgcolor: "#F44336",
                color: "white",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#F44336" },
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Validate
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{ textTransform: "capitalize" }}
            >
              View Documents
            </Button>
          </div>
        )}{" "}
        {userRoles === "M_E_OFFICE" && (
          <div className="flex gap-3 absolute bottom-6 right-6">
            <Button
              variant="contained"
              sx={{
                bgcolor: "#0E4F2F",
                color: "#FFFFFF",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F" },
              }}
              onClick={() => openEditModal(filteredPosts[0])}
            >
              Edit
            </Button>
            <Button
              onClick={() => setOpenPdfModal(true)}
              variant="outline"
              sx={{
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#0E4F2F", color: "#ffffff" },
              }}
            >
              View Documents
            </Button>
          </div>
        )}
        <RejectDataEntry
          openRejectModal1={openRejectModal1}
          handleCloseRejectModal1={handleCloseRejectModal1}
          rejectionReason1={rejectionReason1}
          handleRejectReasonChange1={handleRejectReasonChange1}
          handleSubmit1={handleSubmit1}
        />
        {openPdfModal && <ViewPdf closeForm={closeForm} />}
        <IndicatorEditModalFormIoi3
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          rowData={editValues}
          handleSave={saveEdits}
          fields={fields}
          header="GBV Reporting"
        />
      </div>
    );
  });
};

export default Ioi3Display;
