export const intermediateOutcomeHeader = [
  {
    id: 1,
    header: "Industry Partnership"
  },
  {
    id: 2,
    header: "Training Program Partnership"
  },
  {
    id: 3,
    header: "GBV Reporting"
  },
  {
    id: 4,
    header: "Workshops/Labs Information"
  },
]

export const intermediateOutcomeIndicator = [
  {
    id: 1,
    indicator:
      "Number of supported Technical Colleges with functioning modernized Governing Board with industry partnership (Cumulated)",
  },
  {
    id: 2,
    indicator:
      "Number of training programs in supported Technical Colleges delivered and monitored in partnership with the private sector ",
  },
  {
    id: 3,
    indicator:
      "Number of supported TCs with reporting and referral mechanisms for GBV affected youth",
  },
  {
    id: 4,
    indicator:
      "Number of fully functioning upgraded workshops/labs in supported TCs (cumulated)",
  },
];
