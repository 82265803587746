function CustomButton({
  children,
  variant = "primary",
  className = "",
  isLoading,
  onClick,
}) {
  const buttonClasses = `
  py-2.5 px-6 rounded-lg font-bold bg-active_bg text-[14px]
        ${variant === "primary" ? "bg-active_bg text-white w-fit" : ""}
        ${variant === "secondary" ? "bg-white text-active_bg w-fit" : ""}
        
    `;
  return (
    <button className={buttonClasses} disabled={isLoading} onClick={onClick}>
      {children}
    </button>
  );
}

export default CustomButton;
