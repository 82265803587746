import React from 'react';

const TotalStudentsInTCCard = ({ totalStudentsInTC }) => {
  return (
    <div className="custom-shadow bg-white px-4 py-4 rounded-lg">
      <h1
        className="text-[20px] font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Total number of Students in TC 
      </h1>
      <h1
        className="text-active_bg text-3xl font-bold mt-2"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        {totalStudentsInTC}
      </h1>
    </div>
  );
};

export default TotalStudentsInTCCard;
