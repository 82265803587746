import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  FirstBarChartDisplayPdo,
  SecondBarChartDisplayPdo,
  ThirdBarChartDisplayPdo,
} from "../Charts/BarchartPdo";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import {
  selectCurrentJurisdiction,
  selectCurrentToken,
  selectCurrentUserRole,
  selectValidatorState,
} from "../../../../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import {
  fetchAllPDO2PostsByJurisdiction,
  fetchAllPDO3PostsByJurisdiction,
} from "../../../../utils/apiContext";
import Loader from "../../../../Components/Loader";
import { toast } from "sonner";
import ShowLoader from "../../../../utils/showLoader";
import ShowError from "../../../../utils/showError";

const PdoCard = () => {
  const userRole = useSelector(selectCurrentUserRole);
  const jurisdiction = useSelector(selectCurrentJurisdiction);
  const validatorState = useSelector(selectValidatorState);

  const {
    data: pdo_2,
    isLoading: pdo2isLoading,
    error: pdo2error,
  } = useQuery({
    queryKey: ["pdo2"],
    queryFn: () => fetchAllPDO2PostsByJurisdiction(jurisdiction),
  });

  const filteredPdo2 =
    userRole === "Validator"
      ? pdo_2?.filter((pdo2) => pdo2?.state === validatorState)
      : pdo_2;

  const {
    data: pdo_3,
    isLoading: pdo3isLoading,
    error: pdo3error,
  } = useQuery({
    queryKey: ["pdo3"],
    queryFn: () => fetchAllPDO3PostsByJurisdiction(jurisdiction),
  });
  if (pdo2isLoading || pdo3isLoading) {
    return <ShowLoader />;
  }
  // if (pdo2error) {
  //   return <ShowError />;
  // }
  // if (pdo3error) {
  //   return <ShowError />;
  // }
  return (
    <div>
      <h1
        className="font-bold text-primary_text"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Pdo Indicators
      </h1>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={2}
        navigation={{ nextEl: ".button-next", prevEl: ".button-prev" }}
      >
        <SwiperSlide>
          <FirstBarChartDisplayPdo />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Percentage of employers of graduates from project supported TC
            programs indicating they are satisfied with the performance of the
            graduates
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <SecondBarChartDisplayPdo data={filteredPdo2} />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            Female enrollment rate in project supported Technical Colleges
            programs
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <ThirdBarChartDisplayPdo />
          <p className="text-xs" style={{ fontFamily: "Poppins, sans-serif" }}>
            BENEFICIARIES OF JOB-FOCUSED INTERVENTIONS (STUDENTS) Direct Project
            beneficiaries (disaggregated by gender for Students)
          </p>
        </SwiperSlide>
      </Swiper>
      <Box className="flex items-center justify-between text-primary_text_light mt-3">
        <motion.div whileTap={{ scale: 0.9 }} className="button-prev">
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
        <motion.div whileTap={{ scale: 0.9 }} className="button-next">
          <KeyboardArrowRightIcon
            sx={{ fontSize: "60px" }}
            className="cursor-pointer"
          />
        </motion.div>
      </Box>
    </div>
  );
};

export default PdoCard;
